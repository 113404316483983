import { state } from 'cerebral'
import { isDesktopSkin } from '@/utils/checks'
import is from 'is_js'
import controller from '@/controller'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { clearAllPosters } from '../../../utils/posters/clearAllPosters'

const initialH = window.innerHeight
const initialW = window.innerWidth

export default [
  ({ store, get }) => {
    fixViewport({ store, get })
    if (getState('useragent.media.desktop') !== isDesktopSkin()){
      clearAllPosters()
    }

    store.set('useragent.media.desktop', isDesktopSkin())
    store.set('useragent.window', {
      height: window.innerHeight,
      width: window.innerWidth
    })
  },
]

const fixViewport = ({ store, get }) => {
  if (is.desktop()) { return }
  const metaViewport = document.querySelector('meta[name=viewport]')
  if (initialW !== window.innerWidth && initialH !== window.innerHeight) {
    //device rotated
    metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
    return
  }
  if (window.innerWidth === initialW && initialH > window.innerHeight) {
    //soft keaboard open
    metaViewport.setAttribute('content', 'height=' + initialH + 'px, width=device-width, initial-scale=1.0')
    // controller.getSequence('app.setIsNavBarHidden')({ hidden: true })
    store.set('useragent.softKeyboardOpen', true)
    return
  }
  if (window.innerWidth === initialW && initialH === window.innerHeight) {
    //default state
    metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0')
    if (get(state`useragent.softKeyboardOpen`)) {
      // controller.getSequence('app.setIsNavBarHidden')({ hidden: false })
      store.set('useragent.softKeyboardOpen', false)
    }
    return
  }
}
