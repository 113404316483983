import { sequences, state } from 'cerebral'
import createPost from './createPost'
import sendCreateAvatar from '../actions/sendCreateAvatar'
import { UpdateMyUser } from '../../../utils/userLoader'
import { setFemale, setMyProfileId } from '../../../utils/my'
import { FEMALE } from '../../../utils/postUtils'
import { getSequence } from '../../../utils/StoreManager'

export default [
  sendCreateAvatar,
  createPost,
  ({ store, props }) => {
    setMyProfileId(props.post_id)
    store.set('auth.profilePostTags', props.tags)
    // setFemale(props.tags.indexOf(FEMALE) !== -1)
  },
  UpdateMyUser
]
