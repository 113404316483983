import { state, props } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

const { vbApiUrl } = config

export default [
  async ({ store, get, props }) => {
    if (!get(state`auth.subs.${props.uid}`) ||
         get(state`posts.watchedUsers.${props.uid}`)) { return }
         
    store.set(`posts.watchedUsers.${props.uid}`, true)

    try {
      await fetch(getCoreUrl('/api/v1/subscriptions/view'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ft-sid': getSessionId()
        },
        body: JSON.stringify({
          author: props.uid,
        })
      })
    } catch (e) {
      console.log('diary watch error:', e)
    }
  }
]
