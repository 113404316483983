import {
  forgotPasswordPanel,
  changeEmailPanel,
  changePasswordPanelStepOne, TOP_PANEL
} from '../../../components/Panels/types'
import { state } from 'cerebral'
import { isTWA } from '@/utils/twa'

export const createPanel = ({ store, get, props }) => {
  const statePanels = get(state`app.panels`)
  if (statePanels.length > 0) {
    const lastPanel = statePanels[statePanels.length - 1]
    if (
      lastPanel.id === props.options.id &&
      lastPanel.type === props.options.type &&
      (
        props.options.type !== forgotPasswordPanel.options.type &&
        props.options.type !== changeEmailPanel.options.type &&
        props.options.type !== TOP_PANEL &&
        props.options.type !== changePasswordPanelStepOne.options.type
      )
    ) {
      console.log('dont open the same panel ', props.options.type)
      return
    }
  }

  if (props.options.type === 'getVip' && isTWA() && !get(state`app.twa_payment_config`)) {
    return
  }
  
  if (props.options.type === 'user' && 
      props.options.id === get(state`auth.uid`)) {
    props.options.type = 'mainMenu'
  }

  store.push('app.panels', { ...props.options, hidden: true })
}

