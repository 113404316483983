import { getConfigRequest } from '../actions'
import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'

export default [
  // getConfigRequest, {
  //   success: [
  //     set(state`shop.successfullyLoaded`, true),
  //     set(state`shop.items`, props`result`)
  //   ],
  //   error: set(state`shop.successfullyLoaded`, false)
  // }
]
