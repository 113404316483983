import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { handlePanelRoute } from '../actions/handlePanelRoute'
import { SPAMMER_UID } from '../../chat/actions'

export default [
  ({ props }) => ({ 
    panelRoute: 'dialog'
  }),
  dispatchUrlChangeToParent,
  handleRoute,
  handlePanelRoute
]
