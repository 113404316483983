import { state } from 'cerebral'
import controller from '@/controller'
import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'

export default [
  () => ({ route: 'create' }),
  ({ store, get }) => {
    let postNum = get(state`auth.storage.metadata.postThemeId`)
    if (postNum) {
      const postData = get(state`posts.postsData.${postNum}`)
      if (postData) {
        store.set('upload.postKind', postData.postId)
        store.set('upload.selectedFileUrl', postData.original)
        store.set('upload.title', postData.originalTitle)
        store.set('upload.description', postData.markdownDescOriginal || postData.originalDesc)
        store.set('upload.selectedTags', postData.tags || [])
        store.set('upload.subscriptionType', postData.subscriptionType)
      } else {
        postNum = null
      }
    }
    
    const selectKind = controller.getSequence('upload.selectPostKind')
    selectKind({ postKind: 'postTheme' })
    store.set('posts.selectedPostId', postNum)
  },
  dispatchUrlChangeToParent,
  handleRoute
]
