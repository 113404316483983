import { state } from 'cerebral'

export const addPostModerationStatus = ({ props, store, get }) => {
  const postThemeId = get(state`auth.storage.metadata.postThemeId`)
  
  if (postThemeId === props.params.postId && get(state`posts.postsData.${postThemeId}`)) {
    store.set(`posts.postsData.${postThemeId}.status`, props.params.postStatus)
    store.set(`posts.postsData.${postThemeId}.statusFromDb`, props.params.postStatus)      
  }
}
