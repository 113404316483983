import { getMyUid } from '../../utils/my'
import { MD5 } from '../../utils/MD5'
import { getMyLSProp, setMyLSProps } from '../../utils/LocalStorageUtil'

export function checkUploadFile(hash) {
  return getMyLSProp(hash)
}

export function getUploadFileHash(byte, url) {
 return  MD5(getMyUid() + byte + url)
}

export function saveUploadFile(hash, fileId) {
  setMyLSProps(hash, fileId)
}

