import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { getConfigRequest } from '../../shop/actions'

export default [
  set(state`app.isReady`, true),
  // getConfigRequest, {
  //   success: [
  //     set(state`shop.successfullyLoaded`, true),
  //     set(state`shop.items`, props`result`)
  //   ],
  //   error: set(state`shop.successfullyLoaded`, false)
  // },
]
