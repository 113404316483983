import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import controller from '@/controller'

export default [
  ({ get, props }) => {
    const themeData = get(state`auth.storage.metadata.theme`) || {}

    //console.log('@@@ appUrl', props.appUrl)
    //let dl = isProdEnv ? 'https://link.ulive.chat/wiHbMvjaQZ' : 'https://6d4h.test-app.link/QOOam0tlUY'

    let dl = isProdEnv ? props.appUrl : 'https://6d4h.test-app.link/QOOam0tlUY'
    dl += '?'
    dl += 'theme=' + encodeURIComponent(themeData.id) + '&'
    dl += 'ar=' + encodeURIComponent(get(state`app.ageRating`)) + '&'
    dl += 'activities=' + encodeURIComponent(themeData.activities) + '&'
    dl += 'privateShowThemes=' + encodeURIComponent(themeData.privateShowThemes) + '&'
    dl += 'session_id=' + encodeURIComponent(getSessionId()) + '&'
    dl += 'uid=' + encodeURIComponent(controller.getState('auth.uid')) + '&'
    dl += 'privateActivities=' + encodeURIComponent(themeData.privateActivities)

    const win = window.open(dl, '_blank')
    win.focus()
  }
]
