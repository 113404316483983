import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import onNewMessage from './onNewMessage'
import config from '@/config'

const { soundUrl } = config

export default ({ props, store, get }) => {
  const { fromUid, postId } = props.params
  if (fromUid === get(state`auth.uid`)) {
    return
  }
  const intl = get(state`intl.content`)

  const unreadComments = get(state`chat.unreadComments`) || []
  unreadComments.push(props.params)
  store.set('chat.unreadComments', unreadComments)

  loadUsers([fromUid], async () => {
    if (get(state`auth.pushSettings.newComment`)) {
      try {
        const s = new Audio(soundUrl + 'private_message_source.mp3')
        await s.play()
      } catch (e) {
        console.log('sound not allowed')
      }
    }
    
    if (get(state`app.route`) === 'room' && fromUid !== get(state`auth.uid`)) {
      const roomId = get(state`app.roomId`)
      onNewMessage({ props: {
        roomId, 
        msgData: {
          from: fromUid,
          to: get(state`auth.uid`),
          text: intl.comment_button.replace('{0}', '#' + postId)
        }
      }, store, get })
    }
  })
}
