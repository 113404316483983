import { state } from 'cerebral'
import searchGirls from './searchGirls'
import config from '@/config'

const { vbApiUrl } = config

export default async ({ path, props, store, get }) => {
  const finded = searchGirls({ props, store, get })
  if (finded.length > 0) {
    return path.success({ data: finded })
  }

  if (get(state`girls.allGirlsLoaded`) || 
     get(state`girls.isLoading`)) {
    return path.fail()
  }

  store.set('girls.isLoading', true)

  const reqData = { mode:get(state`app.ageRating`) > 17 ? 'f+' : 'f-' }
  if (props.uid) {
    if (get(state`girls.lastSearch`) === props.uid) {
      return path.fail()
    }
    
    reqData.uid = props.uid
    store.set('girls.lastSearch', reqData.uid)
  } else if (props.name) {
    if (get(state`girls.lastSearch`) === props.name) {
      return path.fail()
    }
    
    reqData.name = props.name
    store.set('girls.lastSearch', reqData.name)
  } else {
    reqData.offset = get(state`girls.data`).length
  }
  
  store.set('girls.allGirlsLoaded', true)

  try {
    const answer = await fetch(vbApiUrl + '/vb/models?' + new URLSearchParams(reqData).toString())

    if (answer.ok) {
      const json = await answer.json()
      store.set('girls.allGirlsLoaded', !props.name && !props.uid && json.length === 0)
      return path.success({ data: json })
    }
  } catch (e) {
    console.warn('@@@ load girls error:', e)
  }
  return path.fail()
}
