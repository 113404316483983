import { state } from 'cerebral'
import { when } from 'cerebral/factories'
import uploadFile from '../actions/uploadFile'
import { getSessionId } from '@/utils'
import { getOrientationDegree } from '@/utils/selectPhoto'
import controller from '@/controller'

import config from '@/config'
const { postUploadUrl } = config

export default [
  when(state`upload.selectedFile`), {
    true: [
      ({ get, store }) => ({
        url: postUploadUrl + '/api/v1/media-file/upload',
        body: {
          file: get(state`upload.selectedFile`),
          sid: getSessionId(),
          main_file: true,
          orientation: getOrientationDegree(get(state`upload.selectedPhotoOrientation`)),
        },
        callback: result => {
          if (!result.result) { return }
          store.set('neuralCheckUrl', result.result[1350] || result.result[600] || result.result.original)
          store.set('upload.mainFileId', result.result.file_id)
        },
        onError: () => {
          const showSnackbar = controller.getSequence('app.showSnackbar')
          showSnackbar({ text: get(state`intl.content.error_load_profile`), type: 'error' })
          controller.getSequence('upload.setFile')()
        }
      }),
      uploadFile,
    ],
    false: []
  }
]
