import { getCurrentRoomId } from '../navigation/navigationUtil'
import { askQuiz } from './quizUtil'
import { getMiniGamesUrl } from '../api/apiURLUtil'
import { GETRequest, POSTRequest } from '../api/requestWrapper'
import { getState, mergeStoreWrapper, setStoreWrapper } from '../StoreManager'
import { getLocaleVersion } from '../bodyAttributeUtil'
import config from '@/config'
import { closeCurrentPanel } from '../../components/Panels/createPanelUtil'
import { quizStatusEvent } from '../amplitudeLiveEvents'
import { isBuffActive } from '../buffUtils'
import { QUIZ_COOL_DOWN } from '../../modules/buffs'
const { localesUrl, settingsUrl } = config

let quizRoom
export function loadQuizInfo(force){
  if (quizRoom === getCurrentRoomId() && !force){
    return
  }
  quizRoom = getCurrentRoomId()
  GETRequest(getMiniGamesUrl('/api/v1/quiz/'+ quizRoom), (quiz)=>{
    if (quiz.status !== 'in_progress'){
      return
    }
    askQuiz(quiz)
  })
}

export function sendQuizAnswer(msg){
  POSTRequest(getMiniGamesUrl('/api/v1/quiz/message'), { room_id: getCurrentRoomId(), message:msg }, (result)=>{
    //TODO хз нужно ли что-то делать, все равно будет WSS уведомление
  })
}

export function quizStartRequest(){
  if (isBuffActive(getCurrentRoomId(), QUIZ_COOL_DOWN)){
    quizStatusEvent('cool')
    return
  }
  quizStatusEvent('start')
  closeCurrentPanel()
  POSTRequest(getMiniGamesUrl('/api/v1/quiz/start'), { room_id: getCurrentRoomId() }, (result)=>{
    //TODO хз нужно ли что-то делать, все равно будет WSS уведомление
  })
}

export function clearQuiz(){
  quizRoom = null
  setStoreWrapper('quizInfo', null)
}

let loaded
export async function loadQuizQuestions(cb){
  if (!loaded){
    loaded = true
    GETRequest(localesUrl + `/api/v1/quiz/${getState('intl.lang')}.json` , (quizQuestions)=>{
      mergeStoreWrapper('intl.content', quizQuestions)
      if (cb){
        cb()
      }
    }, null, true)
  }
  if (cb){
    cb()
  }
}
