import { state } from 'cerebral'
import config from '@/config'
import { MY_INTERESTS_TAGS_LOADED } from '../../../events/CustomEvents'

export const GIF_TAG_ID = 1120922

const {
  vbApiUrl
} = config

let tagsToLoad = []
let loading = false

const getFilterTags = get => {
  let result = []
  const filters = get(state`posts.filters`)
  for (const group of filters) {
    const groupId = Object.keys(group)[0]
    for (const btn of group[groupId]) {
      if (typeof btn === 'number') {
        result.push(btn)
      } else if (btn.tagsId) {
        result = result.concat(btn.tagsId)
      }
    }
  }
  return result
}

export default async function getTagsData({ store, get, props }) {
  const tags = props.tags

  tagsToLoad = [...tags, ...tagsToLoad, ...getFilterTags(get)]
  
  tagsToLoad = tagsToLoad.filter((tagId, idx) => {
    if (tagsToLoad.indexOf(tagId) !== idx) {//remove duplicates
      return false
    }
    if (get(state`intl.tagsData`)[tagId]) {//remove already loaded
      return false
    }
    return true
  })

  if (tagsToLoad.length > 0) {
    setTimeout(() => loadTagsData({ store, get, props }), 100)
  } else {
    //вызовем событие все запрошенное загрузилось
    if (props.needEvent) {
      window.dispatchEvent(new Event(props.needEvent))
    }
  }
}

async function loadTagsData({ store, get, props }) {
  if (loading || tagsToLoad.length === 0) { return }
  loading = true  
  const lang = get(state`intl.lang`)
  const tags = tagsToLoad
  try {
    const response = await fetch(`${vbApiUrl}/vb2/tags/get/${lang}?tags=${tags.join()}`).catch(e => {
      console.warn('tags/get catch error', e)
    })
    if (response && response.ok){
      const tagsData = await response.json()

      tags.forEach(tagId => {
        if (!tagsData[tagId]) {
          tagsData[tagId] = { categories:['admins'] }
        }
      })

      store.set('intl.tagsData', { ...get(state`intl.tagsData`), ...tagsData })
      if (props.needEvent) {
        window.dispatchEvent(new Event(props.needEvent))
      }
    }   
  } catch (e) {
    console.log(e)
    console.log('failed to fetch lang')
}
  
  tagsToLoad = []
  loading = false
}

