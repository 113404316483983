import is from 'is_js'

export const getTime = (timestamp, intl, lang) => {
  if (is.string(timestamp)) {
    timestamp = parseInt(timestamp, 36)
  }
  if (timestamp < 2000000000) {
    timestamp *= 1000
  }

  const now = new Date().getTime()
  const start = new Date(timestamp)
  let tilePassed = now - start
  if (tilePassed < 0) tilePassed = 0

  if (tilePassed < 24 * 60 * 60 * 1000) {
    return start.toLocaleTimeString([lang], { hour: '2-digit', minute:'2-digit' })
  }
  
  if (tilePassed < 48 * 60 * 60 * 1000) {
    return intl.yesterday_at
  }

  if (tilePassed < 300 * 24 * 60 * 60 * 1000) {
    return start.toLocaleDateString([lang], { month: 'short', day:'numeric' })
  }

  return start.toLocaleDateString([lang], { year:'numeric', month: 'short', day:'numeric' })
}

export const getMessageTime = (timestamp, intl, lang) => {

  const now = new Date().getTime()
  const start = new Date(timestamp)
  let tilePassed = now - start
  if (tilePassed < 0) tilePassed = 0

  if (tilePassed < 24 * 60 * 60 * 1000) {
    return start.toLocaleTimeString([lang], { hour: '2-digit', minute:'2-digit' })
  }

  if (tilePassed < 300 * 24 * 60 * 60 * 1000) {
    return start.toLocaleDateString([lang], { month: 'short', day:'numeric' })
  }

  return start.toLocaleDateString([lang], { year:'numeric', month: 'short', day:'numeric' })
}


export const getMessageFullTime = (timestamp, intl, lang) => {
  const start = new Date(timestamp)
  return start.toLocaleDateString([lang || 'en'], { year:'numeric', month: 'short', day:'numeric', hour: '2-digit', minute:'2-digit'  })
}

