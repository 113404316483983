import controller from '@/controller'
import { sequences } from 'cerebral'

export function proStreamWaitSwitcher() {
  const proStarting = controller.getState('room.proBroadcast')

  let streams = controller.getState('posters.raw') || {}
  const myUid = controller.getState('auth.uid')
  const myStream = controller.getState('auth.myStreamId')

  //если ждем PRO трансляцию
  if(proStarting){
    if (streams[myUid]){
      // const panelsOnPop = controller.getSequence('app.panelsOnPop')
      // panelsOnPop({ hideAll: true })
    } else  if (!streams[myUid] && myStream){
      streams[myUid] = {
        streamId:myStream,
        uid:myUid,
        waitForStream:true
      }

      const storeSetUtil = controller.getSequence('app.storeSetUtil')
      storeSetUtil({ key: 'posters.raw', value:streams })
    }
  } else {
    //если ждали, но больше не ждем
    if (streams[myUid] && streams[myUid].waitForStream){
      delete streams[myUid]
      const storeSetUtil = controller.getSequence('app.storeSetUtil')
      storeSetUtil({ key: 'posters.raw', value:streams })
    }
  }
}

