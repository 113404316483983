import { when, wait, set } from 'cerebral/factories'
import { state } from 'cerebral'
import startStream from './startStream'
import showSnackbar from './showSnackbar'


export default [
  startStream,
  /*wait(20000),
  when(state`app.isInPrivate`), {
    true: [],
    false: [
      ({ props, store, get }) => ({
        text: get(state`intl.content.call_cancelled`).replace('%userName%', props.callerName),
        type: 'error' 
      }),
      showSnackbar,
    ]
  }*/
]
