import config from '@/config'
import { getSessionId } from '@/utils'
import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'

const { ftCoreUrl } = config

export default [
  set(state`app.users.${props`uid`}.storage.moderComment`, props`comment`),
  ({ props }) => fetch(`${ftCoreUrl}/api/v1/users/moder_comment`, {
    method: 'POST',
    body: new URLSearchParams({
      sid: getSessionId(),
      uid: props.uid,
      comment: props.comment || '',
    })
  }).catch(e => console.warn('catch error', e)),
]
