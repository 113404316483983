import { state } from 'cerebral'
import is from 'is_js'

export const calculateAngle = ({ store, get }) => {
  const isFrontCamera = get(state`room.isFrontCamera`)
  if (is.iphone() && window.orientation !== undefined) {
    if (window.orientation === 0) { store.set('room.metadata.angle', 90) }
    else {
      if (isFrontCamera) {
        if (window.orientation === -90) { store.set('room.metadata.angle', 0) }
        else if (window.orientation === 90) { store.set('room.metadata.angle', 180) }
      } else {
        if (window.orientation === -90) { store.set('room.metadata.angle', 180) }
        else if (window.orientation === 90) { store.set('room.metadata.angle', 0) }
      }
    }
  } else {
    store.set('room.metadata.angle', 0)
  }
}
