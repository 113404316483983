import signals from './signals'

export default {
  /*state: {
    typeModerFilter: 'potentiallyInteresting',
    data: {
      'potentiallyInteresting': [],
    },
    page: {},
    isLoading: {
      'potentiallyInteresting': false,
    },
    allLoaded: {
      'potentiallyInteresting': false,
    },
  },*/
  state: {
    typeModerFilter: 'potentiallyInteresting',
    data: [],
    page: 0,
    isLoading: false,
    allLoaded: false,
    countCheckedPost: 0,
  },
  sequences: signals
}
