import { set, unset, when } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { send } from '../../socket/actions'

const sendBannedCountries = [
  ({ store, get }) => ({ 
    params: { 
      countries: JSON.stringify(get(state`intl.bannedCountries`))
    }, 
    method: 'setBannedCountries' 
  }),
  send
]

export default [
  when(props`ban`), {
    true: [
      set(state`intl.bannedCountries.${props`country`}`, true),
      sendBannedCountries
    ],
    false: [
      unset(state`intl.bannedCountries.${props`country`}`),
      sendBannedCountries
    ]
  }
]
