import { set } from 'cerebral/factories'
import { state } from 'cerebral'

export default [
  ({ props, store, get }) => {
    if (get(state`app.hideTopBar`) !== props.value) {
      store.set('app.hideTopBar', props.value)
    }
  }
]
