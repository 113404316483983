import { send } from '../../socket/actions'
import deleteVideoUrl from '../actions/deleteVideoUrl'

export default [
  deleteVideoUrl,
  {
    deleted:[
      () => ({
        method: 'endWatchRecord',
      }),
      send,
    ],
    fail:[]
  }
]
