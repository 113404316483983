import { concat, set } from 'cerebral/factories'
import { state, props } from 'cerebral'
import loadGirls from '../actions/loadGirls'
import { generateNameFromUid } from '../../auth/actions/generateNameFromUid'
import { isUsersLoaded, getUser, loadUsers } from '@/utils/userLoader'

export default [
  loadGirls,{
    success:[
      ({ store, get, props }) => {
        let users = []
        for (const idx in props.data) {
          const modelData = props.data[idx]
          if (!modelData.name) {
            modelData.name = generateNameFromUid(modelData.uid)
          }
          users.push(modelData.uid)
          /*if (!get(state`app.users.${modelData.uid}`)) {
            store.set(`app.users.${modelData.uid}`, { model: 1, ...modelData })
          }*/
        }
        loadUsers(users)
      },
      concat(state`girls.data`, props`data`),
      set(state`girls.isLoading`, false)
    ],
    fail:[
      set(state`girls.isLoading`, false)
    ]
  }
]
