import { getState, setStoreWrapper } from '../StoreManager'

export function addNewMessage(){
  setStoreWrapper('lastNewMSG', Date.now())
  setStoreWrapper('smoothScroll', true)
}

export function addAllMessages(){
  setStoreWrapper('smoothScroll', false)
}

export function overlayChatScrollToEnd(){
  //TODO не скролить, если отскролено
  setTimeout(()=>{
    const element = document.getElementById('overlayChatScrollContainer')
    if (!element) return false
    element.scrollTop = 9999999999999999
  }, 100)
}

export function scrollOnAction(){
  if (!getState('overlayScrolled')){
    addNewMessage()
    overlayChatScrollToEnd()
  }
}