import { props, sequence, state } from 'cerebral'
import { setStoreWrapper } from '../../../utils/StoreManager'
import { merge } from 'cerebral/factories'
import { getLocaleValue } from '../../../utils/localeUil'
import controller from '@/controller'

let timeout

export const showSnackbar = (text, ms = 5000, type = 'success', additionalInfo = null) =>
  sequence('showSnackbar', [
    merge(state`app.snackbar`, { state: 'in', text, type, additionalInfo }),
    ({ props }) => {
      if (timeout){
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        setStoreWrapper('app.snackbar', { state: 'out', text: '', type: 'success' })
      },  props.ms || 7000)
    }
  ])

export function showSnackbarNew(text, ms = 5000, type = 'success', additionalInfo = null) {
  const showSnackbar = controller.getSequence('app.showSnackbar')
  // ms = 5000, type = 'success', additionalInfo = null
  showSnackbar({
    text: text,
    ms:ms,
    type: type,
    additionalInfo:additionalInfo
  })
}

