import { isTWA } from '../../utils/twa'
import { allPostRatings, allSubsRatings, isOnlyApprovedPosts } from '../../utils/ageRating/ageRatingUtil'
import { getSessionId } from '../../utils'
import { FACE_DETECTED, FEMALE, GENDER_MISMATCH_TAG, MALE, PROFILE_TAG } from '../../utils/postUtils'
import { getState } from '../../utils/StoreManager'
import { imFemale, isMyProfileSet } from '../../utils/my'
import { TYPE_FANS, TYPE_MATCH } from './signals/getPosts'
import { state } from 'cerebral'
import { getSelectedTags } from '../filters/actions/feedUtil'
import { getParentDomain } from '../../utils/urlUtils'
import { isDiversity } from '../../utils/appConfigUtil'

export function getDatingParams(noGender = false, onlyApproved = false, noProfileTag = false, genderTag = false, withNoFace = false) {
  let tags = getSelectedTags()
  if(!noProfileTag){
    tags.push(PROFILE_TAG)
  }
  if (!withNoFace && !tags.includes(FACE_DETECTED)){
    tags.push(FACE_DETECTED)
  }

  let queryString = 'lang=' + getState('intl.lang') + '&tags=' + tags.toString()
  //если теги не включают пол, то добавим дефолт, если он нужен
  if (!tags.includes(MALE) && !tags.includes(FEMALE)){
    if (!noGender){
      //если диверсити, то подставляем пол только если есть аккаунт
      if (!genderTag) {
        if (isDiversity()){
          if (isMyProfileSet()) {
            queryString += '&oppositeGender=true'
          }
        } else {
          queryString += '&oppositeGender=true'
        }
      } else {
        if (isDiversity()){
          if (isMyProfileSet()) {
            tags.push(imFemale() ? MALE : FEMALE)
          }
        } else {
          tags.push(imFemale() ? MALE : FEMALE)
        }
      }
    }
  }

  //для TWA только проверенные в ручную
  queryString += `&ageRating=${allPostRatings}`

  //для остальных только безопасные по версии алгорима
  if (!isTWA() && !onlyApproved && !getParentDomain().includes('flirtymania.dating')){
    queryString += '&isNotAdult=true&withOnModeration=true'
  }

  queryString += '&withNotInteresting=true'

  const sid = getSessionId()
  if (sid) {
    queryString += `&sid=${sid}`
  }
  return queryString
}

export function getProfilesParams(type, withNotInteresting) {
  let queryString = 'lang=' + getState('intl.lang') + '&tags=' + PROFILE_TAG

  //для TWA только проверенные в ручную
  queryString += `&ageRating=${allPostRatings}`

  //для остальных только безопасные по версии алгорима
  if (!isTWA()){
    queryString += '&isNotAdult=true&withOnModeration=true&withNotInteresting=true'
  } else {
    if (withNotInteresting){
      queryString += '&withNotInteresting=true'
    }
  }

  const sid = getSessionId()
  if (sid) {
    queryString += `&sid=${sid}`
  }

  if (type === TYPE_FANS){
    queryString += '&fromFollowers=1'
  } else if (type === TYPE_MATCH){
    queryString += '&matchAuthors=1'
  }

  return queryString
}

export function getSubsParams() {
  let queryString = ''

  const subsCount = getState('auth.subsCount') || 0
  if (subsCount === 0) {
    queryString += '&promoAuthors=true'
    queryString += `&ageRating=${allPostRatings}`
  } else {
    queryString += '&userFeed=1'
    queryString += `&ageRating=${allSubsRatings}`

    //для остальных только безопасные по версии алгорима
    if (!isTWA()){
      queryString += '&isNotAdult=true&withOnModeration=true'
    }
  }
  queryString += '&withNotInteresting=true'

  const sid = getSessionId()
  if (sid) {
    queryString += `&sid=${getSessionId()}`
  }

  const tags = getSelectedTags()
  if (tags && tags.length > 0) {
    queryString += `&tags=${tags.toString()}`
  }
  return queryString
}

export function getNoGenderMismatchParam() {
  return '&excludeTags=' + GENDER_MISMATCH_TAG
}
