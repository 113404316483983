import controller from '@/controller'
import config from '@/config'
import storeSetUtil from '../signals/StoreSetUtil'
import { getSessionId } from '../cookies'
import { getAgeRatingByUid } from '../../modules/posters/showInRoom'
const { streamApiURL } = config
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import getLocale from '../../modules/intl/signals/getLocale'
import { getLocaleValue } from '../localeUil'
import { proStreamWaitSwitcher } from './proStreamUtil'
import { onPublishEvent } from '../amplitudeLiveEvents'
import is from 'is_js'
import { isMobileSkin } from '../checks'
import { getState, setStoreWrapper } from '../StoreManager'
import { getMyLSProp, setMyLSProps } from '../LocalStorageUtil'

export function checkDevice(deviceID, devices) {

  if (!deviceID || !devices){
    return false
  }

  let deviceHere = false
  devices.forEach(device => {
    if (device.deviceId === deviceID){
      deviceHere = true
    }
  })

  return deviceHere
}

export function wowzaStreamMediaHack() {
  if (!navigator.mediaDevices){
    console.log('!!! noMedia wowzaStreamMediaHack')
    return
  }
  navigator.mediaDevices.enumerateDevices().then(devices => {
    const deviceArray = new Array()
    devices.forEach(device => {
      if (device.kind == 'audioinput' || device.kind == 'videoinput') {
        deviceArray.push(device)
      }
    })
    console.log('dvc ' + devices.length)
    updateDevices(devices)
  }).catch(err => {
    console.error('Cannot get devices -> error name: ' + err.name + ': ' + err.message)
  })
}

export function updateDevices(devices) {
  console.log(devices)
  const storeSetUtil = controller.getSequence('app.storeSetUtil')
  if (!devices) {
    storeSetUtil({ key: 'room.currentVideoDeviceId', value:null })
    storeSetUtil({ key: 'room.currentAudioDeviceId', value:null })
    return
  }
  const videoDevices = devices.filter(device => device.kind === 'videoinput') || []
  const audioDevices = devices.filter(device => device.kind === 'audioinput') || []

  //проверим не отключили ли прошлую кмеру
  if (!checkDevice(controller.getState('room.currentVideoDeviceId'), videoDevices)){
    storeSetUtil({ key: 'room.currentVideoDeviceId', value:getDefaultCamId(videoDevices) })
  }

  //проверим не отключили ли прошлый микрофон
  if (!checkDevice(controller.getState('room.currentAudioDeviceId'), audioDevices)){
    storeSetUtil({ key: 'room.currentAudioDeviceId', value:getDefaultMicId(audioDevices) })
  }

  const setVideoDevices = controller.getSequence('room.setVideoDevices')
  setVideoDevices({ videoDevices, audioDevices })

  // onPublishEvent('update_devices',{
  //   video_devices: videoDevices.length,
  //   audio_devices: audioDevices.length
  // })
}

export function getDefaultCamId(videoDevices) {
  if (videoDevices && videoDevices[0] && videoDevices[0].deviceId){
    const storeSetUtil = controller.getSequence('app.storeSetUtil')
    storeSetUtil({ key: 'room.currentVideoDeviceId', value:videoDevices[0].deviceId })
    return videoDevices[0].deviceId
  }
  return undefined
}

export function getDefaultMicId(audioDevices) {
  if (audioDevices && audioDevices[0] && audioDevices[0].deviceId){
    const storeSetUtil = controller.getSequence('app.storeSetUtil')
    storeSetUtil({ key: 'room.currentAudioDeviceId', value:audioDevices[0].deviceId })
    return audioDevices[0].deviceId
  }
  return undefined
}

export function getMediaConstraintsPublish() {
  let mediaConstraints = {
    video : true,
    audio : true
  }
  let did
  if (isMobileSkin()){
    did = getDefaultMicId(controller.getState('room.audioDevices'))
  } else {
    did = controller.getState('room.currentAudioDeviceId') || getDefaultMicId(controller.getState('room.audioDevices'))
  }

  if (did){
    mediaConstraints.audio = { deviceId: { deviceId: did } }
  }

  if (isMobileSkin()){
    did = getDefaultCamId(controller.getState('room.videoDevices'))
  } else {
    did = controller.getState('room.currentVideoDeviceId') ||  getDefaultCamId(controller.getState('room.videoDevices'))
  }

  if (did){
    mediaConstraints.video = { deviceId: { exact: did } }
  }

  return mediaConstraints
}

// export function getPublishToken() {
//   return controller.getState('auth.streamToken') || genPublishToken()
// }

export async function getPublishToken() {
  if (controller.getState('auth.streamToken')){
    // sendAmplitudeEvent('stream_token', { status:'success' })
    return
  }

  if (controller.getState('auth.loadingToken' + controller.getState('auth.uid'))){
    return
  }
  let tokenStatus = 'error'
  const storeSetUtil = controller.getSequence('app.storeSetUtil')
  try {
    storeSetUtil({ key: 'auth.loadingToken' + controller.getState('auth.uid'), value:true })

    const response = await fetch(`${streamApiURL}/api/v1/streams/?ant_app=` + getPublishApp(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      }
    }).catch(e => {
      console.warn('genPublishToken catch error', e)
    })
    if (response && response.ok) {
      const respObj = await response.json()
      if (respObj.publish_token || respObj.token){
        tokenStatus = 'success'
        storeSetUtil({ key: 'auth.streamToken', value:respObj.publish_token || respObj.token })
        storeSetUtil({ key: 'auth.myStreamId', value:respObj.stream_id })

        proStreamWaitSwitcher(true)
      } else {
        tokenStatus = 'error_no_token'
        console.warn('genPublishToken catch noToken')
      }
    } else {
      console.warn('genPublishToken catch error')
    }
  } catch (e) {
    console.warn('genPublishToken catch error', e)
  }

  //покажем верхнее сообщение об ошибке
  if (tokenStatus !== 'success'){
    const showSnackbar = controller.getSequence('app.showSnackbar')
    showSnackbar({
      text: getLocaleValue('stream_token_error'),
      type: 'error'
    })

    sendAmplitudeEvent('stream_token', { status:tokenStatus })
  }

  storeSetUtil({ key: 'auth.loadingToken' + controller.getState('auth.uid'), value:false })
}

export function getPublishApp(){
  return !needAdaptive() ? 'main_app' : 'adaptive_app'
}

export function getPublishURL(){
 return !needAdaptive() ? config.antWsUrl : config.antWsUrlAdaptive
}

//TODO по идее можно делать несколько коннекто и смотреть с разных серверов
export function getPlayURL(){
  return config.antWsUrl
}

//TODO определение когда нужен адаптивный стриммер
function needAdaptive() {
  return false//getState('noH264') || getMyLSProp('noH264')
}

export function setNoH264() {
  setStoreWrapper('noH264', true)
  setMyLSProps('noH264', true)
}

//получение id стрима по uid
export function getStreamName(uid) {
  const allBC = controller.getState('posters.raw')
  let info = allBC[uid]
  if (!info){
    fetch(`${streamApiURL}/api/v1/streams/uid/` + uid, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      }
    }).then((response) => {
      if (response && response.ok) {
        response.json().then(respObj =>{
          if (respObj.stream_id){
            let companionUid = controller.getState('room.companionUid')

            //если он все еще компаньен, то добавим его стрим в список стримов
            if (companionUid){
              let streams = controller.getState('posters.raw')
              if (!streams[companionUid]){
                streams[companionUid] = {
                  uid:companionUid,
                  waitForStream:true,
                  streamId:respObj.stream_id
                }
                const storeSetUtil = controller.getSequence('app.storeSetUtil')
                storeSetUtil({ key: 'posters.raw', value:streams })
              }
            }
          } else {
            retryToGetStreamInfo(uid)
            console.warn('getStreamName catch stream')
          }
        }).catch(e =>{
          retryToGetStreamInfo(uid)
          console.warn('getStreamName catch parce')
        })
      } else {
        retryToGetStreamInfo(uid)
        console.warn('getStreamName catch error')
      }
    }).catch(e => {
      retryToGetStreamInfo(uid)
      console.warn('getStreamName catch error', e)
    })
  }
}

function retryToGetStreamInfo(uid) {
  let companionUid = controller.getState('room.companionUid')
  if (companionUid && uid == companionUid){
    setTimeout(() => {
      console.log('retry streamId ' + uid)
      getStreamName(uid)
    }, 1000)
  }
}

export function checkMediaDevices(){
  return window.navigator && window.navigator.mediaDevices
}