import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { requestPaidOparation } from '../../shop/actions/requestPaidOparation'

export default [
  ({ props, store, get }) => ({
    cost: Math.floor(props.cost * get(state`intl.settings.peepCost`)),
    reason: 'peeping-start'
  }),
  requestPaidOparation, {
    enoughCoins: [
      ({ props }) => ({
        params: {
          peep: true,
          model: props.uid
        },
        method: 'startPrivateShow'
      }),
      send,
    ],
    shopOpened: []
  },
  ({ store, get, props }) => {
    store.set('room.peepPrivateData', {
      topic_id: props.themeId,
      model_id : props.uid,
      startTime: Date.now(),
      cost_per_minute_in_coins: props.cost,
      cost_per_minute_in_percents: get(state`intl.settings.peepCost`),
      costs_in_coins: 0
    })
  }
]
