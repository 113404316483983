import { getGEOUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'

const pages = {}
const loadingStatus = {}
const ONE_PAGE_ITEMS = 30

export const HIDDEN_COUNTRY = 'hidden_country'
export const HIDDEN_CITY = 'hidden_city'

export function getCountriesList(name) {
  return getGeoList(true, name)
}

export function getCitiesList(name) {
  return getGeoList(false, name)
}

export function getCountriesLoadingStatus(name) {
  return getLoadingStatus(getCountriesSearchID(name) )
}

export function getCitiesLoadingStatus(name) {
  return getLoadingStatus(getCitiesSearchID(name))
}

function getGeoList(country, name) {
  const listId = country ? getCountriesSearchID(name) : getCitiesSearchID(name)

  const geoLists = getState('geoLists') || {}
  return geoLists[listId] || []
}

export function getCountriesSearchID(name) {
  return 'countriesList' + name
}

export function getCitiesSearchID(name) {
  return 'citiesList' + getState('selectedCountry') + name
}

export function loadCountriesList(name = '', callback = null) {
  loadGeoList(true, name, callback)
}

export function loadCitiesList(name = '', callback = null) {
  loadGeoList(false, name, callback)
}

async function loadGeoList(country, name, callback) {
  const loadingType = country ? getCountriesSearchID(name) : getCitiesSearchID(name)
  if (loadingStatus[loadingType]){
    if (callback){
      callback(loadingType, loadingStatus[loadingType])
    }
    return
  }

  loadingStatus[loadingType] = true
  setStoreWrapper('loadingGeoStatus', loadingStatus)
  let page = pages[loadingType] || 0

  try {
    let url = getGEOUrl('/api/v1/' + (country ? 'countries' : 'cities'))
    url += '?page=' + page

    if (name){
      url += '&name='+name
    }

    if (!country){
      const selectedCountry = getState('selectedCountry')
      if (selectedCountry){
        url += '&country='+selectedCountry
      }
    }


    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    delete loadingStatus[loadingType]
    setStoreWrapper('loadingGeoStatus',loadingStatus)
    if (!response.ok) {
      if (callback){
        callback(loadingType, 'error')
      }
      return
    }

    page++
    pages[loadingType] = page
    let result = await response.json()
    if (country){
      result = result.countries
    } else {
      result = result.cities
    }

    if (result < ONE_PAGE_ITEMS){
      loadingStatus[loadingType] = 'allLoaded'
    }

    const allTypes = getState('geoLists') || {}
    allTypes[loadingType] = (allTypes[loadingType] || []).concat(result)

    setStoreWrapper('loadingGeoStatus',loadingStatus)
    setStoreWrapper('geoLists', allTypes)
    if (callback){
      callback(loadingType, 'success')
    }
  } catch (e) {
    delete loadingStatus[loadingType]
    if (callback){
      callback(loadingType, 'error')
    }
    setStoreWrapper('loadingGeoStatus',loadingStatus)
    console.log('loadCountryError ' + loadingType)
  }
}

function getLoadingStatus(name) {
  return loadingStatus[name]
}

export async function getLocationByGEO(latitude, longitude) {
  if (!latitude || !longitude){
    return
  }

  try {
    let url = getGEOUrl('/api/v1/city-by-geo?latitude=' + latitude + '&longitude=' + longitude)

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      setStoreWrapper('locationLoading', false)
      return
    }

    let result = await response.json()

    setStoreWrapper('selectedCountry', result.country)
    setStoreWrapper('selectedCityName', result.city)
    setStoreWrapper('selectedCityId', result.geoname_id)
    setStoreWrapper('locationLoading', false)
  } catch (e) {
    setStoreWrapper('locationLoading', false)
    console.log('getLocationByGEO ')
  }
}
