import amplitude from 'amplitude-js'
import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import GAEvent from '../../../utils/GAEvent'
import config from '@/config'
const { ftCoreUrl } = config

export default [
  ({ store, get, props }) => {
    const allowed = !get(state`auth.stuff.allowNsfw`)
    
    fetch(`${ftCoreUrl}/api/v1/users/nsfw`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sid: getSessionId(),
        allowNsfw: allowed
      })
    }).catch(e => console.warn('catch error', e))
    store.set('auth.stuff.allowNsfw', allowed)
    store.set('auth.stuff.allowedColor', allowed)
    
    const userProps = new amplitude.Identify()
    userProps.set('nsfw', allowed)
    amplitude.getInstance().identify(userProps)

    GAEvent('nsfw', allowed ? 'enable' : 'disable', props.contentType, 0)
  }
]
