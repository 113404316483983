import is from 'is_js'
import controller from '@/controller'
import { logger } from '../logger'
import { enhanceSDP, makeAndroidSDP } from './enhanceSdp'
import config from '@/config'
import { isFileExist } from '../checks'
import { getStreamInfo } from '.'

const { streamUrl, hls_restreamerAppName } = config

const RTCPeerConnection = window.RTCPeerConnection
const RTCIceCandidate = window.RTCIceCandidate
const RTCSessionDescription = window.RTCSessionDescription

let checkStreamTimer = null

export default class Publisher {
  constructor(endpoint, options) {
    this.stream = null
    this.ws = null
    this.pc = null
    this.endpoint = endpoint
    this.userData = {
      sessionId: '[empty]'
    }
    this.peerConnectionConfig = {
      iceServers: []
    }
    this.SDPOutput = new Object()
    if (options) {
      if (options.userData) {
        this.userData = { ...this.userData, ...options.userData }
      }
      if (options.peerConnection) {
        this.peerConnectionConfig = {
          ...this.peerConnectionConfig,
          ...options.peerConnection
        }
      }
      if (options.pconclose) {
        this.pconclose = options.pconclose
      }
      if (options.pconerror) {
        this.pconerror = options.pconerror
      }
      if (options.onStreamStarted) {
        this.onStreamStarted = options.onStreamStarted
      }
    }
  }

  connect(stream) {
    logger.info('CONNECT', this.endpoint)
    return this.disconnect()
      .then(() => {
        this.pc = new RTCPeerConnection(this.peerConnectionConfig)
        window.pc = this.pc
        this.pc.onicecandidate = event => {
          //logger.info('ON ICE CANDIDATE', event)
        }
        this.pc.onclose = () => {
          logger.info('PC CLOSED')
          this.pconclose()
        }
        this.pc.onerror = () => {
          logger.info('PC ERROR')
          this.pconerror()
        }
        this.pc.onconnectionstatechange = evt => {
          console.log('@@@ onconnectionstatechange', evt.target.connectionState)
          if (evt.target.connectionState === 'failed' || 
              evt.target.connectionState === 'disconnected') {
            console.log('@@@ RECONNECT')
            setTimeout(() => this.connect(stream), 2000)
          }
        }
        if (typeof this.pc.addStream === 'undefined') {
          stream.getTracks().forEach(track => {
            this.pc.addTrack(track, stream)
          })
        } else {
          this.pc.addStream(stream)
        }
        return Promise.resolve()
      })
      .then(this.createOffer.bind(this))
      .then(this.setLocalDescription.bind(this))
      .then(this.connectWebSocket.bind(this))
      .then(() => {
        this.stream = stream
        clearTimeout(checkStreamTimer)
        checkStreamTimer = setTimeout(() => this.checkStream(stream), 20000)
        return stream
      })
      .catch(e => {
        console.warn('PUBLISHER CONNECT ERROR: ', e)
        if (e.status !== 503) {
          const stopStream = controller.getSequence('room.stopStream')
          stopStream()
          const showSnackbar = controller.getSequence('app.showSnackbar')
          showSnackbar({ text: controller.getState('intl.content.error_try_again'), type: 'error' })
          throw new Error('@@@ start stream error: ', e)
        }
        
        setTimeout(() => {
          if (controller.getState('room.streamInRoom')) {
            this.connect(stream)
          }
        }, 1000)
      })
  }

  closeStream = () => new Promise(resolve => {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        try {
          track.stop()
        } catch (e) {
          console.warn('track stop error', e)
        }
      })
    }
    this.stream = null
    return resolve()
  })

  closePeerConnection = () => new Promise(resolve => {
    if (this.pc) {
      this.pc.close()
    }
    this.pc = null
    return resolve()
  })

  closeWebSocket = () => new Promise(resolve => {
    if (this.ws) {
      this.ws.close()
    }
    this.ws = null
    return resolve()
  })

  disconnect() {
    logger.info('DISCONNECT', '')
    return Promise.all([this.closePeerConnection(), this.closeWebSocket()])
  }

  disconnectAndStopUserMedia() {
    logger.info('DISCONNECT AND STOP USERMEDIA', '')
    return Promise.all([this.closeStream(), this.closePeerConnection(), this.closeWebSocket()])
  }

  replaceStream = newStream => {
    this.closeStream().then(() => {
      this.stream = newStream
      this.pc.getSenders().map(sender =>
        sender.replaceTrack(
          newStream.getTracks().find(track => track.kind === sender.track.kind),
          newStream
        )
      )
    })
  }

  toggleAudio = isEnabled => {
    if (!this.stream) return
    const audioTracks = this.stream.getAudioTracks()
    for (let i = 0; i < audioTracks.length; i++) {
      audioTracks[i].enabled = isEnabled
    }
  }

  toggleVideo = isEnabled => {
    if (!this.stream) return
    const videoTracks = this.stream.getVideoTracks()
    for (let i = 0; i < videoTracks.length; i++) {
      videoTracks[i].enabled = isEnabled
    }
  }

  checkStream = async stream => {
    clearTimeout(checkStreamTimer)
    checkStreamTimer = null

    if (!controller.getState('room.streamInRoom')) return
    
    const myUid = controller.getState('auth.uid')
    if (controller.getState('room.codec') !== 'H264') {
      if (this.onStreamStarted) {
        this.onStreamStarted()
      }
      checkStreamTimer = setTimeout(() => this.checkStream(stream), 20000)
      return
    }
    if (await isFileExist(`${streamUrl}/${hls_restreamerAppName}/${myUid}_aac/playlist.m3u8`)) {
      if (this.onStreamStarted) {
        this.onStreamStarted()
      }
      checkStreamTimer = setTimeout(() => this.checkStream(stream), 20000)
    } else {
      this.connect(stream)
    }
  }
  
  createOffer() {
    return this.pc.createOffer().then(description => description)
    .catch(e => logger.warn('CREATE OFFER ERROR: ', e))
  }

  setLocalDescription(description) {
    const setCodec = controller.getSequence('room.setCodec')

    if (is.desktop()) {
      description.sdp = enhanceSDP(description.sdp, setCodec)
    } else {
      description = makeAndroidSDP(description, setCodec)
    }
    
    return this.pc.setLocalDescription(description).then(() => description)
  }

  connectWebSocket(description) {
    return new Promise((resolve, reject) => {
      this.ws = new WebSocket(this.endpoint)
      this.ws.binaryType = 'arraybuffer'
      this.ws.onopen = () => {
        logger.info('WEBSOCKET ON OPEN', description)
        this.sendPublishMessage(description)
      }
      this.ws.onmessage = async event => {
        logger.info('WEBSOCKET ON MESSAGE', event)
        const data = JSON.parse(event.data)
        const status = Number(data.status)
        if (status !== 200) {
          await this.disconnect()
          return reject(data)
        } else {
          if (data.sdp !== undefined) {
            this.setRemoteDescription(data)
          }
          this.addIceCandidates(data)
        }
        if (this.ws !== null) {
          //this.ws.close()
          //this.ws = null
        }
        resolve()
      }
      this.ws.onclose = () => {
        logger.info('WEBSOCKET ON CLOSE', '')
      }
      this.ws.onerror = async event => {
        logger.warn('WEBSOCKET ON ERROR', event)
        await this.disconnect()
        reject(event)
      }
    })
  }

  sendPublishMessage(description) {
    this.ws.send(
      JSON.stringify({
        direction: 'publish',
        command: 'sendOffer',
        streamInfo: getStreamInfo(),
        sdp: description,
        userData: this.userData
      })
    )
  }

  setRemoteDescription(description) {
    return this.pc.setRemoteDescription(
      new RTCSessionDescription(description.sdp)
    )
  }

  addIceCandidates(data) {
    logger.info('ADD ICE CANDIDATES', data)
    if (data.iceCandidates !== undefined) {
      data.iceCandidates.forEach(iceCandidate => {
        this.pc.addIceCandidate(new RTCIceCandidate(iceCandidate))
      })
    }
  }
}
