import addBuff from './addBuff'
import delBuff from './delBuff'
import buyBuff from './buyBuff'
import sendBuff from './sendBuff'
import saveUserComment from './saveUserComment'
import getDailyCoins from './getDailyCoins'
import moderSetBuff from './moderSetBuff'

export default {
  addBuff,
  delBuff,
  buyBuff,
  sendBuff,
  moderSetBuff,
  saveUserComment,
  getDailyCoins
}
