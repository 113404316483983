import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'

const { vbApiUrl } = config

export const getPaidPhoto = async ({ store, get, path, props }) => {
  const postsData = get(state`posts.postsData`)
  const findedPost = postsData[props.postId]
  if (findedPost && findedPost.original) {
    return path.success()
  }

  try {
    const answer = await fetch(vbApiUrl + '/vb/posts/paid', {
                                          method: 'POST',
                                          body: new URLSearchParams({
                                            sid: getSessionId(),
                                            postId: props.postId
                                          }),
                                        })
    
    if (answer.ok) {
      const json = await answer.json()
      if (findedPost) {
        findedPost.original = json.result
        store.set(`posts.postsData.${props.postId}`, findedPost)
        return path.success()
      }
    }
  } catch (e) {
    console.warn('@@@ get paid photo error:', e)
  }
  return path.fail()
}
