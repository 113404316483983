import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { sendAmplitudeEvent } from '@/utils/GAEvent'

export default [
  ({ props }) => ({ 
    params: props, 
    method: 'payoutRequest' 
  }),
  send,
  ({ props, store, get }) => store.set('app.payoutData', {
    coins: get(state`auth.coins`),
    wallet: props.wallet,
    type: props.walletType,
  })
]
