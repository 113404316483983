import clipboard from 'clipboard-js'
import { showSnackbar } from '../factories'
import { state } from 'cerebral'

export default [
  ({ props }) => {
    clipboard.copy(props.value.toString())
  },
  showSnackbar(state`intl.content.sr_copied`, 5000)
]
