import { getCurrentRoomId, getRoomOwnerUid, isMyRoom } from '../navigation/navigationUtil'
import { getState } from '../StoreManager'
import { isMe } from '../checks'
import { isVipStream } from '../../modules/posters/showInRoom'
import { imVIP } from '../my'

export function isPrivateInCurrentRoom(notMy){
  return isPrivateInRoom(getCurrentRoomId(), notMy)
}

export function isPrivateInRoom(roomId, notMy){
  const roomOwnerId = getRoomOwnerUid(roomId)
  const privates = getState('posters.privates')

  if (privates && privates[roomOwnerId]) {
    const privateShow = privates[roomOwnerId]
    if (notMy){
      return !isMe(privateShow.viewer) && !isMe(privateShow.model)
    } else {
      return true
    }
  }

  return false
}

export function cantWatchCurrentStream(){
  //для привата отдельная логика, если он есть и он не мой то закроем
  const roomId = getCurrentRoomId()
  if (isPrivateInRoom(roomId)){
    return isPrivateInRoom(roomId, true)
  }
  return !isMyRoom() && isVipStream(getCurrentRoomId()) && !imVIP()
}

export function isMyCall(){
  const roomId = getCurrentRoomId()
  if (isPrivateInRoom(roomId)){
    return !isPrivateInRoom(roomId, true)
  }
  return false
}
