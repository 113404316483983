import { getState } from './StoreManager'
import { COIN_500_ICON } from './stringUtils'
import { isCDNDisable } from './appUtil'
import { PROXY_PHOTOS_URL } from './getPreviewUrl'

export function getSettings(key) {
  return (getState('intl.settings') || {})[key]
}

export function getSettingsInt(key) {
  return getSettings(key) || 0
}

export function getSettingsCost(key) {
  return COIN_500_ICON + getSettingsInt(key)
}

export function getPostPhotoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl){
    return null
  }
  if (isProdEnv){
    return (isCDNDisable() ? PROXY_PHOTOS_URL : settingsUrl.photos_url_prod) + fileName
  }
  return (isCDNDisable() ? PROXY_PHOTOS_URL : settingsUrl.photos_url_dev) + fileName
}

export function getPostVideoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl){
    return null
  }
  if (isProdEnv){
    return settingsUrl.videos_url_prod + fileName
  }
  return settingsUrl.videos_url_dev + fileName
}
