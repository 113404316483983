import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getSessionId } from '@/utils'
const { stickersPacks, vbApiUrl } = config

export default async ({ store, get, props }) => {
  const sid = getSessionId()
  const postId = props.postId

  let result
  try {
    const response = await fetch(`${vbApiUrl}/logger/get_comments?sid=${sid}&postId=${postId}&instid=vcr&fileId=0`)
    if (!response.ok) { return }
    result = await response.text()
  } catch (e) {
    return
  }
  const allMsg = result.toString().split('<br>')
  const msgData = get(state`chat.dialogs`) || {}
  const usersToLoad = []

  for (let i = 0; i < allMsg.length; i++) {
    if (allMsg[i].length < 10) {
      continue
    }
    
    let str = ''
    
    str = allMsg[i].split('>')
    
    let time = str[0].substring(0, 10)
    time = (parseInt(time) * 1000).toString(36)
    const from = str[0].substring(10)
    const texts = JSON.parse(str[1]) 
    const text = texts[ get(state`intl.lang`) ] || texts.original
    let sticker = null

    for (const packType in stickersPacks) {
      if (sticker) { break }

      const stickers = stickersPacks[packType]
      for (const idx in stickers) {
        const stickerId = stickers[idx]
        if (text === stickerId) {
          sticker = stickerId
          break
        }
      }
    }

    usersToLoad.push(from)

    if (!msgData[postId]) {
      msgData[postId] = []
    }

    msgData[postId].push({
      from: from,
      postId: time,
      text:text,
      sticker: sticker,
      post: postId,
      fileId: 0,
      myPost: Object.keys(texts).length > 2 && from === get(state`auth.uid`)
    })
  }

  loadUsers(usersToLoad, ()=>{
    store.set('chat.dialogs', msgData)
  })
}
