import controller from '@/controller'
import is from 'is_js'
import { wowzaStreamMediaHack } from '../stream/streamUtil'

export const getUserMedia = async () => {
  try {
    if (!navigator.mediaDevices) {
      return null
    }
    const constraints = {
      audio: true,
      video: true
    }

    if (is.mobile()) {
      const isFrontCamera = controller.getState('room.isFrontCamera')
      constraints.video = {
        facingMode: isFrontCamera ? 'user' : 'environment'
      }
    } else if (controller.getState('room.isScreenShared')) {
      return await navigator.mediaDevices.getDisplayMedia(constraints)
    } else {
      const currentCamIndex = controller.getState('room.currentVideoDeviceIndex')
      const allCams = controller.getState('room.videoDevices') || []
      const vDevice = allCams.length > 0 && allCams[Math.min(currentCamIndex, allCams.length - 1)]
      if (vDevice && vDevice.deviceId) {
        constraints.video = { deviceId: { exact: vDevice.deviceId } }
      }
      const currentMicIndex = controller.getState('room.currentAudioDeviceIndex')
      const allMics = controller.getState('room.audioDevices') || []
      const aDevice = allMics.length > 0 && allMics[Math.min(currentMicIndex, allMics.length - 1)]
      if (aDevice && aDevice.deviceId) {
        constraints.audio = { deviceId: { exact: aDevice.deviceId } }
      }
    }
    
    return await navigator.mediaDevices.getUserMedia(constraints)
  } catch (e) {
    console.warn('@@@ getUserMedia error', e)
    const showSnackbar = controller.getSequence('app.showSnackbar')
    showSnackbar({
      text: controller.getState('intl.content.denied_access_to_the_camera'), 
      type: 'alarm'
    })
    const room = controller.getState('room')
    room.streamInRoom = null
    controller.flush()
    
    return null
  }
}
