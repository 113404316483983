import { state } from 'cerebral'

export const prepareRoute = ({ store, get, props }) => {
  let { url } = props
  const { urlValue, urlParams } = props
  const locale = get(state`intl.lang`)  
  url = `/${locale}${url}`
  if (urlValue) url += `/${urlValue}`
  if (urlParams) url += `?${urlParams}`
  return { url }
}
