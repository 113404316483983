import app from './app'
import chat from './chat'
import auth from './auth'
import intl from './intl'
import shop from './shop'
import room from './room'
import buffs from './buffs'
import socket from './socket'
import upload from './upload'
import posters from './posters'
import posts from './posts'
import payment from './payment'
import girls from './girls'
import useragent from './useragent'
import sponsors from './sponsors'
import moderation from './moderation'

export default {
  app,
  chat,
  room,
  girls,
  intl,
  auth,
  shop,
  buffs,
  socket,
  upload,
  posters,
  posts,
  payment,
  useragent,
  sponsors,
  moderation
}
