import { state } from 'cerebral'

export default [
  ({ props, store, get }) => {
    const post = get(state`posts.postsData.${props.postId}`)
    if (post.tags) {
      !post.tags.includes(props.setTag) && !props.onlyDel  
      ? post.tags.push(props.setTag) 
      : post.tags.splice(post.tags.indexOf(props.setTag), 1)
    } else {
      post.tags = new Array()
      post.tags.push(props.setTag)
    }
    store.set(`posts.postsData.${props.postId}`, post)
  }
]
