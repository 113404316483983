import adapter from 'webrtc-adapter'//эту либу достаточно заимпортить, чтобы она работала

import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr) {
		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr)
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr)
	}
}

// polyfill from https://developer.mozilla.org/ru/docs/Web/API/HTMLCanvasElement/toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      const canvas = this
      setTimeout(function() {
        const binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
            len = binStr.length,
            arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    }
  })
}

if (!Array.prototype.flat) {
	Object.defineProperty(Array.prototype, 'flat', {
		configurable: true,
		value: function flat () {
			const depth = isNaN(arguments[0]) ? 1 : Number(arguments[0])

			return depth ? Array.prototype.reduce.call(this, function (acc, cur) {
				if (Array.isArray(cur)) {
					acc.push.apply(acc, flat.call(cur, depth - 1))
				} else {
					acc.push(cur)
				}

				return acc
			}, []) : Array.prototype.slice.call(this)
		},
		writable: true
	})
}
