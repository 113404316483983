import { push, wait, when } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { panelsOnPop } from '../../app/actions'
import { showSnackbar } from '../../app/factories'
import { postersRoute } from '@/utils/routerHelpers'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'
import { updateLSProps } from '../../../utils/LocalStorageUtil'

export default [
  push(state`posts.complaints`, props`postId`),
  // ({ store, get, props }) => {
  //   let myLSProps = get(state`auth.myLSProps`)
  //   if (!myLSProps.bannedPosts){
  //     myLSProps.bannedPosts = {}
  //   }
  //   myLSProps.bannedPosts[props.postId] = true
  //
  //   store.set('auth.myLSProps', myLSProps)
  //
  //   updateLSProps(get(state`auth.uid`), myLSProps)
  // },
  panelsOnPop,
  wait(250),
  when(state`app.route`, route => route === 'post'), {
    true: [
      () => ({ url: postersRoute }),
      prepareAndRouteTo,
    ],
    false: []
  },
  //filterPosts,
  showSnackbar(state`intl.content.claim_success`, 3000, 'success'),
]
