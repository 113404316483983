import { when, set } from 'cerebral/factories'
import { state } from 'cerebral'
import { roomRoute } from '@/utils/routerHelpers'
import prepareAndRouteTo from './prepareAndRouteTo'
import { getRoomOwnerUid } from '../../../utils/navigation/navigationUtil'

export default [
  when(state`auth.isLoggedIn`), {
    true: [
      set(state`chat.chatOpen`, false),
      ({ props }) => ({ url: roomRoute, urlValue: getRoomOwnerUid(props.roomId) }),
      prepareAndRouteTo
    ],
    false: []
  }
]
