import { state } from 'cerebral'
import controller from '@/controller'
import { createWsConnection, Ws } from '@/utils'
import { wasConnection } from '.'
import { goReload, NEED_RELOAD } from '../../../utils/reloadUtil'
import { getReconnectCount, getWSSReconnectTimeout } from '../../../utils/api/timeoutUtil'
import { cdnWSSToggle } from '../../../utils/urlUtils'
import { sendPercentAmplitudeEvent } from '../../../utils/GAEvent'

let isReconnecting = false
export const onclose = ({ props, store, get, path }) => {
  console.log('ONCLOSE WSS')
  if (NEED_RELOAD){
    console.log('@@ ONCLOSE WS')
    location.reload()
    return path.connectionLostPanelIsOpen()
  }

  if (isReconnecting) {
    return path.connectionLostPanelIsOpen()
  }
  const { wasClean, code, reason } = props
  if (wasClean) {
    console.log('@@@ ws connnect closed clean.')
  } else {
    console.warn('@@@ ws connect closed unexpected, wasConnection:', wasConnection())
    if (!wasConnection()) {
      const serverNode = document.getElementById('server-render')
      if (serverNode)serverNode.style.display = 'block'
    }
    const appNode = document.getElementById('app')
    if (appNode)appNode.style.display = 'block'
  }
  store.set('socket.isOpen', false)
  store.set('socket.isConnected', false)
  console.warn(`Code: ${code}. Reason: ${reason}`)

  const panels = get(state`app.panels`)
  if (panels[panels.length - 1] && panels[panels.length - 1].type === 'connectionLostPanel') {
    return path.connectionLostPanelIsOpen()
  }
  if (panels[panels.length - 1] && panels[panels.length - 1].type === 'loggedFromAnotherPlace') {
    return path.loggedFromAnotherPlacePanelIsOpen()
  }
  const connectToWs = () => {
    isReconnecting = false
    const onopen = controller.getSequence('socket.onopen')
    const onmessage = controller.getSequence('socket.onmessage')
    const onclose = controller.getSequence('socket.onclose')
    const onerror = controller.getSequence('socket.onerror')
    console.log('connectToWs connection')
    createWsConnection({ onopen, onmessage, onclose, onerror })
    const reconnectOnTimeout = () => setTimeout(() => {
      sendPercentAmplitudeEvent('wss_reconnect',{ reconnectCount: getReconnectCount() })
      const ws = Ws()
      isReconnecting = false
      if (ws.readyState !== 1) {
        console.log('reconnectOnTimeout connection')
        createWsConnection({ onopen, onmessage, onclose, onerror })
        isReconnecting = true
        cdnWSSToggle()
        reconnectOnTimeout()
      }
    }, getWSSReconnectTimeout())
    isReconnecting = true
    cdnWSSToggle()
    reconnectOnTimeout()
  }
  setTimeout(connectToWs, getWSSReconnectTimeout())
  isReconnecting = true
  return path.needToCreateConnectionLostPanel()
}
