import { getCoreUrl } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { getState, setStoreWrapper } from '../StoreManager'
import { sendAmplitudeEvent } from '../GAEvent'

export function dislikePost(postId) {
  setStoreWrapper('scrollNext', postId)
  sendAction(postId, true)

  sendAmplitudeEvent('dislike', {})
}

export function cancelDislike(postId) {
  sendAction(postId, false)
}

export function isDislikedPost(postId){
  const disliked = getState('dislikedPosts') || {}
  return disliked[postId]
}

async function sendAction(postId, dislike) {
  const disliked = getState('dislikedPosts') || {}
  if (dislike){
    disliked[postId] = postId
  } else{
    delete disliked[postId]
  }

  setStoreWrapper('dislikedPosts', disliked)

  const response = await fetch(getCoreUrl('/api/v1/dislikes/' + postId), {
    method: dislike ? 'POST' : 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    }
  }).catch(e => {
    //прогресс индикатор
    this.setState({ loadingMode: false })
    console.warn('catch error', e)
  })

  if (response && response.ok) {
    const respObj = await response.json()
    console.log('rob')
  }
}
