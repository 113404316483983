import getPaidPhoto from './getPaidPhoto'
import showPost from './showPost'
import watchVideo from './watchVideo'
import postComplaintId from './postComplaintId'
import postComplaintUid from './postComplaintUid'
import postComplaintDelete from './postComplaintDelete'
import getPost from './getPost'
import postLike from './postLike'
import hideVideo from './hideVideo'
import hideCoverSelection from './hideCoverSelection'
import freePhotoView from './freePhotoView'
import setFilter, { setDefaultFilter } from './setFilter'
import selectTag from './selectTag'
import { 
  getTopPosts, 
  getMorePosts, 
  getMoreUserPosts, 
  //getFixedPosts,
  getSimilarPosts, 
  getUserPosts, 
  getUserSubsPosts 
} from './getPosts'
import resetPosts from './resetPosts'
import selectHDQuality from './selectHDQuality'
import postLoadCheckNew from './postLoadCheckNew'
import postsWatched from './postsWatched'
import showLastPost from './showLastPost'
import onPostClick from './onPostClick'
import addPostModerationStatus from './addPostModerationStatus'
import onFixedPost from './onFixedPost'
import { saveTopUsers } from '../../../utils/topBar/topBarFillUtil'

export default {
  setFilter,
  setDefaultFilter,
  selectTag,
  getPaidPhoto,
  showPost,
  watchVideo,
  hideVideo,
  postsWatched,
  showLastPost,
  onPostClick,
  hideCoverSelection,
  freePhotoView,
  postComplaintId,
  postComplaintUid,
  postComplaintDelete,
  getPost,
  postLike,
  getTopPosts,
  getMorePosts,
  getUserSubsPosts,
  getMoreUserPosts,
  saveTopUsers,
  getSimilarPosts,
  getUserPosts,
  resetPosts,
  selectHDQuality,
  addPostModerationStatus,
  onFixedPost,
  postLoadCheckNew
}
