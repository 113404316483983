import is from 'is_js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { isTWA } from '@/utils/twa'
import { state } from 'cerebral'

const fpPromise = FingerprintJS.load()

export const generateFingerprint = async ({ get }) => {
  const fp = await fpPromise
  const result = await fp.get()
  
  let randomPart = ''
  if (is.iphone() || is.ipad()) {
    randomPart = '_' + Math.ceil(Math.random() * 999)
  }

  if (isTWA()) {
    const udid = get(state`app.udid`)
    if (udid) {
      return { fingerprint: udid }
    }
  }

  return { fingerprint: result.visitorId + randomPart }
}

