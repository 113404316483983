import controller from '@/controller'
import { MS_PWA } from './api/PWAUtil'
import { getState, setStoreWrapper } from './StoreManager'
import { loadMyLSProps } from './LocalStorageUtil'

export const isTWA = () => {
  const appId = getAppId()
  if (appId) {
    return true
  }
  
  if (document.referrer.includes('android-app://')) {
    return true
  }

  const url = new URL(location.href)
  if (!url || !url.searchParams) {
    return false
  }
  const getParam = url.searchParams.get('appid')// || url.searchParams.get('mobile')
  if (getParam) {
    return true
  }

  return false
}

export const getAppId = () => {
  const cashedAppId = getState('app.appId')
  if (cashedAppId){
    return cashedAppId
  }
  if (document.referrer.includes('app-info://platform/microsoft-store') || ((Date.now() - new Date(loadMyLSProps(MS_PWA))) < 5000)){
    setStoreWrapper('app.appId', MS_PWA)
    return MS_PWA
  }
  const url = new URL(location.href)
  const getParam = url.searchParams ? url.searchParams.get('appid') : null
  return getParam
}

export function getGen() {
  const url = new URL(location.href)
  const getParam = url.searchParams ? url.searchParams.get('gen') : null

  return parseInt(controller.getState('wrapperGen') || getParam  || 0)
}

export function isMSPWA(){
  return getAppId() === MS_PWA
}
