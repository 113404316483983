import signals from './signals'

export default {
  state: {
    content: {
      reconnect: 'Trying to reconnect.',
      free_girls: 'Girls LIVE. No payment. No registration.',
      chat_go: 'Go to video chat'
    },
    country: '',
    countriesList: null,
    crossPromo: null,
    domainsConfig: null,
    marketingMediums: null,
    tagsData: {},
    bannedCountries: {},//пример: {ru: true, fr: true}
    lang: null,
    direction: 'ltr',
    settings: null,
    autoTranslation: true,
    availableLocales: {
      en: { name: 'English' },
      ru: { name: 'Русский' },
    },
    trustedDomains: [],
    spam: [],
    postKinds:{
      post: {
        kindId: 'post',
        categoryIds: []
      }
    },
    usersTop: [],
  },
  sequences: signals
}
