import { set, when } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { send } from '../../socket/actions'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import openShop from '../../app/signals/openShop'
import showPost from './showPost'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { postNeedSubscription } from '@/utils/subscriptionUtil'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

export default [
  () => ({ hideAll: true }),
  panelsOnPop,
  when(state`auth.coins`, props`cost`, (myCoins, videoCost) => myCoins >= videoCost), {
    true: [
      ({ store, props }) => store.set('posts.selectedVideoUrl', props.isHDQualitySelected && props.hd ? props.hdUrl : props.original || null),
      showPost,
      set(state`upload.coverTime`, 0),
      set(state`upload.selectedFile`, null),
      set(state`upload.selectedFileUrl`, null),
      set(state`upload.selectedFilename`, null),
      set(state`posts.buyingPostId`, props`postId`),
      ({ props }) => ({
        params: {
          postId: props.postId,
        }, 
        method: 'startWatchRecord',
      }),
      when(props`postId`, postId => postNeedSubscription(postId)),{
        true: [],
        false: [
          send,
        ]
      },
      // ({ props, get }) => {
      //   const postData = get(state`posts.postsData.${props.postId}`) || {}
      //   const eventData = {
      //     sent_coins: props.cost || 0,
      //     age_rating: getUserAgeRating(postData.uid),
      //     content_rating: props.ageRating,
      //     counterparty_id: postData.uid || '-1',
      //     product_id: props.postId,
      //     content_category: 'video',
      //     delicate: props.ageRating > get(state`app.ageRating`),
      //     video_duration: props.videoLength
      //   }
      //
      //   if (postData.subscriptionType) {
      //     eventData.subscription_type = postData.subscriptionType.toLowerCase()
      //   }
      //   sendAmplitudeEvent('users_content', eventData)
      // },
    ],
    false: [
      ({ get }) => ({
        message: get(state`intl.content.no_coins_for_video`),
        reason: 'buy-paid-videopost'
      }),
      openShop
    ],
  },
]
