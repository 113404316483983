import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { state } from 'cerebral'
import { isMe } from '../../../utils'
import { getState } from '../../../utils/StoreManager'

export default [
  () => ({ route: 'room' }),
  dispatchUrlChangeToParent,
  handleRoute,
  ({ store, props, get, state }) => {
    const userId = props.routeArgs[0]
    const privateShow = (getState('posters.privates') || {})[userId]

    let chatRoomId = userId
    if (privateShow && !isMe(privateShow.viewer) && !isMe(privateShow.model)) {
      chatRoomId = 'specialPublic_' + chatRoomId
      // return path.modelInPrivateWithOtherUser()
    }

    store.set('app.roomId', chatRoomId)
    store.set('chat.chatRoomId', chatRoomId)
  },
]
