import Publisher from './Publisher'
import controller from '@/controller'
import { getSessionId } from '../cookies'
import config from '@/config'
import is from 'is_js'

const {
  wsStreamUrl,
  wsStreamUrlProxy,
  publish_h264_appName,
  publish_vp_appName,
} = config

export const getStreamInfo = () => {
  const codec = controller.getState('room.codec')
  return {
    applicationName: codec === 'H264' ? publish_h264_appName : publish_vp_appName,
    streamName: `${controller.getState('auth.uid')}_${getSessionId()}`,
    sessionId: '[empty]'
  }
}

export const getWowzaPublisher = options => new Publisher((is.safari('>=15') || is.ios('>=15')) ? wsStreamUrlProxy : wsStreamUrl, options)
