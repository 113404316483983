import controller from '@/controller'
import config from '@/config'
import { getSessionId } from '../../../utils/cookies'
const { ftCoreUrl } = config

export default [
  () => {
    fetch(`${ftCoreUrl}/api/v1/users/daily_coins`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sid: getSessionId()
      })
    }).catch(e => console.warn('catch error', e))
    const panelsOnPop = controller.getSequence('app.panelsOnPop')
    panelsOnPop()
  },
]
