import { wait, set } from 'cerebral/factories'
import { state, props, sequences } from 'cerebral'
import { getCodec, calculateAngle } from '../actions'
import { send } from '../../socket/actions'
import { getParentDomain } from '@/utils/urlUtils'
import { getSessionId } from '@/utils'
import is from 'is_js'

import config from '@/config'
import { isActAllowed } from '../../../utils/themeUtils'
import { CHAT_PERMISSION, LEVEL_VIP } from '../../../utils/subscriptionUtil'
import { callSequence, getState } from '../../../utils/StoreManager'
import { getPrivateThemes, getUserPrivateThemes } from '../../../utils/directCallThemeUtil'
import { getMyUid } from '../../../utils/my'
const { ftCoreUrl } = config

const createMetadata = () => {
  let metadata = getState('auth.storage.metadata') || {}
  //если не задал форс фри чат, и стоит дефолт, то поставим VIP чат только
  // if (!getState('auth.subscriptionSettings.CHAT_MESSAGE')){
  //   callSequence('app.setSubPermission',{ id: CHAT_PERMISSION, value: LEVEL_VIP })
  //   metadata.useChatDefault = true
  // }
  if (metadata.needRotate && !is.ios()){
    delete metadata.needRotate
  }

  if (is.ios() && !metadata.needRotate){
    metadata.needRotate = true
  }

  const result = {
    ...metadata,
    needSub: getState('auth.subscriptionSettings.LIVE'),
    domain: getParentDomain(),
    theme: {
      id: 'reality_show',
      activities:[],
      privateActivities:[],
      privateShowThemes: getMyPrivateThemes()
    },
    ...getState('room.metadata'),
  }
  delete result.privateShow
  return result
}

export const saveMetadata = async () => fetch(`${ftCoreUrl}/api/v1/broadcasts/metadata`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ 
    sid: getSessionId(),
    metadata: createMetadata()
  })
})

export function getMyPrivateThemes(){
  const broadcastThemes = getPrivateThemes()
  const bannedPrivates = getState('room.bannedPrivates')
  const result = []

  for (const themeId in broadcastThemes) {
    if (bannedPrivates[themeId]) {
      continue
    }

    result.push(themeId)
  }
  return result
}

// const getActs = (status) => {
//   const actsObj = getState('room.selectedActs')
//   return Object.keys(actsObj).filter(actId => isActAllowed(actId) && actsObj[actId] === status)
// }


export default [
  wait(50),
  getCodec,
  calculateAngle,
  ({ get }) => ({
    method: 'createBroadcast',
    params: {
      roomId: get(state`app.roomId`),
      metadata: createMetadata({ get })
    }
  }),
  send,
  saveMetadata,
  set(state`auth.storage.metadata`, props`params.metadata`)
]
