import SDPTransfrom from 'sdp-transform/lib'

const videoChoice = '42e01f'
const audioChoice = 'opus'
let videoIndex = -1
let audioIndex = -1
let SDPOutput = {}

export function enhanceSDP(sdpStr, setCodec) {
  const enhanceData = {
    audioBitrate: 64,
		videoBitrate: 720,
		videoFrameRate: 25
  }
  videoIndex = -1
  audioIndex = -1
  SDPOutput = {}

  let sdpLines = sdpStr.split(/\r\n/)
	let sdpSection = 'header'
	let hitMID = false
	let sdpStrRet = ''

	//console.log("Original SDP: "+sdpStr);

	// Firefox provides a reasonable SDP, Chrome is just odd
	// so we have to doing a little mundging to make it all work
	if (!sdpStr.includes('THIS_IS_SDPARTA') || videoChoice.includes('VP9'))
	{
		for (const sdpIndex in sdpLines)
		{
			const sdpLine = sdpLines[sdpIndex]

			if (sdpLine.length <= 0)
				continue

			const doneCheck = checkLine(sdpLine)
			if (!doneCheck)
				continue

			sdpStrRet += sdpLine
			sdpStrRet += '\r\n'
		}
		sdpStrRet =  addAudio(sdpStrRet, deliverCheckLine(audioChoice,'audio'))
		sdpStrRet =  addVideo(sdpStrRet, deliverCheckLine(videoChoice,'video'))
		sdpStr = sdpStrRet
		sdpLines = sdpStr.split(/\r\n/)
		sdpStrRet = ''
	}

	for (const sdpIndex in sdpLines)
	{
		const sdpLine = sdpLines[sdpIndex]

		if (sdpLine.length <= 0)
			continue

		if (sdpLine.indexOf('m=audio') === 0 && audioIndex !== -1)
		{
			const audioMLines = sdpLine.split(' ')
			sdpStrRet += audioMLines[0] + ' ' + audioMLines[1] + ' ' + audioMLines[2] + ' ' + audioIndex + '\r\n'
			continue
		}

		if (sdpLine.indexOf('m=video') === 0 && videoIndex !== -1)
		{
			const videoMLines = sdpLine.split(' ')
			sdpStrRet += videoMLines[0] + ' ' + videoMLines[1] + ' ' + videoMLines[2] + ' ' + videoIndex + '\r\n'
			continue
		}

		sdpStrRet += sdpLine

		if (sdpLine.indexOf('m=audio') === 0)
		{
			sdpSection = 'audio'
			hitMID = false
		}
		else if (sdpLine.indexOf('m=video') === 0)
		{
			sdpSection = 'video'
			hitMID = false
		}
		else if (sdpLine.indexOf('a=rtpmap') === 0)
		{
			sdpSection = 'bandwidth'
			hitMID = false
		}

		if (sdpLine.indexOf('a=mid:') === 0 || sdpLine.indexOf('a=rtpmap') === 0)
		{
			if (!hitMID)
			{
				if ('audio'.localeCompare(sdpSection) === 0)
				{
					if (enhanceData.audioBitrate !== undefined)
					{
						sdpStrRet += '\r\nb=CT:' + (enhanceData.audioBitrate)
						sdpStrRet += '\r\nb=AS:' + (enhanceData.audioBitrate)
					}
					hitMID = true
				}
				else if ('video'.localeCompare(sdpSection) === 0)
				{
					if (enhanceData.videoBitrate !== undefined)
					{
						sdpStrRet += '\r\nb=CT:' + (enhanceData.videoBitrate)
						sdpStrRet += '\r\nb=AS:' + (enhanceData.videoBitrate)
						if (enhanceData.videoFrameRate !== undefined)
            {
              sdpStrRet += '\r\na=framerate:' + enhanceData.videoFrameRate
            }
					}
					hitMID = true
				}
				else if ('bandwidth'.localeCompare(sdpSection) === 0)
				{
					const rtpmapID = getrtpMapID(sdpLine)
					if (rtpmapID !== null)
					{
						const match = rtpmapID[2].toLowerCase()
						if (('vp9'.localeCompare(match) === 0) ||  ('vp8'.localeCompare(match) === 0) || ('h264'.localeCompare(match) === 0) ||
							('red'.localeCompare(match) === 0) || ('ulpfec'.localeCompare(match) === 0) || ('rtx'.localeCompare(match) === 0))
						{
							if (enhanceData.videoBitrate !== undefined)
						  {
								sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (enhanceData.videoBitrate) + ';x-google-max-bitrate=' + (enhanceData.videoBitrate)
							}
						}

						if (('opus'.localeCompare(match) === 0) ||  ('isac'.localeCompare(match) === 0) || ('g722'.localeCompare(match) === 0) || ('pcmu'.localeCompare(match) === 0) ||
								('pcma'.localeCompare(match) === 0) || ('cn'.localeCompare(match) === 0))
						{
							if (enhanceData.audioBitrate !== undefined)
							{
								sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (enhanceData.audioBitrate) + ';x-google-max-bitrate=' + (enhanceData.audioBitrate)
							}
						}
					}
				}
			}
		}
		sdpStrRet += '\r\n'
  }
  setCodec({ codec: 'H264' })
	return sdpStrRet
}

function addAudio(sdpStr, audioLine)
{
	const sdpLines = sdpStr.split(/\r\n/)
	//const sdpSection = 'header'
	//const hitMID = false
	let sdpStrRet = ''
	let done = false

	for (const sdpIndex in sdpLines)
	{
		const sdpLine = sdpLines[sdpIndex]

		if (sdpLine.length <= 0)
			continue

		sdpStrRet += sdpLine
		sdpStrRet += '\r\n'

		if ('a=rtcp-mux'.localeCompare(sdpLine) === 0 && done === false)
		{
			sdpStrRet += audioLine
			done = true
		}
	}
	return sdpStrRet
}

function addVideo(sdpStr, videoLine)
{
	const sdpLines = sdpStr.split(/\r\n/)
	//const sdpSection = 'header'
	//const hitMID = false
	let sdpStrRet = ''
	let done = false

	let rtcpSize = false
	let rtcpMux = false

	for (const sdpIndex in sdpLines)
	{
		const sdpLine = sdpLines[sdpIndex]

		if (sdpLine.length <= 0)
			continue

		if (sdpLine.includes('a=rtcp-rsize'))
		{
			rtcpSize = true
		}

		if (sdpLine.includes('a=rtcp-mux'))
		{
			rtcpMux = true
		}
	}

	for (const sdpIndex in sdpLines)
	{
		const sdpLine = sdpLines[sdpIndex]

		sdpStrRet += sdpLine
		sdpStrRet += '\r\n'

		if (('a=rtcp-rsize'.localeCompare(sdpLine) === 0) && done === false && rtcpSize === true)
		{
			sdpStrRet += videoLine
			done = true
		}

		if ('a=rtcp-mux'.localeCompare(sdpLine) === 0 && done === true && rtcpSize === false)
		{
			sdpStrRet += videoLine
			done = true
		}

		if ('a=rtcp-mux'.localeCompare(sdpLine) === 0 && done === false && rtcpSize === false)
		{
			done = true
		}
	}
	return sdpStrRet
}

function deliverCheckLine(profile,type)
{
	let outputString = ''
	for (const line in SDPOutput)
	{
		const lineInUse = SDPOutput[line]
		outputString += line
		if (lineInUse.includes(profile))
		{
			if (profile.includes('VP9') || profile.includes('VP8'))
			{
				let output = ''
				const outputs = lineInUse.split(/\r\n/)
				for (const position in outputs)
				{
					const transport = outputs[position]
					if (transport.indexOf('transport-cc') !== -1 || transport.indexOf('goog-remb') !== -1 || transport.indexOf('nack') !== -1)
					{
						continue
					}
					output += transport
					output += '\r\n'
				}

				if (type.includes('audio'))
				{
					audioIndex = line
				}

				if (type.includes('video'))
				{
					videoIndex = line
				}

				return output
			}
			if (type.includes('audio'))
			{
				audioIndex = line
			}

			if (type.includes('video'))
			{
				videoIndex = line
			}
			return lineInUse
		}
	}
	return outputString
}

function checkLine(line)
{
	if (line.startsWith('a=rtpmap') || line.startsWith('a=rtcp-fb') || line.startsWith('a=fmtp'))
	{
		const res = line.split(':')

		if (res.length > 1)
		{
			const number = res[1].split(' ')
			if (!isNaN(number[0]))
			{
				if (!number[1].startsWith('http') && !number[1].startsWith('ur'))
				{
					let currentString = SDPOutput[number[0]]
					if (!currentString)
					{
						currentString = ''
					}
					currentString += line + '\r\n'
					SDPOutput[number[0]] = currentString
					return false
				}
			}
		}
	}

	return true
}

function getrtpMapID(line)
{
	const findid = new RegExp('a=rtpmap:(\\d+) (\\w+)/(\\d+)')
	const found = line.match(findid)
	return (found && found.length >= 3) ? found : null
}

export const makeAndroidSDP = (description, setCodec) => {
  // const h264 = description.sdp.match(/H264/igm)
  // const vp8 = description.sdp.match(/VP8/igm)
  // const vp9 = description.sdp.match(/VP9/igm)
  // console.log(`found in sdp: H264 - ${h264 ? h264.length : 0}, VP8 - ${vp8 ? vp8.length : 0}, VP9 - ${vp9 ? vp9.length : 0}`)      

  const sdp = SDPTransfrom.parse(description.sdp)
  if (sdp && sdp.media && sdp.media[1]) {
    const codecs = sdp.media[1].rtp.reduce((result, { codec, payload }) => {
      result.ALL.push(codec + '_' + payload)
      if (codec === 'H264') result.H264.push(payload)
      if (codec === 'VP9') result.VP9.push(payload)
      if (codec === 'VP8') result.VP8.push(payload)
      return result
    }, { H264: [], VP9: [], VP8: [], ALL: [] })
    const preferCodec = codecs.H264.length ? 'H264' : 
                        codecs.VP9.length  ? 'VP9' : 'VP8'
    setCodec({ codec: preferCodec })
    
    /*if (preferCodec !== 'H264') {
      console.log('@@@ all codecs:', codecs.ALL)
      throw new Error('no H264')
    }*/
    const fmtp = sdp.media[1].fmtp.reduce((reducer, { payload, config }) => {
      if (config.indexOf('profile-level-id') !== -1) {
        // const start = config.indexOf('profile-level-id=') + 17
        // const end = start + 6
        reducer[payload] = config
      }
      return reducer
    }, {})
    
    const prefProfile = '42e01f'
    // 'packetization-mode=1'
    codecs[preferCodec].sort((a, b) => {
      if (fmtp[a] === '420029') return -1
      if (fmtp[b] === '420029') return 1

      if (fmtp[a].indexOf(prefProfile) !== -1 && (!fmtp[b] || fmtp[b].indexOf(prefProfile) === -1)) return 1
      if ((!fmtp[a] || fmtp[a].indexOf(prefProfile) === -1) && fmtp[b].indexOf(prefProfile) !== -1) return -1
      if (fmtp[a].indexOf(prefProfile) !== -1 && fmtp[b].indexOf(prefProfile) !== -1) {
        if (fmtp[a].indexOf('packetization-mode=1') !== -1) return 1
        if (fmtp[b].indexOf('packetization-mode=1') !== -1) return -1
      }
      if ((fmtp[a] !== '42001f' && fmtp[b] !== '42001f')) return 0
    })
    
    console.log('video payloads before', sdp.media[1].payloads)
    let payloadLine = sdp.media[1].payloads
    codecs[preferCodec].map(payload => {
      payloadLine = payloadLine.replace(`${payload} `, '')
      payloadLine = payloadLine.replace(payload, '')
      payloadLine = `${payload} ${payloadLine}`
    })
    
    for (let i = 0; i < sdp.media[1].fmtp.length; i++) {
      sdp.media[1].fmtp[i].config += ';x-google-min-bitrate=800;x-google-max-bitrate=800'
    }
    console.log('video payloads after', payloadLine)
    console.log('fmtp', sdp.media[1].fmtp)
    console.log('sdp media', sdp.media[1])
    sdp.media[1].payloads = payloadLine
    description.sdp = SDPTransfrom.write(sdp)
  }
  return description
}
