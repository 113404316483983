import { state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import managePanels from '../chains/managePanels'
import clearRoomVariables from '../../room/signals/clearRoomVariables'
import sendGoInRoom from '../../room/signals/sendGoInRoom'
import { getLangCode } from '../../intl/actions'
import { postersRoute } from '@/utils/routerHelpers'
import handleLocaleFromUrl from '../../intl/signals/handleLocaleFromUrl'

export const handleRoute = [
  handleLocaleFromUrl,
  ({ store, get }) => {
    let url = location.pathname.indexOf('/new-chat') !== -1
    ? location.pathname.substring(9)
    : location.pathname
    url = url.split('/')
    const [first, lang, ...other] = url
    url = [first, ...other].join('/')
    const routesHistory = get(state`app.routesHistory`)
    if (routesHistory[routesHistory.length - 1] !== url) {
      store.push('app.routesHistory', url)
    }
  },
  ({ store, get, props }) => {
    if (props.route) {
      store.set('app.lastRoute', get(state`app.route`))
      store.set('app.route', props.route)
    }
    if (props.panelRoute) {
      store.set('app.panelRoute', props.panelRoute)
      store.set('app.url', props.url)
      const currentRoute = get(state`app.route`)
      if (currentRoute === '/') {
        store.set('app.route', 'home')
        store.set('room.callingTo', null)
      }
    }
  },
  managePanels,
  when(
    state`app.route`,
    state`app.lastRoute`,
    (route, lastRoute) => route !== 'room' && lastRoute === 'room'
  ), {
    false: [],
    true: [
      clearRoomVariables,
      sendGoInRoom,
      set(state`room.companionUid`, null)
    ]
  }
]

export default [
  getLangCode,
  ({ get, router }) => router.redirect(`/${get(state`intl.lang`)}${postersRoute}${get(state`app.urlGetVariables`)}`)
]
