import { state } from 'cerebral'
import { set, when } from 'cerebral/factories'
import { panelsOnPop } from '../../app/actions'
import { loginPanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import { isModeration } from '../../../utils/moderationUtil'
//import { isModeration } from '../../../components/Screens'

export const hideLoginPanels = [
  ({ get, path }) => {
    const isAdmin = get(state`auth.admin`) || get(state`auth.superAdmin`)
    
    if (!isAdmin && isModeration) {
      return path.needAdmin()
    }
    return path.hidePanels()
  }, {
    needAdmin: [
      () => loginPanel,
      createPanel,
    ],
    hidePanels: [
      when(state`app.panels`, panels => 
      panels.length && (
      panels[panels.length - 1].type === 'login' ||
      panels[panels.length - 1].type === 'loggedFromAnotherPlace')), 
      {
        true: [
          panelsOnPop,
        ],
        false: [

        ]
      },
      set(state`auth.loginForm`, { username: '', password: '' }),
    ]
  }
]
