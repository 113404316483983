import { state, props } from 'cerebral'
import controller from '@/controller'
import { isMe } from './checks'
import { getLocaleValue } from './localeUil'
import { getMyProfile, getMyUid } from './my'
import { getUser } from './userLoader'

export const PROFILE_TAG = 1067952
export const STREAM_THEME_TAG = 1131021
export const MALE = 38
export const IS_REAL = 35
export const INTERESTING = 36
export const HD = 33
export const FEMALE = 83341
export const VIDEOCHAT = 1085661
export const HETERO = 1476374

export const SKYPE_TAG = 1348801
export const FB_TAG = 1348799
export const SNAPCHAT_TAG = 1348800
export const TIK_TOK_TAG = 1360605
export const INSTAGRAM_TAG = 1376652
export const EMAIL_TAG = 1348798
export const FACE_DETECTED = 1489662
export const NO_FACE = 1489664

export const GENDER_MISMATCH_TAG = 1545618

export const DESCRIPTION_REMOVED = 1493889

export const FIXED_POST =1063614

export const GAMING_TAG = 1072431
export const ART_TAG = 39

export const showPostInBlog = postData => {
  if (!postData) { return false }

  if (postData.postKind === 'postTheme') {
    return false
  }
  if (postData.tags) {
    if (postData.tags.indexOf(STREAM_THEME_TAG) !== -1) {
      return false
    }
  }

  return true
}

//если забаненно возвращает true
export const postComplaintCheck = postId => {
  const lsp = controller.getState('auth.myLSProps')
  let banned = lsp && lsp.bannedPosts && lsp.bannedPosts[postId]
  return banned
}

//получение uid автора поста
export const getPostAuthor = (postId) => {
  if(!postId){
    return undefined
  }
  const postData = getPostData(postId)
  if (!postData){
    return undefined
  }
  return postData.uid
}

export function isMyPost(postId) {
  return isMe(getPostAuthor(postId))
}

//получение данных поста
export const getPostData = (postId) => {
  return controller.getState(`posts.postsData.${postId}`)
}

export const isProfile = (postId) => {
  const postData = getPostData(postId)
  if (postData && postData.tags && postData.tags.indexOf(PROFILE_TAG) !== -1){
    return true
  }

  return false
}

export function getPostDescription(postId, original) {
  const postData = getPostData(postId)
  if (!postData){
    return null
  }
  if (postData.tags && postData.tags.includes(DESCRIPTION_REMOVED) && !isMyPost(postId)){
    return null
  }
  if (isMyPost(postId) || original){
    return postData.markdownDescOriginal
  }

  return postData.markdownDesc || postData.markdownDescOriginal
}

export function getPostModerationStatus(postId) {
  if (!postId) return null

  const post = getPostData(postId)
  if (!post) return null

  const isVideo = post.postType === 1
  switch (post.statusFromDb) {
    case 0:
      return null//intl.ui_up115
    case 5:
      return isVideo ? getLocaleValue('bad_video') + ' \u00A0 ' : getLocaleValue('bad_photo')
    case 6:
      return isVideo ? getLocaleValue('someone_elses_video') + ' \u00A0 ' : getLocaleValue('not_your_photo')
    case 7:
      return getLocaleValue('bad_descr')
    case 8:
      return getLocaleValue('duplicate')
    case 101:
      return getLocaleValue('ui_up_status6')
  }

  return null
}

export function getMyProfileModerationStatus() {
  const user = getUser(getMyUid())
  if (user && user.storage && user.storage.profilePost && user.storage.profilePost.postId && !getMyProfile()){
    return getPostModerationStatus(user.storage.profilePost.postId)
  }
  return null
}
