import { state } from 'cerebral'

export const requestPaidOparation = ({ store, get, path, controller, props }) => {
  const { cost, reason } = props
  let { message } = props
  const coins = get(state`auth.coins`)
  if (coins >= cost) {
    return path.enoughCoins()
  } else {
    if (!message) message = get(state`intl.content.need_more_coins`)
    message = message.replace('{0}', cost - coins)
    controller.getSequence('app.openShop')({ message, reason })
    return path.shopOpened()
  }
}
