import { state } from 'cerebral'

export const loadCountries = async ({ store, get }) => {
  store.set('intl.countriesList', 'loading')
  
  let result
  try {
    const response = await fetch(`https://7b1d1ed9-a3f5-4e03-ae8d-badd1bc8f1a6.selcdn.net/countries/${get(state`intl.lang`)}.json`)
    if (!response.ok) { 
      store.set('intl.countriesList', null)
      return 
    }

    result = await response.json()
  } catch (e) {
    store.set('intl.countriesList', null)
    return
  }
  
  //ждем пока панель появится, а то стран много и анимация будет тормозить
  setTimeout(() => {
    const lowCase = {}
    Object.keys(result).forEach(key => {
      lowCase[key.toLowerCase()] = result[key]
    })
    store.set('intl.countriesList', lowCase)
  }, 400)
}
