import signals from './signals'

export default {
  state: {
    categoriesTags: {},
    selectedFile: null,
    selectedFileUrl: null,
    selectedFilename: null,
    selectedPhotoOrientation: -1,
    mainFileId: null,
    extraFiles: [],
    selectedTags: [],
    subscriptionType: null,
    title: null,
    description: null,
    moderPostStatus: 0,
    uploadProgress: -1,
    postKind: null,
    coverTime: 0,
    moderPanel: false,
    photoUrl: null,
    
    avaX1: 0,
    avaX2: 0,
    avaY1: 0,
    avaY2: 0,
    avaW: 0,
    avaH: 0
  },
  sequences: signals
}
