import { getCoreUrl } from './apiURLUtil'
import { getSessionId } from '../cookies'
import { sendAmplitudeEvent } from '../GAEvent'

export async function POSTRequest(url, params, callback, errorCB){
  const sid = getSessionId()
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': sid
    },
    body: JSON.stringify(params)
  }).catch(e => {
    if (errorCB){
      errorCB()
    }
  })

  if (response && response.ok) {
    const result = await response.json()
    if (callback){
      callback(result)
    }
    return
  }
  if (errorCB){
    errorCB()
  }
}

export async function GETRequest(url, callback, errorCB, noSID){
  const data = {
    method: 'GET'
  }
  if (!noSID){
    data.headers = {
      'Content-Type': 'application/json',
        'ft-sid': getSessionId()
    }
  }
  const response = await fetch(url,data).catch(e => {
    if (errorCB){
      errorCB()
    }
  })

  if (response && response.ok) {
    const result = await response.json()
    if (callback){
      callback(result)
    }
    return
  }
  if (errorCB){
    errorCB()
  }
}

