
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import { requestPaidOparation } from '../../shop/actions/requestPaidOparation'

export default [
  ({ store, get }) => ({
    cost: get(state`intl.settings.effects.moderator.cost`),
    reason: 'buy-moderator'
  }),
  requestPaidOparation, {
    enoughCoins: [
      ({ props }) => ({
        method: 'buyEffect',
        params: { cardType: props.cardType }
      }),
      send
    ],
    shopOpened: []
  }
]
