import { state } from 'cerebral'
import { set } from 'cerebral/factories'
import roomTransited from '../../app/signals/roomTransited'
import showInRoom from '../../posters/showInRoom'
import { postRoute } from '@/utils/routerHelpers'

export default [
  set(state`posts.selectedVideoUrl`, null),
  ({ props, path, get }) => {
    const br = get(state`posters.raw.${props.uid}`)
    if (showInRoom(br)) {
      return path.openRoom({ roomId: props.uid })
    }
    
    const posts = get(state`posts.userPosts.${props.uid}`)
    if (!posts || posts.length === 0) {
      return path.openRoom({ roomId: props.uid })
    }

    posts.sort((p1, p2) => {
      const post1 = get(state`posts.postsData.${p1}`) || {}
      const post2 = get(state`posts.postsData.${p2}`) || {}
      
      const creationDate1 = new Date(post1.creationDate || 0).getTime()
      const creationDate2 = new Date(post2.creationDate || 0).getTime()
      return creationDate2 - creationDate1
    })
    
    return path.openPost({ postId: posts[0] })
  }, {
    openRoom: roomTransited,
    openPost: [
      ({ router, props, get }) => {
        const lang = get(state`intl.lang`)
        router.goTo(`/${lang}${postRoute}/${props.postId}`)
      }
    ]
  }
]
