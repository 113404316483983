import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'

const { httpApiUrl } = config

export const changePasswordRequest = async ({ get, path }) => {
  const { password, oldPassword } = get(state`auth.changePasswordForm`)
  
  const fd = new FormData()
  fd.append('sid', getSessionId())
  fd.append('password', password)
  if (get(state`auth.isPasswordSet`)) {
    fd.append('old_password', oldPassword)
  }
  
  const response = await fetch(httpApiUrl + '/auth/change_password', {
    method: 'POST',
    body: fd
  })
  if (!response.ok) {
    path.requestError({ status: response.status })
  }
  const result = await response.json()
  if (result.result) return path.success()
  if (result.error) return path.error({ error: result.error })
}
