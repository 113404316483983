import { wait } from 'cerebral/factories'
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import panelsOnPop from '../../app/signals/panelsOnPop'
import routeToRoom from '../../app/signals/routeToRoom'
import { saveMetadata } from '../../room/signals/sendCreateBroadcast'

import config from '@/config'
import { getSessionId } from '@/utils'
import { setStoreWrapper } from '../../../utils/StoreManager'
const { ftCoreUrl } = config

export default [
  /*({ props }) => ({
    params: props,
    method: 'deletePost'
  }),
  send,*/

  ({ props }) => {
    const postId = props.postId
    fetch(`${ftCoreUrl}/api/v1/posts/${postId}`, {
      method: 'DELETE',
      body: new URLSearchParams({
        sid: getSessionId(),
      })
    }).catch(e => console.warn('catch error', e))
  },

  ({ props, store, get, path }) => {
    const postId = props.postId
    const postData = get(state`posts.postsData.${postId}`) 
    const authorUid = postData.uid
    const authorPosts = get(state`posts.userPosts.${authorUid}`) || []
    
    let photoDataIdx = authorPosts.indexOf(postId)
    if (photoDataIdx >= 0) {
      authorPosts.splice(photoDataIdx, 1)
      store.set(`posts.userPosts.${authorUid}`, authorPosts)
    }
     
    const allPosts = get(state`posts.topPosts`)
    photoDataIdx = allPosts.indexOf(postId)
    if (photoDataIdx >= 0) {
      allPosts.splice(photoDataIdx, 1)
      store.set('posts.topPosts', allPosts)
    }
    store.set(`posts.postsData.${postId}`, null)

    setTimeout(()=>{
      setStoreWrapper('updateDairy', authorUid)
    }, 1000)

    if (postId === get(state`auth.storage.metadata.postThemeId`)) {
      store.set('auth.storage.metadata.postThemeId', null)
      return path.saveMD()
    }
    return path.closePanel()
  }, {
    saveMD: [
      saveMetadata
    ],
    closePanel:[
      panelsOnPop,
      wait(350),
      ({ get }) => ({ roomId: get(state`auth.uid`) }),
      routeToRoom,
    ],
  },
]
