import { state } from 'cerebral'

export default ({ store, get, path }) => {
  const selectedVideoUrl = get(state`posts.selectedVideoUrl`)
  
  if (selectedVideoUrl) {
    store.set('posts.selectedVideoUrl', null)
    return path.deleted()
  }

  return path.fail()
}
