import config from '@/config'
import { getSessionId } from '@/utils'
const { dialogsUrl } = config

export default async (dialogId) => {
  const response = await fetch(`${dialogsUrl}/api/v1/dialogs/` + dialogId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
  })
  if (!response.ok) return
}

