import is from 'is_js'
import config from '@/config'
import controller from '@/controller'
import { inIframe } from './inIframe'
import { isLocalHost, isPartnerDomain } from './urlUtils'
import { getSessionId } from './cookies'
import { isTWA } from './twa'
import { sendAmplitudeEvent } from './GAEvent'
import { getMyUid } from './my'
import { getState, setStoreWrapper } from './StoreManager'
const { pushSettingsUrl } = config
// import OneSignal from 'react-onesignal'

let waitTimes = 0

export const initOneSignal = () => {
  console.log('OneSignal: Init. inIframe:', inIframe())
  if (is.ios() || isPartnerDomain() || isLocalHost()) {
    console.log('OneSignal no Init: ios or partner or local')
    return
  }
  const oneSignalId = controller.getState('intl.appSourceConfig.onesignalAppId')
  if (!oneSignalId) {
    if (waitTimes > 10) return
    console.log('OneSignal: wait for key')
    setTimeout(initOneSignal, 3000)
    waitTimes++
    return
  }
  console.log('OneSignal: key', oneSignalId)

  if (inIframe()) {
    return
  } else {
    OneSignal.push(() => {
      OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' }
      OneSignal.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js'
      OneSignal.SERVICE_WORKER_UPDATER_PATH = 'push/onesignal/OneSignalSDKUpdaterWorker.js'
      
      OneSignal.init({
        appId: oneSignalId
      }).then(() => {
        //изменился статус подписки
        OneSignal.on('subscriptionChange', isSubscribed => {
          sendAmplitudeEvent('push_change', {
            iframe: inIframe(),
            allowed: isSubscribed
          })

          console.log('OneSignal subscription: ', isSubscribed)
          setStoreWrapper('isPushEnabled', isSubscribed)
          if (isSubscribed) {
            OneSignal.push(() => {
              OneSignal.getUserId().then(userId => {
                console.log('!OneSignal apid: ', userId)
                if (userId) {
                  controller.getSequence('app.saveOneSignalUserId')({ userId })
                  uploadOneSignalId()
                }
              })
            })
            controller.getSequence('app.setNotificationsButtonMode')({ mode: 1 })
          } else {
            controller.getSequence('app.setNotificationsButtonMode')({ mode: 0 })
          }
        })

        controller.getSequence('app.setOneSignalInited')()
        getSubscriptionState()
      })
    })
  }
}

const getSubscriptionState = () => {
  console.log('getSubscriptionState')
  if (!getMyUid()){
    console.log('notLoginned')
    return
  }
  OneSignal.push(() => {
    console.log('fromPUSH')
    OneSignal.isPushNotificationsEnabled().then(isPushEnabled => {
      setStoreWrapper('isPushEnabled', isPushEnabled)
      controller.getSequence('app.setNotificationsButtonMode')({ mode: isPushEnabled ? 1 : 0 })
      console.log('OneSignal isPushEnabled:', isPushEnabled)
      if (isPushEnabled){
        OneSignal.push(() => {
          OneSignal.getUserId().then(userId => {
            console.log('!OneSignal apid: ', userId)
            if (userId) {
              controller.getSequence('app.saveOneSignalUserId')({ userId })
              uploadOneSignalId()
            }
          })
        })
      }
    })
  })

  OneSignal.push(function() {
    OneSignal.isPushNotificationsEnabled(function(isEnabled) {
      if (isEnabled)
        console.log("Push notifications are enabled!")
      else
        console.log("Push notifications are not enabled yet.")
    })
  })
}

export const uploadOneSignalId = () => {
  console.log('!! OneSignal uploadOneSignalId')
  try {
    const oneSignalId = controller.getState('intl.appSourceConfig.onesignalAppId')
    const userId = controller.getState('app.oneSignalUserId')
    if (!userId || userId === 'null' || !oneSignalId || oneSignalId === 'null') {
      console.log('OneSignal: no apid')
      return
    }

    if (!getSessionId()){
      console.log('NOT LOGINNED')
      return
    }

    if (!getState('isPushEnabled')){
      console.log('PUSH NOT ALLOWED')
      return
    }

    if (getMyUid()) {
      OneSignal.push(() => {
        console.log('extUID')
        OneSignal.setExternalUserId(getMyUid())
      })
    }

    const formData = new FormData()
    formData.append('app', 'vb')
    formData.append('apid', userId)
    formData.append('device_type', isTWA() ? 'mobile' : 'web')
    formData.append('app_id', controller.getState('app.appId'))
    formData.append('onesignal_id', oneSignalId)
    formData.append('sid', getSessionId())
    console.log('save save_apid_by_sid')
    const request = new XMLHttpRequest()
    request.open('POST', pushSettingsUrl + 'push/save_apid_by_sid')
    if (!isProdEnv) {
      request.setRequestHeader('Authorization', 'Basic ' + btoa('testvreale:eh7mh9fozl'),)
    }
    request.send(formData)
    console.log('save success')
  } catch (e) {
    console.warn(e)
  }
}

export const registerForPushNotifications = () => {
  controller.getSequence('app.setNotificationsButtonMode')({ mode: 1 })
  console.log('OneSignal registerForPushNotifications iframe:', inIframe())

  sendAmplitudeEvent('allow_push_click', {
    iframe: inIframe()
  })

  if (inIframe()) {
    window.parent.postMessage(
      JSON.stringify(
        { type: 'requestRegisterForPushNotifications', payload: '' }
      ),
      '*'
    )
  } else {
    OneSignal.push(() => {
      OneSignal.showNativePrompt()
    })
  }
}
