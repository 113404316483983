import { sendDialogMessage } from '../actions'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { panelsOnPop } from '../../app/actions'
import { state } from 'cerebral'
import dialogMediaUpload from '../../upload/actions/dialogMediaUpload'

export default [
  ({ props }) =>{
    const progress = getState('upload.uploadProgressFile') || {}
    progress[getState('upload.selectedFilename')] = 0
    setStoreWrapper('upload.uploadProgressFile', progress)
  },
  ({ props }) =>(
    {
      uid:props.uid,
      dialog_id:props.dialogId,
      cost:props.cost,
      mediaFileUpload:true,
      fileData: {
        cost:props.cost,
        mediaTypeVideo: getState('mediaTypeVideo'),
        selectedFile: getState('upload.selectedFile'),
        selectedFilename: getState('upload.selectedFilename'),
        selectedFileUrl: getState('upload.selectedFileUrl'),
        selectedPhotoOrientation: getState('upload.selectedPhotoOrientation')
    }
  }),
  panelsOnPop,
  sendDialogMessage,
  dialogMediaUpload
]
