import { isDesktopSkin, isMe } from './checks'
import { callSequence } from './StoreManager'
// import { POSTERS_SCROLL } from '../components/Screens/Posters'
// import { mainScrollId } from '../components/Screens'

let scrollPositions = {}

const mainScrollId = 'MainScroll'
const POSTERS_SCROLL = 'posters'

export function setScreenInPosition(key, withAnimate) {
  let position = 0
  if (!isMe(key)){
    position = scrollPositions[key] || 0
  }
  if (isMe(key) || key === POSTERS_SCROLL){
    callSequence('app.setAnimatedTopBar',{ withAnimate: withAnimate })
    callSequence('app.hideTopBar',{ value: position > 300 })
  }
  if (isDesktopSkin()){
    const element = document.getElementById(mainScrollId)
    if (element){
      element.scrollTop = position
    } else {
      window.scrollTo(0, position)
    }
  } else {
    window.scrollTo(0, position)
  }
}

export function rememberScrollPosition(key, value) {
  if (isMe(key)){
    return
  }
  scrollPositions[key] = value || getCurrentScrollPosition()
}

export function clearAllScrollPositions() {
  scrollPositions = {}
}

export function clearScrollPositions(key) {
  delete scrollPositions[key]
}

export function getPostersScrollPosition() {
  return scrollPositions[POSTERS_SCROLL] || 0
}

export function rememberPostersScrollPosition() {
  return rememberScrollPosition(POSTERS_SCROLL)
}

export function getCurrentScrollPosition() {
  if (isDesktopSkin()){
    const element = document.getElementById(mainScrollId)
    return element ? element.scrollTop : 0
  } else {
    return window.scrollY
  }
}
