import { wait } from 'cerebral/factories'
import { send } from '../../socket/actions'

export default [
  wait(1500),
  () => ({
    method: 'getLastCaller' 
  }),
  send
]
