import { sendAmplitudeEvent, sendPercentAmplitudeEvent } from '../GAEvent'
import { getState } from '../StoreManager'
import { GIF_TAG_ID } from '../../modules/intl/actions'
import { imAdmin } from '../adminUtil'
import { imInRoom, imOnHomeRoute } from '../appUtil'
import { getPostAuthor, isProfile } from '../postUtils'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude, isStreaming } from '../../modules/socket/actions'
import { getPostAgeRating, getUserAgeRating } from '../ageRating/ageRatingUtil'
import { getUserFeed } from '../../modules/posts/signals/getPosts'

function getEventProps(data, create) {
  const tags = data.tags || []

  const eventProps = {
    //успех загрузки поста
    status: 'success',
      //количество тегов
      tags_count:tags.length,
    //количество медиа файлов в посте
    media_count:(getState('upload.extraFiles') || data.extra_files_ids || []).length,
    //длина заголовка
    hed_len:(getState('upload.title') || data.title || '').length,
    //длина описания
    disc_len:(getState('upload.description') || data.description || '').length,
    subscription_type:getState('upload.subscriptionType') || data.subscriptionType || 0,
    post_kind:data.post_kind
  }

  //тип поста: анкета, тема, обычный пост
  if (data.postTheme || data.post_kind === 'postTheme'){
    eventProps['type'] = 'theme'
  } else if (data.postProfile || data.post_kind === 'postProfile'){
    eventProps['type'] = 'profile'
  } else{
    eventProps['type'] = 'post'
  }

  if (data.status){
    eventProps['moderation_status'] = data.status
  }
  //тип основного медиа файла: фото, видео, gif
  if (tags.indexOf(GIF_TAG_ID) !== -1){
    eventProps['main_media_type'] = 'gif'
  } else if (data.postType === 1){
    eventProps['main_media_type'] = 'video'
  } else {
    eventProps['main_media_type'] = 'photo'
  }

  //события добавления тега к посту
  tags.forEach(tagId =>{
    sendAmplitudeEvent(create ? 'tag_added_to_post' : 'admin_tag_added_to_post', {
      tag_id: tagId,
      post_id: data.resp ? data.resp.post_id : 0
    })
    eventProps[(create ? 'tag_' : 'admin_tag_') + tagId ] = 1
  })

  return eventProps
}

export function postCreateSuccessEvent(data) {
  sendAmplitudeEvent('post_create', getEventProps(data, true))
}

export function postCreateErrorEvent() {
  sendAmplitudeEvent('post_create', {
    status: 'error'
  })
}

export function postCreateClickEvent(edit) {
  sendAmplitudeEvent('post_create', {
    status: 'click',
    edit: Boolean(edit)
  })
}

export function postEditEvent(status) {
  sendAmplitudeEvent('post_edit', {
    status: status,
    imAdmin: imAdmin()
  })
}

export function postModerationEvent(data) {
  sendAmplitudeEvent('post_moderation', getEventProps(data))
}

export function postModerationErrorEvent() {
  sendAmplitudeEvent('post_moderation', {
    status: 'error'
  })
}

export function postClickEvent(postId, toRoom = false) {
  const profile = isProfile(postId)
  const uid = getPostAuthor(postId)
  sendPercentAmplitudeEvent('post_click', {
    type: profile ? 'profile' : 'post',
    online: getOnlineStatusForAmplitude(uid),
    stream: getStreamStatusForAmplitude(uid),
    home: imOnHomeRoute(),
    inRoom:imInRoom(),
    age_rating: getPostAgeRating(postId),
    roomNavigate:toRoom,
    feed: getUserFeed()
  })
}
