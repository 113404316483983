import routed from './routed'
import routeTo from './routeTo'
import girlRouted from  './girlRouted'
import acceptWarning from  './acceptWarning'
import acceptEula from  './acceptEula'
import hideEula from  './hideEula'
import hideTopBar from  './hideTopBar'
import scrollToTop from  './scrollToTop'
import userRedirectStatus from  './userRedirectStatus'
import setSafariRedirectStatus from  './setSafariRedirectStatus'
import girlPostRouted from  './girlPostRouted'
import girlsRouted from  './girlsRouted'
import postRouted from  './postRouted'
import roomRouted from  './roomRouted'
import dialogRouted from  './dialogRouted'
import panelsOnPop from './panelsOnPop'
import routeToRoom from './routeToRoom'
import createPanel from './createPanel'
import showSnackbar from './showSnackbar'
import testSnackbar from './testSnackbar'
import destroyPanel from './destroyPanel'
import onResize from './onResize'
import invalidRouted from './invalidRouted'
import roomTransited from './roomTransited'
import closeSnackbar from './closeSnackbar'
import copyToClipboard from './copyToClipboard'
import createPanelWithoutUrl from './createPanelWithoutUrl'
import setBroadcastDescription from './setBroadcastDescription'
import destroyPanelImmediately from './destroyPanelImmediately'
import forgotPassword from './forgotPassword'
import answerCall from './answerCall'
import getLastCaller from './getLastCaller'
import askPayout from './askPayout'
import askPayoutHttp from './askPayoutHttp'
import askPrivate from './askPrivate'
import addSub from './addSub'
import removeSub from './removeSub'
import changeSub from './changeSub'
import setSubPermission from './setSubPermission'
import startStream from './startStream'
import deepLink from './deepLink'
import hideMessage from './hideMessage'
import setUniqueEvent from './setUniqueEvent'
import openSupport from './openSupport'
import openShop from './openShop'
import getAditionalResources from '../chains/getAditionalResources'
import initOneSignal from './initOneSignal'
import postersRouted from './postersRouted'
import dialogsRouted from './dialogsRouted'
import coinsRouted from './coinsRouted'
import payoutRouted from './payoutRouted'
import searchRouted from './searchRouted'
import fansRouted from './fansRouted'
import settingsRouted from './settingsRouted'
import popRoutesHistory from './popRoutesHistory'
import saveOneSignalUserId from './saveOneSignalUserId'
import setOneSignalInited from './setOneSignalInited'
import processingOfRoutes from './processingOfRoutes'
import addContentRouted from './addContentRouted'
import onIframeMessage from './onIframeMessage'
import redirectToRoom from './redirectToRoom'
import loadState from './loadState'
import share from './share'
import setNotificationsButtonMode from './setNotificationsButtonMode'
import startPeeping from './startPeeping'
import prepareAndRouteTo from './prepareAndRouteTo'
import setAnimatedTopBar from './setAnimatedTopBar'
import onScrollEnd from './onScrollEnd'
import openRateUs from './openRateUs'
import leavePostScreen from './leavePostScreen'
import convertCurrency from './convertCurrency'
import exchangeCoins from './exchangeCoins'
import onUsersLoaded from './onUsersLoaded'
import agentTransfer from './agentTransfer'
import stopCall from './stopCall'
import allowNsfw from './allowNsfw'
import contentModerationRouted from './contentModerationRouted'
import complaintStream from './complaint/complaintStream'
import updateMyPaidContacts from './paidContacts/updateMyPaidContacts'
import storeSetUtil from '../../../utils/signals/StoreSetUtil'
import storePushUtil from '../../../utils/signals/StorePushUtil'
import storeShiftUtil from '../../../utils/signals/StoreShiftUtil'
import storeMergeUtil from '../../../utils/signals/StoreMergeUtil'
import storeUnshiftUtil from '../../../utils/signals/StoreUnshiftUtil'


export default {
  addSub,
  share,
  allowNsfw,
  stopCall,
  openRateUs,
  openSupport,
  askPayout,
  askPayoutHttp,
  askPrivate,
  acceptWarning,
  acceptEula,
  hideEula,
  hideTopBar,
  scrollToTop,
  userRedirectStatus,
  setSafariRedirectStatus,
  answerCall,
  removeSub,
  changeSub,
  routed,
  routeTo,
  openShop,
  loadState,
  dialogRouted,
  startPeeping,
  setOneSignalInited,
  prepareAndRouteTo,
  addContentRouted,
  getLastCaller,
  setSubPermission,
  getAditionalResources,
  setNotificationsButtonMode,
  redirectToRoom,
  onIframeMessage,
  processingOfRoutes,
  popRoutesHistory,
  coinsRouted,
  payoutRouted,
  searchRouted,
  fansRouted,
  settingsRouted,
  dialogsRouted,
  saveOneSignalUserId,
  girlRouted,
  girlPostRouted,
  postersRouted,
  girlsRouted,
  postRouted,
  roomRouted,
  invalidRouted,
  createPanel,
  panelsOnPop,
  startStream,
  deepLink,
  onResize,
  hideMessage,
  routeToRoom,
  testSnackbar,
  showSnackbar,
  destroyPanel,
  roomTransited,
  closeSnackbar,
  setUniqueEvent,
  copyToClipboard,
  createPanelWithoutUrl,
  setBroadcastDescription,
  destroyPanelImmediately,
  forgotPassword,
  initOneSignal,
  setAnimatedTopBar,
  onScrollEnd,
  onUsersLoaded,
  leavePostScreen,
  convertCurrency,
  exchangeCoins,
  agentTransfer,
  contentModerationRouted,
  complaintStream,
  updateMyPaidContacts,
  storeSetUtil,
  storePushUtil,
  storeShiftUtil,
  storeMergeUtil,
  storeUnshiftUtil
}
