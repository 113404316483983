import { state } from 'cerebral'
import { loadUser } from '@/utils/userLoader'
import { COIN_ICON } from '@/utils'
import controller from '@/controller'
import getStreamMetadata from '@/utils/getStreamMetadata'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import { LIVE_PERMISSION, needSubscription } from '@/utils/subscriptionUtil'
import config from '@/config'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

const { soundUrl } = config

/*let timerId = -1
const peepTimer = ({ store, get }) => {
  const timeLeft = get(state`room.peepTimeLeft`)
  if (timeLeft <= 0) {
    clearInterval(timerId)
    timerId = -1
  }
  store.set('room.peepTimeLeft', timeLeft - 1)
}*/

const addActivityMsg = ({ data, store, get }) => {
  loadUser(data.from, users => {
    const actPayer = users[data.from]

    let textMessage = ''
    if (get(state`room.streamInRoom`) === get(state`auth.uid`)) {
      textMessage = '+$' + Math.trunc((data.received / get(state`intl.settings.coinsToMoney`)) * 100) / 100 + '\n' + get(state`intl.content.act_${data.activity}_title`)
    } else {
      textMessage = '+' + COIN_ICON + data.received + '\n' + get(state`intl.content.act_${data.activity}_title`)
    }

    const messages = get(state`chat.roomsMessages.${data.to}`) || []
    messages.push({
      from: data.from,
      fromName: actPayer.name,
      postId: data.to + Date.now(),
      themeMsg: true,
      text: textMessage,
      fromNameTsc: actPayer.total_subscription_cost,
    })
  
    store.set(`chat.roomsMessages.${data.to}`, messages)
  })
}

//params: {"activity":"candy_eating",
//         "peep": "ead9c7682515efa1853415be2240aa70", 
//         "received":5000,
//         "from":"ead9c7682515efa1853415be2240aa70",
//         "to":"c17025846d5f3af0ba5c4b6a93d264ec"}
export const onPaidActivity = async ({ store, get, props }) => {
  const data = props.params
  if (data.peep) {
    if (data.from === get(state`auth.uid`)) {
      store.set('room.peepTimeLeft', 0)
    }
  } else {
    addActivityMsg({ data, store, get })
    
    if (data.to === get(state`auth.uid`)) {
      try {
        const sound = new Audio(soundUrl + data.activity + '.mp3')
        await sound.play()
      } catch (e) {
        console.log('sound not allowed')
      }
      
      const addPopup = controller.getSequence('room.addPopup')
      addPopup({ fromUid: data.from,  title: `act_${data.activity}_title`, pink: false })
      return
    }
    if (data.from !== get(state`auth.uid`)) {
      /*const act = getActivityData(data.activity)
      if (act.peep_time) {
        store.set('room.peepTimeLeft', Math.max(get(state`room.peepTimeLeft`), act.peep_time))
        store.set('room.peepActivityId', data.activity)
        store.set('room.peepUid', data.to)
        if (timerId < 0) {
          timerId = setInterval(peepTimer, 1000, state)
        }
      }*/
    } else {
      sendTopicPayAnalitycs({ store, get, data })
    }
  }
}

const sendTopicPayAnalitycs = ({ store, get, data }) => {
  const subscription_type = (needSubscription(data.to, LIVE_PERMISSION) || '-1').toLowerCase()
  const amdata = {
    subscription_type,
    sent_coins: data.received,
    age_rating: getUserAgeRating(data.to),
    content_rating: getAgeRatingByUid(data.to),
    counterparty_id: data.to,
    product_type: 'activity',
    activity_id: data.activity
  }

  if (get(state`app.isInPrivate`)) {
    amdata.topic_id = get(state`app.privateThemeId`)
    amdata.broadcast_type = 'private'
    sendAmplitudeEvent('topic_pay', amdata)
  } else {
    const metadata = getStreamMetadata(get(state`app.roomId`))
    const themeId = (metadata.postThemeId || '-1') + ''
    amdata.topic_id = themeId
    amdata.broadcast_type = 'public'
    sendAmplitudeEvent('topic_pay', amdata)
  }
}
