
//добавляет кнопки которые должны быть в фильтре всегда
import {
  TYPE_DATING,
  TYPE_FANS,
  TYPE_MATCH,
  TYPE_ONLINE,
  TYPE_REAL_DATING,
  TYPE_TOP_M, TYPE_TOP_M_L, TYPE_TOP_W
} from '../modules/posts/signals/getPosts'
import { isRestrictedMode } from './api/PWAUtil'

export const setDefaultFilters = filters => {
  if (!filters){
    filters = []
  }
  let hasSubButton
  //если фильтры пустые - добавим дефолтную группу
  if (filters.length === 0){
    filters.push( { type:[] })
  } else {
    //проверим, есть ли кнопка "подписки"
    filters.forEach(filterOb => {
      if (hasSubButton){
        return
      }
      Object.keys(filterOb).forEach(key => {
        if (hasSubButton){
          return
        }
        filterOb[key].forEach(button =>{
            if (typeof button !== 'number' && button.id === 'subscriptions'){
              hasSubButton = true
              return
            }
          })
      })
    })
  }

  //добавим дефолтные фильтры в первую группу
  Object.keys(filters[0]).forEach(key => {
    // filters[0][key].push({
    //   'localeKey': 'locale_filter',
    //   'id': 'locale_filter',
    //   'priority': 100
    // })

    if (isRestrictedMode()){
      filters[0][key].push({
        'localeKey': 'online_status',
        'id': TYPE_ONLINE,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'fans',
        'id': TYPE_FANS,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'top_users_month',
        'id': TYPE_TOP_M,
        'priority': 100
      })

      filters[0][key].push({
        'localeKey': 'locale_top',
        'id': TYPE_TOP_M_L,
        'priority': 100
      })
      return
    }
    filters[0][key].push({
      'localeKey': 'vip_filter',
      'id': 'vip_filter',
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'filter_videochat_dating',
      'id': TYPE_DATING,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'short_dating',
      'id': TYPE_REAL_DATING,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'fans',
      'id': TYPE_FANS,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'dating_matches',
      'id': TYPE_MATCH,
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'online_status',
      'id': TYPE_ONLINE,
      'priority': 100
    })

    // filters[0][key].push({
    //   'localeKey': 'chats_filter',
    //   'id': 'chat_only',
    //   'priority': 100
    // })

    filters[0][key].push({
      'localeKey': 'sponsors',
      'id': 'sponsors',
      'priority': 100
    })

    filters[0][key].push({
      'localeKey': 'top_users_month',
      'id': TYPE_TOP_M,
      'priority': 100
    })
    // filters[0][key].push({
    //   'localeKey': 'top_users_week',
    //   'id': TYPE_TOP_W,
    //   'priority': 100
    // })
    filters[0][key].push({
      'localeKey': 'locale_top',
      'id': TYPE_TOP_M_L,
      'priority': 100
    })
    return
  })

  //если не было подписок, добавим их
  if (!hasSubButton){
    filters.push({
      subscriptions: [{
        localeKey: 'subscriptions',
        id: 'subscriptions',
        'showTopBar': true,
        priority: 1199
      }]
    })
  }
  return filters
}
