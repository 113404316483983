import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'

export default [
  ({ props, store, get }) => {
    if (get(state`posters.scrollPos`) !== props.scrollPos) {
      store.set('posters.scrollPos', props.scrollPos)
    }
  }
]

// export default set(state`posters.scrollPos`, props`scrollPos`)
