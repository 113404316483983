import { getAwardURL, getCoreUrl } from './apiURLUtil'
import { getSessionId } from '../cookies'
import { setStoreWrapper } from '../StoreManager'
import { sendAmplitudeEvent } from '../GAEvent'
import { getAwardCost, updateAwardProgress } from '../awards/awardsProgressUtil'
import { isMe } from '../checks'
import { getAwardCategory, prepareAwardConfig } from '../awards/awardsConfig'
import { showSnackbarNew } from '../../modules/app/factories'
import { getLocaleValue } from '../localeUil'

export async function setMyGender(gender, callback) {
  const response = await fetch(getCoreUrl('/api/v1/users/gender'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      gender: gender
    })
  }).catch(e => {
    sendAmplitudeEvent('gender', { action: 'set_error' })
    if (callback){
      callback()
    }
  })

  if (response && response.ok) {
    sendAmplitudeEvent('gender', { action: 'set_success', gender:gender })
    if (callback){
      callback()
    }
    return
  }
  sendAmplitudeEvent('gender', { action: 'set_error' })
  if (callback){
    callback()
  }
}
