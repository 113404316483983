import { getUser } from '@/utils/userLoader'
import config from '@/config'
import controller from '@/controller'
import { posterVisibleStream, isStreamNormal, isPostVisible } from './ageRating/ageRatingUtil'
import { getBroadcast } from '../modules/posters/showInRoom'
import { getPostData } from './postUtils'
import { onlyWebRTCPlay } from './stream/iosPlayUtil'
import { isCDNDisable } from './appUtil'

const { previewUrl } = config
const PROXY_PREVIEW_URL = 'https://previews-proxy.faminta1.com/'
export const PROXY_PHOTOS_URL = 'https://photo-proxy.faminta1.com/images/'

const previewHistory = {}

const getPreviewUrl = (uid, ignorePostTheme = false) => {
  if (!ignorePostTheme) {
    const user = getUser(uid)
    if (user && user.storage && user.storage.metadata && user.storage.metadata.postThemeId) {
      const postId = user.storage.metadata.postThemeId
      const post = getPostData(postId)
      if (!post) {
        return { url: null, loading:true, w:480, h:640 }
      }

      const allowNsfw = controller.getState('auth.stuff.allowNsfw')

      if (post && post.statusFromDb > 0 && post.statusFromDb < 5
               && (allowNsfw ? posterVisibleStream(uid) : isStreamNormal(uid))
        && isPostVisible(post.postId)
      ) {
        return {
          postTheme: true,
          url: post.middle || post.original,
          original: post.original,
          height: post.previewHeight,
          width: post.previewWidth,
          w: post.previewWidth,
          h: post.previewHeight
        }
      }
    }
  }
  let bd = getBroadcast(uid)
  //для запоминания размеров закончившейся трансляции
  if (!bd){
    bd = previewHistory[uid]
  } else {
    previewHistory[uid] = bd
  }

  let w = 0
  let h = 0
  if (bd){
    //трансляции с IOS нуждаются в поворте пропорций TODO когда будут не только IOS добавить доп проверку
    // if (onlyWebRTCPlay(uid)){
    //   w = bd.h
    //   h = bd.w
    // } else {
      w = bd.w || 480
      h = bd.h || 640
    // }
  }

  return {
    url: (isCDNDisable() ? PROXY_PREVIEW_URL : previewUrl) + uid + '.webp?v=' + Math.floor(Date.now() / 3600000),
    w:w,
    h:h
  }
}

export default getPreviewUrl
