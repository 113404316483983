import { state } from 'cerebral'
import { set } from 'cerebral/factories'
import { dispatchUrlChangeToParent } from '../actions'
import { handleRoute } from './routed'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import getStreamMetadata from '@/utils/getStreamMetadata'
import askRateApp from '@/utils/askRateApp'
import isVip from '../../../utils/isVip'
import { callSequence } from '../../../utils/StoreManager'
import { getPostersScrollPosition } from '../../../utils/scrollRememberUtil'

export default [
  ({ get }) => {
    const roomId = get(state`app.roomId`)
    const metadata = getStreamMetadata(roomId)
    callSequence('app.setAnimatedTopBar',{ withAnimate: false })
    callSequence('app.hideTopBar',{ value: getPostersScrollPosition() > 300 })
    if (metadata && metadata.theme) {
      return {
        roomId,
        themeId: (metadata.postThemeId || '-1') + '',
        isInPrivate: get(state`app.wasInPrivate`)
      }
    }
  },
  set(state`app.wasInPrivate`, null),
  () => ({ route: 'home' }),
  dispatchUrlChangeToParent,
  handleRoute,
  ({ get, props, store }) => {
    const firstViewed = get(state`auth.firstViewed`)
    //поcылаем аналитику c 10% вероятноcтью, если это не VIP юзер и не приват
    if (firstViewed && (!isVip() && Math.random() > 0.1 && !props.isInPrivate)) {
      return
    }
    
    const streamData = get(state`room.streamData`)
    if (!streamData) { return }

    const roomEnterTime = get(state`room.roomEnterTime`)
    if (roomEnterTime === 0) { return }

    const amData = {
      viewing_category: 'viewing_broadcast',
      content_rating: getAgeRatingByUid(props.roomId),
      model_id: props.roomId,
      subscription_type: '-1',
      broadcast_type: props.isInPrivate ? 'private' : 'public',
      end_reason: 'user',
      topic_id: props.themeId,
      avg_bitrate: streamData.bitrate / 1000000,
      height: streamData.height,
      width: streamData.width,
      protocol: streamData.protocol || 'HLS',
      firstView: !firstViewed
    }

    store.set(state`auth.firstViewed`, true)

    if (streamData.brMetadata) {
      amData.codec = streamData.brMetadata.codec 
      amData.subscription_type = (streamData.brMetadata.needSub || '-1').toLowerCase()
    }
    if (streamData.videoStartTime > 0) {
      const brWatchTime = Date.now() - streamData.videoStartTime
      if (brWatchTime < 100 || brWatchTime > 9999999 || !props.themeId) { return }
      const starting_duration = streamData.videoStartTime - roomEnterTime
      if (starting_duration < 10 || starting_duration > 1000000) { return }
      
      amData.starting_duration = starting_duration / 1000
      amData.viewing_duration = brWatchTime / 1000

      if (amData.viewing_duration){
        sendAmplitudeEvent('topic_duration_user', amData)
      }

      if (brWatchTime > 60000) { askRateApp('stream_watch') }
    } else {
      amData.starting_duration = 0
      amData.viewing_duration = 0
      if (amData.viewing_duration){
        sendAmplitudeEvent('topic_duration_user', amData)
      }
    }
  },
  ({ store }) => store.set('room.streamData', null)
]
