import { push, when, shift, set } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { send } from '../../socket/actions'
import { subscribeOnLike } from '../../auth/actions/subscribeOnLike'
import { getPostAuthor } from '../../../utils/postUtils'
import { getSequence, getState } from '../../../utils/StoreManager'
import { getProfilePostId, profileClick } from '../../../utils/userUtil'
import { getMyUid, imVIP } from '../../../utils/my'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { moderEditPanel } from '../../../components/Panels/types'
// import { datingNeedProfile } from '../../../components/Screens/Posters/Filters'

export function datingNeedProfile(key) {
  sendAmplitudeEvent('dating', {
    action:key//'need_set_profile'
  })
  getSequence('moderation.postModerationOpen')({ isProfile:true })
  getSequence('app.createPanel')(moderEditPanel(null, 740, true, key))
}

export default [
  ({ get, path, store, props }) => {
    if (getState('posts.myLikes').indexOf(props.postId) === -1){
      // //если у меня нет профиля то лайкать нельзя
      // if (!getProfilePostId(getMyUid()) && !imVIP()){
      //   return path.cantLike()
      // }
      return path.sendLike()
    }

    return path.dislike()
  }, {
    dislike: [
      ({ get, store, props }) => {
        const likes = get(state`posts.myLikes`)
        const index = likes.indexOf(props.postId)
        likes.splice(index, 1)
        store.set('posts.myLikes', likes)
      }
    ],
    sendLike: [
      ({ props }) => ({
        params: { postId: Number(props.postId) },
        method: 'postLike'
      }),
      ({ props }) =>{
        subscribeOnLike(getPostAuthor(props.postId))
      },
      send,
      push(state`posts.myLikes`, props`postId`),
      when(state`posts.myLikes`, myLikes => myLikes.length > 100),{
        true: [
          shift(state`posts.myLikes`)
        ],
        false: []
      }
    ],
    cantLike: [
      () => {
        datingNeedProfile('need_set_profile')
      }
    ]
  },

  when(state`posts.myLikes`, props`postId`, (myLikes, postId) => myLikes.indexOf(postId) === -1), {
    true: [

    ],
    false: [

    ]
  },
]
