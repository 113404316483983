import controller from '@/controller'
import { isTWA } from '@/utils/twa'
import ls from 'local-storage'
import { rateUsPanel } from '../components/Panels/types'
import { setStoreWrapper } from './StoreManager'
import { sendAmplitudeEvent } from './GAEvent'

let intervalId = -1

//частота запроса оценки раз в 2 дня
const rateTimeout = 2  * 24 * 60 * 60 * 1000

const askRateApp = (reason) => {
  if (!isTWA() || intervalId >= 0) { return }
  
  const askTime = ls.get('fm.askTime') || 0
  if (Date.now() - askTime < rateTimeout) { return }

  const version = ls.get('fm.rated_twa_version')
  if (version === controller.getState('app.twa_version')) { return }
  
  intervalId = setTimeout(()=>{
    ls.set('fm.askTime', Date.now())
    clearInterval(intervalId)
    sendAmplitudeEvent('rate_app', { reason: reason, action: 'show' })
    setStoreWrapper('askRateReason', reason)
    controller.getSequence('app.createPanel')(rateUsPanel)
  }, 3000)
}

export default askRateApp
