import { isNumber } from 'lodash'

export default [
  ({ props, store }) => {
    if (isNumber(props.avaX1)) {
      store.set('upload.avaX1', Math.max(0, props.avaX1))
    }
    if (isNumber(props.avaX2)) {
      store.set('upload.avaX2', Math.max(0, props.avaX2))
    }
    if (isNumber(props.avaY1)) {
      store.set('upload.avaY1', Math.max(0, props.avaY1))
    }
    if (isNumber(props.avaY2)) {
      store.set('upload.avaY2', Math.max(0, props.avaY2))
    }
    if (isNumber(props.avaW)) {
      store.set('upload.avaW', Math.max(0, props.avaW))
    }
    if (isNumber(props.avaH)) {
      store.set('upload.avaH', Math.max(0, props.avaH))
    }
  }
]
