import { getState } from './StoreManager'

export function getGiftStars(giftId) {
  const extraStarsGifts =  getState('intl.settings.giftsNew') || {}
  if (!extraStarsGifts[giftId]){
    return 5
  }
  return extraStarsGifts[giftId].stars || 5
}

export function getGiftCost(giftId) {
  const extraStarsGifts =  getState('intl.settings.giftsNew') || {}
  if (!extraStarsGifts[giftId]){
    return 0
  }
  return extraStarsGifts[giftId].cost || 0
}
