import React, { Component } from 'react'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import { Spinner } from './Loading'
import { string, func, object, bool } from 'prop-types'
import { callSequence } from '../utils/StoreManager'
import { getMyUid } from '../utils/my'

const Container = styled.div({
  position: 'relative',
})

const Img = styled.img({
  height: '100%',
  width: '100%',
}, propsOverrideCss)

class ImageWithLoader extends Component {
  state = {
    loadedSrc: '',
  }

  render() {
    const { style, filter, objectFit, onLoad, src, onClick, noSpinner, imgStyle, map, stopPropagation, customClass  } = this.props
    return (
      <Container style={style} className={customClass} onClick={(e) => {
        if (stopPropagation){
          e.stopPropagation()
        }
        if (onClick){
          onClick()
        }
      }} >
        <Img src={ src } style={ imgStyle } filter={ filter } useMap={map} objectFit={ objectFit }
             opacity={(this.state.loadedSrc !== src && !noSpinner) ? 0 : 1}
             onLoad={ e => {
               this.setState({ loadedSrc: src })
               if (onLoad){
                 onLoad(e)
               }
             }} />
        {this.state.loadedSrc !== src && !noSpinner &&
          <Spinner borderColor='rgba(221, 221, 221, 0.36)' borderTopColor='#ffffff' />
        }
      </Container>
    )
  }
}

ImageWithLoader.propTypes = {
  filter: string,
  objectFit: string,
  customClass: string,
  onLoad: func,
  src: string,
  style: object,
  onClick: func,
  noSpinner:bool,
  map:object,
  imgStyle: object,
  stopPropagation: bool
}

export default ImageWithLoader
