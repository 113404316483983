import { state } from 'cerebral'
import { updateLSProps } from '../../../../utils/LocalStorageUtil'
import { showSnackbar } from '../../factories'

export default [
  ({ store, get, props }) => {
    let myLSProps = get(state`auth.myLSProps`)
    if (!myLSProps.bannedStreams){
      myLSProps.bannedStreams = {}
    }
    myLSProps.bannedStreams[props.uid] = true

    store.set('auth.myLSProps', myLSProps)

    updateLSProps(get(state`auth.uid`), myLSProps)

    //ресет для переотрисовки афиши
    store.set('posters.raw', get(state`posters.raw`))
  },

  showSnackbar(state`intl.content.claim_success`, 3000, 'success')
]
