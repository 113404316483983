import controller from '@/controller'
import showInRoom from '../modules/posters/showInRoom'
import { loadUser, getUser } from './userLoader'

let spamTimerId = -1

export const initPrivateOfferSpam = () => {
  clearTimeout(spamTimerId)
  const settings = controller.getState('intl.settings')
  if (!settings || controller.getState('app.ageRating') < 14) {return}
  const time = (1 + Math.random()) * 60 * 1000 * (settings.privateOfferInterval || 10)
  spamTimerId = setTimeout(sendPrivateOfferSpam, time)
}

const sendPrivateOfferSpam = () => {
  initPrivateOfferSpam()

  if (controller.getState('auth.model') > 0 ||
      controller.getState('room.streamInRoom') ||
      controller.getState('app.isInPrivate') ||
      !controller.getState('auth.pushSettings.privateOffer') ||
      controller.getState('auth.coins') < 9999) {
    return
  }

  const raw = controller.getState('posters.raw') || {}
  const broadcasts = Object.keys(raw).map(e => raw[e]).filter(showInRoom)
  if (broadcasts.length === 0) {
    return
  }

  const randomBr = broadcasts[ Math.round(Math.random() * 199) % broadcasts.length ]
  
  loadUser(randomBr.uid, () => {
    const user = getUser(randomBr.uid)
    const intl = controller.getState('intl.content')
    const showSnackbar = controller.getSequence('app.showSnackbar')
    showSnackbar({ 
      text: intl.call_her_button.replace('{name}', user.name), 
      type: 'privateOffer',
      additionalInfo: randomBr.uid
    })
  })
}
