import signals from './signals'

export default {
  state: {
    data: [],
    subsData: [],
    allGirlsLoaded: false,
    lastSearch: '',
    isLoading: false,
  },
  sequences: signals
}
