import { push, when, shift } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { send } from '../../socket/actions'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import showPost from './showPost'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

export default [
  () => ({ hideAll: true }),
  panelsOnPop,
  when(state`posts.viewedPhotos`, props`postId`, (viewedPhotos, postId) => viewedPhotos.indexOf(postId) === -1), {
    true: [
      ({ props }) => ({
        params: { postId: Number(props.postId) },
        method: 'postView' 
      }),
      send,
      push(state`posts.viewedPhotos`, props`postId`),
      when(state`posts.viewedPhotos`, viewedPhotos => viewedPhotos.length > 100),{
        true: [
          shift(state`posts.viewedPhotos`)
        ],
        false: []
      },
    ],
    false: []
  },
  showPost,
  // ({ props, get }) => {
  //   const postData = get(state`posts.postsData.${props.postId}`)
  //   const eventData = {
  //     sent_coins: 0,
  //     age_rating: getUserAgeRating(postData.uid),
  //     content_rating: postData.ageRating,
  //     counterparty_id: postData.uid || '-1',
  //     product_id: props.postId,
  //     content_category: 'photo'
  //   }
  //
  //   if (postData.subscriptionType) {
  //     eventData.subscription_type = postData.subscriptionType.toLowerCase()
  //   }
  //   sendAmplitudeEvent('users_content', eventData)
  // },
]
