import { state } from 'cerebral'
import { getAppId } from '../../../utils/twa'
import { dispatchUrlChangeToParent } from '../actions'

export default [
  ({ router, store, get }) => {
    const history = get(state`app.routesHistory`) || []
    history.pop()
    store.set('app.routesHistory', history)

    const lang = get(state`intl.lang`)
    let redirectUrl = history.length > 0 ? 
                      `/${lang}` + history[history.length - 1] :
                      `/${lang}/p`
    const appId = getAppId()
    if (appId) {
      redirectUrl += '?appid=' + appId
    }
    router.redirect(redirectUrl)
  },
  dispatchUrlChangeToParent
]
