let reconnectTimeout = 0
let callCount = 0

export function getWSSReconnectTimeout() {
  reconnectTimeout += 1000 + 1000*callCount*Math.random()
  callCount++
  console.log('reconnectTimeout ' + reconnectTimeout)
  return reconnectTimeout
}

export function resetWSSReconnectTimeout() {
  callCount = 0
  reconnectTimeout = 0
}

export function getReconnectCount() {
  return callCount
}
