import { state } from 'cerebral'
import controller from '@/controller'

export default ({ store, get, props }) => {
  const addPopup = controller.getSequence('room.addPopup')
  addPopup({ fromUid: props.viewer,  title: 'ui_kit_private_call', pink: true })

  const myUid = get(state`auth.uid`)
  if (myUid !== props.model) {
    return
  }

  const messages = get(state`chat.roomsMessages.${props.model}`) || []
  messages.push({
    from: 'fm',
    fromName: 'Videochat',
    postId: Date.now(),
    text: get(state`intl.content.theme_${props.themeId}_descr`)
  })
  store.set(`chat.roomsMessages.${props.model}`, messages)
}
