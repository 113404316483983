import { getTopPanelType } from '../modules/app/actions'
import { ANOTHER_LOGIN, CONNECTION_LOST } from '../components/Panels/types'
import { getState } from './StoreManager'

export let NEED_RELOAD

export function checkReload(hidden) {
  //если приложение возвращается в фокус проверим коннект, если его нет то перезагрузим страницу
  if (!hidden){
    const lastPanelType = getTopPanelType()
    console.log('lastPanel ' + lastPanelType + ' socet ' + getState('socket.isOpen'))
    if (lastPanelType === ANOTHER_LOGIN || lastPanelType === CONNECTION_LOST || !getState('socket.isOpen')){
      goReload()
    } else {
      // NEED_RELOAD = true
      // setTimeout(() => {
      //   NEED_RELOAD = false
      // }, 1500)
    }
  }
}

export function goReload() {
  location.reload()
}

