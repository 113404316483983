import React from 'react'
import { string, object, func } from 'prop-types'
import styled from '@emotion/styled'
import { flex } from '@/utils'
import { connect } from '@cerebral/react'
import { state, sequences } from 'cerebral'

const Container = styled.div({
  width: '100%',
  height: '100%',
  position: 'absolute',
  textAlign:'center',
  zIndex: 1000,
  backgroundColor: '#FFF',
  ...flex('column', 'center', 'flex-start'),
})

const Title = styled.div({
  padding: '48px 16px 4px 16px',
  fontSize: 24,
  fontWeight: 500
})

const Descr = styled.div({
  width: 320,
  color: '#999',
  fontSize: 16,
})

const ToSupportBtn = styled.div({
  margin: '32px 16px 0px 16px',
  padding: '12px 16px',
  cursor: 'pointer',
  borderRadius: 4,
  fontSize: 14,
  textAlign: 'center',
  backgroundColor: '#2196f3',
  color: '#ffffff',
  '&:hover':{
    backgroundColor: '#2599f8',
  },
})

const BanScreen = ({
  banId,
  openSupport,
  intl
}) => 
  <Container>
    <Title>
      {intl.banned_title}
    </Title>
    <Descr>
      {(intl.banned_description || '').replace('%banId%', banId)}
    </Descr>
    <ToSupportBtn onClick={ () => openSupport() }>
      {intl.banned_button}
    </ToSupportBtn>
  </Container>

BanScreen.propTypes = {
  banId: string,
  intl: object,
  openSupport: func
}

export default connect(
  {
    intl: state`intl.content`,
    banId: state`app.banId`,
    openSupport: sequences`app.openSupport`,
  },
  BanScreen
)
