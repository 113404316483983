import { state } from 'cerebral'
import { showStartPanel } from '../../../components/Panels/types'
import createPost from './createPost'
import createPanel from '../../app/signals/createPanel'
import { saveMetadata } from '../../room/signals/sendCreateBroadcast'

export default [
  createPost,
  ({ store, get, props }) => {
    const metadata = get(state`auth.storage.metadata`) || {}
    store.set('auth.storage.metadata', { ...metadata, postThemeId: props.post_id })
  },
  () => ({ options: showStartPanel.options }),
  createPanel,
  saveMetadata
]
