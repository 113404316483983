import { when, set, wait } from 'cerebral/factories'
import { state, props } from 'cerebral'
import { getPaidPhoto } from '../actions'
import openShop from '../../app/signals/openShop'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import showPost from './showPost'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { getPostCost } from '@/utils'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

export default [
  () => ({ hideAll: true }),
  panelsOnPop,
  wait(100),
  set(state`posts.buyingPostId`, props`postId`),
  ({ props }) => ({ cost: getPostCost(props) }),

  when(state`auth.coins`, props`cost`, (myCoins, photoCost) => myCoins >= photoCost), {
    true: [
      getPaidPhoto,{
        success:[
          set(state`posts.buyingPostId`, -1),
          // ({ props, store, get }) => {
          //   const postData = get(state`posts.postsData.${props.postId}`)
          //   const eventData = {
          //     sent_coins: props.cost || 0,
          //     age_rating: getUserAgeRating(postData.uid),
          //     content_rating: props.ageRating,
          //     counterparty_id: postData.uid || '-1',
          //     product_id: props.postId,
          //     delicate: props.ageRating > get(state`app.ageRating`),
          //     content_category: 'photo'
          //   }
          //
          //   if (postData.subscriptionType) {
          //     eventData.subscription_type = postData.subscriptionType.toLowerCase()
          //   }
          //   sendAmplitudeEvent('users_content', eventData)
          // },
          showPost
        ],
        fail:[
          set(state`posts.buyingPostId`, -1),
        ]
      }
    ],
    false: [
      set(state`posts.buyingPostId`, -1),
      ({ store, get, props }) => {
        const needCoins = props.cost - get(state`auth.coins`)
        return {
          message: get(state`intl.content.need_more_coins`).format(needCoins),
          reason: 'buy-paid-photo'
        }
      },
      openShop
    ]
  },
]
