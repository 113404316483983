import GAEvent, { sendAmplitudeEvent } from '../GAEvent'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude } from '../../modules/socket/actions'
import { getUserAgeRating } from '../ageRating/ageRatingUtil'
import { getCoreUrl, getReviewsURL } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { isModel } from '../user/userInfos'
import { showSnackbarNew } from '../../modules/app/factories'
import { getShowcase } from '../showcase/getShowcase'
import { getUser, updateUser } from '../userLoader'
import { setStoreWrapper } from '../StoreManager'
import { clearGETCash } from '../../api/universalLoader'
import { dellFullShowcaseCash } from './getShowcase'
import { getLocaleValue } from '../localeUil'
import askRateApp from '../askRateApp'

export async function sendRating(to_uid, rating, anonymous, text ) {
  const response = await fetch(getReviewsURL(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      to_uid,
      rating,
      anonymous,
      text
    })
  }).catch(e => {
    console.warn('catch error', e)
  })

  if (response && response.ok) {
    showSnackbarNew(getLocaleValue('success_review_send'))

    //если оценил юзера больше 4 баллов просим оценить приложение
    if (rating > 4){
      askRateApp('rate_user')
    }

    sendAmplitudeEvent('user_review', {
      action:'send',
      rating,
      anonymous,
      isModel:isModel(to_uid),
      online: getOnlineStatusForAmplitude(to_uid),
      stream: getStreamStatusForAmplitude(to_uid),
      age_rating: getUserAgeRating(to_uid)
    })
  }
}

export async function delRating(id, anonymous, reviewOwner) {
  const response = await fetch(getReviewsURL(id), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      id,
      anonymous
    })
  }).catch(e => {
    console.warn('catch error', e)
  })

  if (response && response.ok) {
    showSnackbarNew(getLocaleValue('success_review_delete'))

    sendAmplitudeEvent('user_review', {
      action:'delete',
      anonymous,
      isModel:isModel(reviewOwner),
      online: getOnlineStatusForAmplitude(reviewOwner),
      stream: getStreamStatusForAmplitude(reviewOwner),
      age_rating: getUserAgeRating(reviewOwner)
    })
  }
}

export function addLocalReview(params) {
  //to_uid, rating, anonymous, text, gift_id, from_uid
  const user = getUser(params.to_uid)
  if(!user){
    return
  }
  const currentShowcase = getShowcase(params.to_uid)
  currentShowcase.unshift(params)

  if (!user.lastReviews){
    user.lastReviews = {}
  }
  user.lastReviews.reviews = currentShowcase
  user.rating = params.user_rating

  updateUser(params.to_uid, user)

  dellFullShowcaseCash(params.to_uid)
  setStoreWrapper('allShowcase', null)
}

export function delLocalReview(params) {
  // params.to_uid = '8f409b225c8df8943d7687844a012df4'
  // params.id = 1234
  const user = getUser(params.to_uid)
  if(!user){
    return
  }
  const currentShowcase = getShowcase(params.to_uid)
  const newShowcase = []
  currentShowcase.forEach(item =>{
    if (item.id !== params.id){
      newShowcase.push(item)
    }
  })

  if (!user.lastReviews){
    user.lastReviews = {}
  }

  user.lastReviews.reviews = newShowcase
  user.lastReviews.beforeDellLenght = currentShowcase.length
  user.rating = params.user_rating

  updateUser(params.to_uid, user)

  dellFullShowcaseCash(params.to_uid)
  setStoreWrapper('allShowcase', null)
}
