import { cancelUpload } from '../actions/uploadFile'
import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { getSessionId } from '@/utils'

import config from '@/config'
const { postUploadUrl } = config

export const deleteRemoteFile = fileId => {
  return
  // fetch(`${postUploadUrl}/api/v1/media-file/${fileId}/`, {
  //   method: 'DELETE',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ sid: getSessionId() })
  // }).catch(e => console.warn('catch error', e))
}

export default [
  cancelUpload,
  set(state`upload.uploadProgress`, -1),
  ({ get }) => {
    const mainFileId = get(state`upload.mainFileId`)
    if (mainFileId) {
      deleteRemoteFile(mainFileId)
    }
    const extraFiles = get(state`upload.extraFiles`)
    extraFiles.forEach(
      ({ file_id }) => deleteRemoteFile(file_id)
    )
  },
  set(state`upload.mainFileId`, null),
  set(state`upload.extraFiles`, []),
]
