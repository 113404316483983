import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'

export default [
  set(state`room.streamInRoom`, props`streamInRoom`),
  ({ props, store, get }) => {
    if (props.streamInRoom) { return }

    store.unset(`posters.raw.${get(state`auth.uid`)}`)
  }
]
