import { state } from 'cerebral'
import { getActivityData } from '@/utils/themeUtils'

export default ({ store, get, path }) => {
  const act = getActivityData(get(state`room.peepActivityId`))
  const settings = get(state`intl.settings`)
  
  if (Math.ceil(act.cost * settings.peepCost) > get(state`auth.coins`)) {
    return path.no()
  }
  return path.yes()
}
