import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import loadCategoryTags from '../../modules/upload/signals/loadCategoryTags'

export async function loadProfileTags() {
  if (!getState('loadedCatsProfile')){
    setStoreWrapper('upload.postKind', 'postProfile')
    callSequence('auth.loadCategoryTags')
  }
}

