import { getUser, loadUser, loadUsers } from '../userLoader'
import { isFemale } from '../user/userInfos'
import { getUserStableRandom } from '../user/getUserStableRandom'
import { getAllFreeFemaleCharacters, getAllFreeMaleCharacters, getVIPCharacters } from './awardsConfig'
import { imVIP } from '../my'
import isVip from '../isVip'
import { isBuffActive } from '../buffUtils'
import { CHICKEN } from '../../modules/buffs'
import { CHICKEN_HERO, isChicken, isRabbit, RABBIT_HERO } from '../cardGame/cardGameUtil'
import { isCustomVanilla } from '../api/PWAUtil'

export function getUserMainCharacter(uid) {
  if (isCustomVanilla()){
    return null
  }

  const user = getUser(uid)

  if (isChicken(uid)){
    return CHICKEN_HERO
  }

  if (isRabbit(uid)){
    return RABBIT_HERO
  }

  //если нет юзера, то не показываем картинку, что бы лишнее не моргало
  if (!user){
    return null
  }

  if (user.awards && user.awards[0]){
    return user.awards[0].award
  }

  let characters
  //если есть юзер, но нет картинки - подставим какую-то в соттветствии с полом
  if(isVip(uid)){
    characters =  getVIPCharacters(isFemale(uid))
  }  else {
    characters = isFemale(uid) ? getAllFreeFemaleCharacters() : getAllFreeMaleCharacters()
  }

  return characters[getUserStableRandom(uid, characters.length)]
}

export function getVIPCharacter(uid){
  const characters =  getVIPCharacters(isFemale(uid))
  return characters[getUserStableRandom(uid, characters.length)]
}
