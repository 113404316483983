import { when } from 'cerebral/factories'
import { state } from 'cerebral'
import prepareAndRouteTo from './prepareAndRouteTo'
//import roomTransited from './roomTransited'
import { postersRoute, contentModerationRoute } from '@/utils/routerHelpers'
import controller from '@/controller'
import { isModeration } from '../../../utils/moderationUtil'

export default [
  when(state`app.routesHistory`, routesHistory => routesHistory.length < 2 || routesHistory[routesHistory.length - 2] === postersRoute), {
    true: [
      () => ({ url: postersRoute }),
      prepareAndRouteTo,
    ],
    false: [
      //({ get }) => ({ roomId: get(state`posts.postsData.${get(state`posts.selectedPostId`)}.uid`) }),
      //roomTransited,
      ({ get }) => {
        const postersHS = get(state`app.routesHistory`)
        console.log('rh ' + postersHS.length)
        if (isModeration) {
          const routeTo = controller.getSequence('app.prepareAndRouteTo')
          routeTo({ url: contentModerationRoute })
        } else {
          history.back()
          // const roomTransited = controller.getSequence('app.roomTransited')
          // roomTransited({ roomId: get(state`posts.postsData.${get(state`posts.selectedPostId`)}.uid`) })
        }
      },
    ]
  }
]
