import { panelsOnPop, createPanel } from '../actions'
import { unset, wait, when } from 'cerebral/factories'
import { state } from 'cerebral'

export default [
    unset(state`app.msgPanel_msgText`),
    unset(state`app.msgPanel_btnText`),
    panelsOnPop,
    wait(300),
    when(state`app.msgPanel_nextPanel`), {
        true: [
            ({ store, get }) => ({ options: { type:get(state`app.msgPanel_nextPanel`) } }),
            createPanel,
        ],
        false: []
    },
    unset(state`app.msgPanel_nextPanel`),
]
