import { state } from 'cerebral'
import controller from '@/controller'
import { isUserMessageBanned } from '../../../utils/ban/banMessageCheck'

export default ({ props, get, path }) => {
  if (props.msgData.from === get(state`auth.uid`)) {
    return path.my()
  }

  // if (checkTextForBadWords(props.msgData.text)) {
  //   return path.block()
  // }

  if (isUserMessageBanned(props.msgData.from)) {
    return path.block()
  }

  return path.notMy()
}

export const hideBadUrlsInDialog = text => {
  if (!text) return text
  const regexp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@;:%_\+.~#?&\/=]*)/gmi
  const trustedDomains = controller.getState('intl.trustedDomains')

  return text.replaceAll(
    regexp,
    m => trustedDomains.indexOf(m.split('/')[0]) === -1 ? '*******' : m
  )
  .replaceAll(/[\u{1D400}-\u{1D7FF}]/ug, '')
}

export const checkTextForBadWords = text => {
  if (!text) return true
  if (text.match(/[\u{1D400}-\u{1D7FF}]/u)) {
    return true
  }
  const words = (text || '').split(/[ \.]/gmi)

  const ageRating = controller.getState('app.ageRating')
  const badWords = controller.getState(`intl.stopWords.${ageRating}`) || []

  return !!words.find(
    word => word && badWords.indexOf(
      word
        .replaceAll(/https?:\/\/(www\.)?/gmi, '')
        .replaceAll(/\d/gmi, '')
        .trim().toLowerCase()
    ) !== -1
  )
}
