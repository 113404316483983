import { state } from 'cerebral'
import config from '@/config'

const { httpApiUrl } = config

export const checkEmailRequest = pathToForm => async ({ path, get }) => {
  const form = get(state`${pathToForm}`)
  const { email } = form
  
  //TODO: check email for validness
  //if (!form.email.isValid) return path.wrongEmail()
  const response = await fetch(httpApiUrl + '/auth/check_email?email=' + email)
  if (!response.ok) {
    return path.requestError({ status: response.status })
  }
  const result = await response.json()

  if (result.error) return path.error({ error: result.error })
  if (result.result) return path.exists()
  if (!result.result) return path.notExists()
}
