import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { overlayChatScrollToEnd } from '../../../utils/chat/chatMessagesManager'

export default [
  set(state`chat.chatOpen`, true),
  ()=>{
    overlayChatScrollToEnd()
  }
]
