import { wait } from 'cerebral/factories'
import { state } from 'cerebral'
import { panelsOnPop } from '../../app/actions'
import { showSnackbar } from '../../app/factories'
import { getSessionId } from '@/utils'

import config from '@/config'
import { updateLSProps } from '../../../utils/LocalStorageUtil'

const {
  ftCoreUrl
} = config

export default [
  //добавим пост в локальное хранилище, что бы его больше не показывать
  ({ store, get, props }) => {
    let myLSProps = get(state`auth.myLSProps`)
    if (!myLSProps.bannedPosts){
      myLSProps.bannedPosts = {}
    }
    myLSProps.bannedPosts[props.postId] = true

    store.set('auth.myLSProps', myLSProps)

    updateLSProps(get(state`auth.uid`), myLSProps)
  },
  async ({ props }) => {
    try {
      const response = await fetch(`${ftCoreUrl}/api/v1/posts-reports`, {
        method: 'POST',
        body: new URLSearchParams({ 
          sid: getSessionId(),
          post_id: props.postId,
          reason: props.tagId,
         })
      })
      await response.json()
    } catch (e) {
      console.log('failed to fetch')
    }
  },
  wait(250),
  showSnackbar(state`intl.content.claim_success`, 3000, 'success')
]
