import lget from 'lodash/get'
import controller from '@/controller'
import { isTWA } from '@/utils/twa'
import getStreamMetadata from './getStreamMetadata'
import { getPrivateThemes } from './directCallThemeUtil'

export const noDelicateRatingsArray = {
  12: [12],
  14: [12, 14],
  16: [14, 16],
  18: [16, 18]
}

const ratingsArray = {
  12: [12],
  14: [12, 14, 16],
  16: [14, 16, 18],
  18: [16, 18]
}

export const subsRatingsArray = {
  12: [12],
  14: [12, 14, 16],
  16: [12, 14, 16, 18],
  18: [12, 14, 16, 18]
}

export const getRatingArray = rating => {
  const postRatings = controller.getState('intl.appSourceConfig.postRatings') || []
  const postRatingsNSFW = controller.getState('intl.appSourceConfig.postRatingsNSFW') || []
  if (postRatings && postRatingsNSFW) {
    return [...postRatings, ...postRatingsNSFW]
  }

  const ageRatingPost = controller.getState('intl.appSourceConfig.ageRatingPost')
  if (ageRatingPost && noDelicateRatingsArray[ageRatingPost]) {
    return noDelicateRatingsArray[ageRatingPost]
  }
  if (controller.getState('intl.appSourceConfig.hideDelicate') || isTWA() || location.host === 'dev.js.faminta.com') {
    return noDelicateRatingsArray[rating]
  }
  return ratingsArray[rating] || ratingsArray[12]
}

export const getBlogRatingArray = rating => {
  const blogPostRatings = controller.getState('intl.appSourceConfig.blogPostRatings') || []
  const blogPostRatingsNSFW = controller.getState('intl.appSourceConfig.blogPostRatingsNSFW') || []
  if (blogPostRatings && blogPostRatingsNSFW) {
    return [...blogPostRatings, ...blogPostRatingsNSFW]
  }

  const ageRatingPost = controller.getState('intl.appSourceConfig.ageRatingPost')
  if (ageRatingPost && noDelicateRatingsArray[ageRatingPost]) {
    return noDelicateRatingsArray[ageRatingPost]
  }
  if (controller.getState('intl.appSourceConfig.hideDelicate') || isTWA() || location.host === 'dev.js.faminta.com') {
    return noDelicateRatingsArray[rating]
  }
  return subsRatingsArray[rating] || subsRatingsArray[12]
}

export const isActAllowed = actId => {
  const broadcastActivities = controller.getState('intl.settings.broadcast_activities')
  const actData = broadcastActivities[actId]

  if (!actData || actData.visible === 'moderator') {
    return false
  }

  const ageRating = controller.getState('app.ageRating')
  if (actData.age_rating > ageRating) {
    return false
  }

  return true
}

export const getThemeData = themeId => {
  const broadcastThemes = getPrivateThemes()
  return broadcastThemes[themeId] || {}
}

export const getActivityData = actId => {
  const settings = controller.getState('intl.settings')
  if (!settings || !settings.broadcast_activities) { return null }

  return settings.broadcast_activities[actId]
}

// export const getActsText = themeSettings => {
//   let res = ''
//   const ageRating = controller.getState('app.ageRating')
//   const settings = controller.getState('intl.settings') || {}
//   const broadcastThemes = getPrivateThemes()
//   const themeData = broadcastThemes[themeSettings.id]
//
//   let activities = themeSettings.activities || []
//
//   activities.sort((a1, a2) => {
//     if (!settings.broadcast_activities) { return 0 }
//     const act1 = settings.broadcast_activities[a1]
//     const act2 = settings.broadcast_activities[a2]
//     if (!act1 || !act2) {
//       return 0
//     }
//     return (act2.viewers_interest || 0) - (act1.viewers_interest || 0)
//   })
//
//   if (themeData) {
//     activities = activities.concat(themeData.required_activities || [])
//   }
//
//   for (let i = 0; i < activities.length; i++) {
//     const actId = activities[i]
//
//     if (actId === 'wave_your_hand') {
//       continue
//     }
//     const actData = getActivityData(actId)
//     if (!actData || actData.age_rating > ageRating) {
//       continue
//     }
//
//     const lbl = controller.getState(`intl.content.act_${actId}_label`)
//     if (lbl) {
//       res += (res.length === 0 ? ' ' : ', ') + lbl
//     }
//   }
//   return res
// }

export const getPrivateActsText = (roomId = null) => {
  const metadata = getStreamMetadata(roomId || controller.getState('app.roomId'))
  if (!metadata || !metadata.theme) return ''
  const intl = controller.getState('intl.content')
  const themeSettings = metadata.theme
  const ageRating = controller.getState('app.ageRating')

  const acts = themeSettings.privateActivities.reduce((reducer, actId, index) => {
    const actLabel = intl[`act_${actId}_label`]
    if (!actLabel || actLabel.length < 2) {
      return reducer
    }
    const actData = getActivityData(actId)
    if (actData.age_rating > ageRating) {
      return reducer
    }
    if (index > 0 && reducer.length > 0) reducer += ', '
    reducer += actLabel
    return reducer
  }, '')

  if (acts.length > 0) {
    return acts.charAt(0).toUpperCase() + acts.slice(1)
  }

  return acts
}

const getThemeName = id =>
  controller.getState(`intl.content.theme_${id}_title`)

export const getBroadcastPrivateThemeName = metadata =>
  metadata
    ? metadata.privateTheme
      ? getThemeName(metadata.privateTheme)
      : getThemeName(lget(metadata, 'theme.privateShowThemes[0]', ''))
    : ''

export const getThemeActivities = ({
  ageRating,
  roomId,
  isInPrivate,
  privateThemeId,
  settings,
  admin,
  superAdmin,
}) => {
  let themeSettings = null
  let activities = []
  if (!roomId || !settings) return activities
  const metadata = getStreamMetadata(roomId)
  if (metadata && metadata.theme) {
    themeSettings = metadata.theme
    activities = themeSettings.activities
    
    const td = getThemeData(themeSettings.id)
    if (td && td.required_activities) {
      activities = activities.concat(td.required_activities)
    }
    
    if (isInPrivate) {
      activities = activities.concat(themeSettings.privateActivities)
      const privateTheme = getThemeData(privateThemeId)
      activities = activities.concat(privateTheme.required_activities)
    }
  }

  if (admin || superAdmin) {
    activities.push('wave_your_hand')
  }
  
  activities = activities.filter((actId, idx) => {
    if (!actId || !settings.broadcast_activities) return false
    if (activities.indexOf(actId) !== idx) {
      return false//убираем дубликаты
    }
    const actData = settings.broadcast_activities[actId]
    if (!actData) {
      return false
    }
    if (actData.age_rating > ageRating) {
      return false//убираем не подходящие по рейтингу
    }
    return true
  })
  
  activities.sort((a1, a2) => {
    const act1 = settings.broadcast_activities[a1]
    const act2 = settings.broadcast_activities[a2]
    return (act2.viewers_interest || 0) - (act1.viewers_interest || 0)
  })
  return activities
}
