import { state } from 'cerebral'
import showInRoom, { showInPosters } from '../showInRoom'
import { isBuffActive } from '@/utils/buffUtils'
import { PROMOTION } from '@/modules/buffs'
import { isWaitForCall } from '../../../utils/ageRating/ageRatingUtil'
import { getProfilePostId } from '../../../utils/userUtil'

const calcSortRate = (poster, props) => {
  const { roomSpectators } = props
  
  let sortRate = 0

  if (roomSpectators[poster.uid]) {
    sortRate += roomSpectators[poster.uid].length
  }
  
  if (isWaitForCall(poster.uid)) {
    sortRate -= 100000
    if (getProfilePostId(poster.uid)){
      sortRate += 10
    }
  }

  if (isBuffActive(poster.uid, PROMOTION)) {
    sortRate += 100
  }

  return sortRate
}

const showedInPosters = get => {
  const raw = get(state`posters.raw`)
  const filters = get(state`posts.filters`)
  get(state`app.users`)
  const result = {}
  const sortProps = {
    roomSpectators: get(state`app.roomSpectators`),
  }
  for (const uid in raw) {
    const br = raw[uid]
    if (showInPosters(br, filters)) {
      result[uid] = br
      result[uid].sortRate = calcSortRate(br, sortProps)
    }
  }
  return result
}

export default showedInPosters
