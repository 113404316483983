import { state } from 'cerebral'
import config from '@/config'

const { vbApiUrl } = config

export default [
  async ({ get, store }) => {
    const categoryInterestTags = get(state`auth.categoryInterestTags`)
    if (categoryInterestTags.length === 0) {
      let params = '?categories=videochat_questionnaire_interests'
      params += '&limit=9999'
      const response = await fetch(
        `${vbApiUrl}/vb2/topTags/${get(state`intl.lang`)}` + params
      )
      if (!response.ok) { return }
      const result = await response.json()
      
      let tags = new Array()
      result.videochat_questionnaire_interests.topTags.forEach(item => {
        tags.push(item.tagId)
      })

      store.set('auth.categoryInterestTags', tags)
    }
  }
]
