import { GETRequest } from '../api/requestWrapper'
import { getCoreUrl } from '../api/apiURLUtil'
import { getState, setStoreWrapper } from '../StoreManager'
import { getSettings, getSettingsInt } from '../settingsUtil'
import { getLevel, getShapeID } from '../levelUtil'
import { getMyUid } from '../my'
import { createEnergyPanel } from '../../components/Panels/createPanelUtil'
import { getCurrentRoomId, getRoomOwnerUid } from '../navigation/navigationUtil'
import { isMe } from '../checks'
import { sendAmplitudeEvent } from '../GAEvent'

export function loadMyEnergy(){
  ///api/v1/energy
  GETRequest(getCoreUrl('/api/v1/energy'), (data)=>{
    updateMyEnergy(data)
  }, (data)=>{
    updateMyEnergy({ energy: 0 })
    console.log('enegryError')
  } )
}

export function updateMyEnergy(data){
  setStoreWrapper('myEnergy', data.energy)
}

export function getMyMaxEnergy(){
  return getSettingsInt('energy_base') + (getShapeID(getMyUid())-1)*getSettingsInt('energy_bonus_for_exp')
}

export const ENERGY_CHAT = 'energy_chat_cost'
export const ENERGY_DIALOG = 'energy_dialog_cost'
export const ENERGY_LIKE = 'energy_like_cost'

//TODO проверка достаточности энергии для действия
export function checkEnergy(action){
  if ((getState('myEnergy') - getEnergyCost(action)) < 0){
    createEnergyPanel('energy_low')
    sendAmplitudeEvent('energy_low', { action })
    return false
  }
  return true
}

export function getEnergyCost(action){
  if (action === ENERGY_CHAT && isMe(getRoomOwnerUid(getCurrentRoomId()))){
    return 0
  }
 return getSettingsInt(action)
}
