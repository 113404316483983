import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getSessionId } from '@/utils'
const { dialogsUrl } = config

export default [
  async ({ store, get, props }) => {
    const otherDialogs = get(state`chat.otherDialogs`) || {}
    
    if (!otherDialogs[props.uid]) {
      otherDialogs[props.uid] = {
        dialogs: []
      }
    }
    
    if (otherDialogs[props.uid].dialogsLoaded) return
  
    const dialogsPage = (otherDialogs[props.uid].dialogsPage || 0) + 1
    
    let result
    try {
      let response
      if (props.uid === 'AllDialogsMedia'){
        response = await fetch(`${dialogsUrl}/api/v1/dialogs/media?page=${dialogsPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
          },
        })
      } else {
        response = await fetch(`${dialogsUrl}/api/v1/dialogs?page=${dialogsPage}&uid=${props.uid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
          },
        })
      }

      if (!response.ok) return
      result = await response.json()
    } catch (e) {
      return 
    }

    const dialogsData = result.dialogs || [result.dialog]
    otherDialogs[props.uid].dialogsPage = dialogsPage
    if (dialogsData.length === 0) {
      otherDialogs[props.uid].dialogsLoaded = true
      store.set('chat.otherDialogs', otherDialogs)
    }
    
    const usersToLoad = dialogsData.map(dialog => dialog.users_in_dialog).flat(1)
    
    loadUsers(
      usersToLoad, 
      () => {
        const oldDialogs = (otherDialogs[props.uid].dialogs || []).map(d => {
          const newD = dialogsData.find(od => od.dialog_id === d.dialog_id)
          return newD ? { ...d, ...newD } : d
        })
        const allDialogs = [ 
          ...oldDialogs, 
          ...dialogsData.filter(
            d => !oldDialogs.find(od => od.dialog_id === d.dialog_id)
          ) 
        ]
        otherDialogs[props.uid].dialogs = allDialogs
        store.set('chat.otherDialogs', otherDialogs)
      }
    )
  }
]
