import { getUser } from '../userLoader'
import { getGiftUrl, getRatingUrl } from '../urlUtils'
import React from 'react'
import { clearGETCash, getGETCash, loadGET } from '../../api/universalLoader'
import { setStoreWrapper } from '../StoreManager'
import { getReviewsURL } from '../api/apiURLUtil'

export function getShowcase(uid) {
  const user = getUser(uid)
  return (user && user.lastReviews && user.lastReviews.reviews) || []
}

const DEFAULT_SHOWCASE = 9

export function showcaseShowAllVisible(uid) {
  const user = getUser(uid)
  if (user && user.lastReviews && user.lastReviews){
    if (user.lastReviews.beforeDellLenght > DEFAULT_SHOWCASE){
      return true
    }
    if (user.lastReviews.reviews){
      return user.lastReviews.reviews.length >  DEFAULT_SHOWCASE
    }
  }

  return false
}

export function getShowcasePicUrl(id) {
  return getRatingUrl(id)
}

export function getFullShowcase(uid, callback) {
  const id = 'showcase' + uid
  const inCash = getGETCash(id) || []

  setStoreWrapper('allShowcase', inCash)

  loadGET( id, getReviewsURL('uid/' + uid), (type, status) =>{
    if (status === 'success'){
      setStoreWrapper('allShowcase', getGETCash(id))
      if(callback){
        callback()
      }
    } else if (status === 'allLoaded'){
      if(callback){
        callback()
      }
    }
  }, 'reviews')
}

export function dellFullShowcaseCash(uid){
  const id = 'showcase' + uid
  clearGETCash(id)
}
