import { state } from 'cerebral'
import { getPostCost } from '../../../utils/stringUtils'
import freePhotoView from './freePhotoView'
import getPaidPhoto from './getPaidPhoto'
import watchVideo from './watchVideo'
import { setScreenInPosition } from '../../../utils/scrollRememberUtil'

export default [
  ({ path, get, props, store }) => {
    const { postId } = props

    const myUid = get(state`auth.uid`)
    const data = get(state`posts.postsData.${postId}`)
    if (!data) {
      return path.doNothing()
    }
    const { uid, ageRating, postType, videoLength, subscriptionType } = data

    let { paid } = data
    if (subscriptionType) {
      paid = false
    }

    if (postType === 1) {//video post
      let cost = 0

      if (paid && uid !== myUid) {
        cost = getPostCost(data)
      }
      
      return path.watchVideo({ postId, videoLength, uid, ageRating, cost })
    }
    
    if (!paid) {
      store.set('posts.buyingPostId', -1)
      return path.freePhotoView({ postId })
    }
    return path.getPaidPhoto(data)
  }, {
    getPaidPhoto,
    freePhotoView,
    watchVideo,
    doNothing: []
  }
]
