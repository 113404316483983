import { setSessionId } from '@/utils'

export const clearUserData = ({ store, get }) => {
  store.merge('auth', {
    isLoggedIn: false,
    userId: null,
    avatar: null,
    coins: 0,
    country: null,
    dob: '',
    email: null,
    experience: 0,
    fid: null,
    name: '',
    photo: null,
    geoIpInfo:null,
    isTempUser: null,
    interestsTags: []
  })
  setSessionId(null)
}
