import loadChatHistory from './loadChatHistory'
import loadDialogs from './loadDialogs'
import sendChatMessage from './sendChatMessage'
import selectRecipient from './selectRecipient'
import updateInputText from './updateInputText'
import inputKeyPress from './inputKeyPress'
import banMsg from './banMsg'
import banRoomChat from './banRoomChat'
import loadComments from './loadComments'
import clearChat from './clearChat'
import resetInput from './resetInput'
import openMsgActions from './openMsgActions'
import setInputH from './setInputH'
import setCompanyNotificationReaded from './setCompanyNotificationReaded'
import updateUnreadComments from './updateUnreadComments'
import deleteComment from './deleteComment'
import loadOtherDialogs from './loadOtherDialogs'
import loadDialogMessages from './loadDialogMessages'
import dialogSendMediaClick from './dialogSendMediaClick'
import sendDialogMessageLocal from './sendDialogMessageLocal'

export default {
  loadDialogMessages,
  loadOtherDialogs,
  loadChatHistory,
  updateUnreadComments,
  loadDialogs,
  loadComments,
  banMsg,
  banRoomChat,
  clearChat,
  deleteComment,
  openMsgActions,
  sendChatMessage,
  selectRecipient,
  updateInputText,
  inputKeyPress,
  resetInput,
  setInputH,
  setCompanyNotificationReaded,
  dialogSendMediaClick,
  sendDialogMessageLocal
}
