import { state } from 'cerebral'
import { requestUsers } from '@/utils/userLoader'
import routeToRoom from './routeToRoom'
import { showSnackbar, routeToFactory } from '../factories'
import { postersRoute } from '@/utils/routerHelpers'
import isVip from '../../../utils/isVip'
import { getLocaleValue } from '../../../utils/localeUil'

export default [
  ({ get, path, props, store }) => {
    const isLoggedIn = get(state`auth.isLoggedIn`)
    const myUid = get(state`auth.uid`)
    // props.roomId = 'specialPublic_' + props.roomId
    const myCoins = get(state`auth.coins`)
    const brCost = get(state`app.paidRooms.${props.roomId}`)

    store.set('room.scrollToPosts', props.scrollToPosts)
    if ((props.allowPay && brCost <= myCoins) || isVip() || props.roomId === myUid) {
      store.set('room.allowedPaidStreamId', props.roomId)
    }
    // const privateShow = get(state`posters.privates.${props.roomId}`)
    if (!isLoggedIn) return path.notLoggedIn()

    // if (privateShow && myUid !== privateShow.viewer && myUid !== privateShow.model) {
    //   props.roomId = 'specialPublic_' + props.roomId
    //   // return path.modelInPrivateWithOtherUser()
    // }
    
    return path.default()
  }, {
    notLoggedIn: [
      routeToFactory(postersRoute)
    ],
    // modelInPrivateWithOtherUser: [
    //   // routeToFactory(postersRoute),
    //
    //   // showSnackbar(getLocaleValue('private_show_already_in_show'), 5000, 'error')
    // ],
    default: [
      routeToRoom,
      ({ props }) => ({ uids: [props.roomId] }),
      ({ store }) => store.set('room.roomEnterTime', Date.now()),
      requestUsers, {
        success: []
      },
    ]
  },
]
