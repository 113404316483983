import { state } from 'cerebral'

export default [
  ({ props, store, get }) => {
    const data = get(state`moderation.data`).map(item => {
      if (item.postId === props.postId) {
        item['status'] = props.status
        item['statusFromDb'] = props.status
      }
      return item
    })
    store.set('moderation.data', data)

    store.set('upload.moderPostStatus', props.status)

    const post = get(state`posts.postsData.${props.postId}`)
    post['status'] = props.status
    post['statusFromDb'] = props.status
    store.set(`posts.postsData.${props.postId}`, post)

    /*if (get(state`auth.uid`) === props.status) {
      store.set('upload.moderPostStatus', 0)
    } else {
      store.set('upload.moderPostStatus', props.status)
    }*/
  }
]
