import amplitude from 'amplitude-js'
import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import GAEvent, { sendAmplitudeEvent } from '../../../utils/GAEvent'
import config from '@/config'
const { ftCoreUrl } = config

export default [
  ({ store, get, props }) => {

    const free = get(state`auth.freeSendMedia`)

    fetch(`${ftCoreUrl}/api/v1/users/fee-incoming-media`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sid: getSessionId(),
        free: free
      })
    }).catch(e => console.warn('catch error', e))

    sendAmplitudeEvent('paid_dialog_media', {
      free:free
    })
  }
]
