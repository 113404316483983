import { state } from 'cerebral'
import config from '@/config'
import controller from '@/controller'
import { getSessionId } from '@/utils'
import { panelsOnPop } from '../../app/actions'
import { sendAmplitudeEvent } from '@/utils/GAEvent'

export default [
  async ({ store, get }) => {
    const  {
      vbApiUrl
    } = config

    const showSnackbar = controller.getSequence('app.showSnackbar')
    const usdExchangeRate = get(state`intl.settings.coinsToMoney`)

    let res = ''
    try {
      const fd = new URLSearchParams()
      fd.append('sid', getSessionId())
      const response = await fetch(`${vbApiUrl}/vb2/balance/coins_to_agency`, {
        method: 'POST',
        body: fd
      })
      res = await response.json()
    } catch (e) {
      console.log(e)
    }
    
    if (res.error) {
      showSnackbar({ 
        text: get(state`intl.content.charge_error`), 
        ms: 1500, 
        type: 'error' 
      })
    } else {
      const resUsd = ((get(state`auth.receivedCoins`) / usdExchangeRate * 100) / 100).toFixed(2)

      sendAmplitudeEvent('payout', {
        sum_usd: resUsd,
        coins: get(state`auth.receivedCoins`),
        //payout_id: 'res.payoutId',
        wallet: get(state`auth.referrer_userid`),
        type: 'agent'
      })

      const usdBalance = res.receivedCoins / usdExchangeRate

      store.set('auth.receivedCoins', res.receivedCoins)
      store.set('auth.purchasedCoins', res.purchasedCoins)
      store.set('auth.coins', res.purchasedCoins)
      store.set('auth.usdBalance', Math.trunc(usdBalance * 100) / 100)

      showSnackbar({ 
        text: get(state`intl.content.transaction_success`), 
        ms: 1500, 
        type: 'success' 
      })      
    }
  },
  panelsOnPop
]
