import { state } from 'cerebral'
import { getSessionId } from '@/utils'

import config from '@/config'

const {
  ftCoreUrl
} = config

export default [
  async ({ get, props, store }) => {
    console.log('@@@ post Del func', props)
    try {
      const response = await fetch(`${ftCoreUrl}/api/v1/posts-reports/${props.postId}`, {
        method: 'DELETE',
        body: new URLSearchParams({ 
          sid: getSessionId(),
         })
      })
      await response.json()

      const post = get(state`posts.postsData.${props.postId}`)
      post['reportReasons'] = null
      store.set(`posts.postsData.${props.postId}`, post)  
    } catch (e) {
      console.log('failed to fetch')
    }
  }
]
