import { set, when } from 'cerebral/factories'
import { state, props } from 'cerebral'
import routeTo from './routeTo'
import { prepareRoute } from '../actions/prepareRoute'
import { send } from '../../socket/actions'
import { isMe } from '../../../utils'

export default [
  // ({ get, path, props, store }) => {
  //   const privateShow = get(state`posters.privates.${props.roomId}`)
  //
  //   // if (privateShow && !isMe(privateShow.viewer) && !isMe(privateShow.model)) {
  //        props.roomId = 'specialPublic_' + props.roomId
  //     // return path.modelInPrivateWithOtherUser()
  //   // }
  // },
  set(state`shop.openReason`, props`reason`),
  prepareRoute,
  routeTo,
  when(state`room.proBroadcast`, state`app.route`, 
        (proBroadcast, route) => proBroadcast && route !== 'room'), {
    true: [
      set(state`room.proBroadcast`, false),
      set(state`room.streamInRoom`, null),
      () => ({ method: 'endBroadcast' }),
      send,
    ],
    false: []
  }
]
