import { state, props } from 'cerebral'
import { showSnackbar } from '../../app/factories/index'
import { authWithEmail } from '../actions/authWithEmail'
import getAuthInfo from '../../auth/signals/getAuthInfo'
import { set } from 'cerebral/factories'
import { uploadOneSignalId } from '@/utils/OneSignal'
import clearLoginFormErrors from './clearLoginFormErrors'

export default [
  ({ store, get, path }) => {
    //TODO: check data for validness
    //isValidForm(state`auth.loginForm`)
    return path.true()
  }, {
  true: [
    clearLoginFormErrors,
    authWithEmail, {
      success: [
        getAuthInfo,
        // ({ get }) => {
        //   const oneSignalUserId = get(state`app.oneSignalUserId`)
        //   if (oneSignalUserId) uploadOneSignalId()
        // }
      ],
      error: [
        ({ props, get }) => ({ error: get(state`intl.content.${props.error}`) || props.error }),
        showSnackbar(props`error`, 3000, 'error'),
        set(state`auth.isAuthComplete`, true)
      ]
    }
  ],
  false: [
    ({ store, get }) => {
      const loginForm = get(state`auth.loginForm`)
      if (!loginForm.username || !loginForm.username.length < 4)
        store.set('auth.loginFormErrors.username', get(state`intl.content.invalid_email`))
      if (!loginForm.password || loginForm.password.length < 6) 
        store.set('auth.loginFormErrors.password', get(state`intl.content.password_mistake`))
    },
  ]
}]
