import { state, props } from 'cerebral'
import { when } from 'cerebral/factories'
import { send } from '../../socket/actions'
import openShop from '../../app/signals/openShop'
import panelsOnPop from '../../app/signals/panelsOnPop'
import showSnackbar from '../../app/signals/showSnackbar'
import { getUser } from '@/utils/userLoader'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'

export default [
  when(state`auth.coins`, state`intl.settings.giftsSets`, props`setId`, 
            (coins, giftsSets, setId) => coins >= giftsSets[setId].fullPrice), {
    true: [
      ({ props }) => ({
        method: 'sendGiftSet',
        params: { setId: props.setId, toUid: props.to }
      }),
      send,
      ({ store, get, props }) => ({ 
        hideAll: true,
        text: get(state`intl.content.log_buy_gift_set`)
              .replace('%set%', get(state`intl.content.${props.setId}_set`))
              .replace('%to%', getUser(props.to).name), 
        type: 'success',
        additionalInfo: null
      }),
      panelsOnPop,
      showSnackbar,
      ({ store, get, props }) => {
        const cost = get(state`intl.settings.giftsSets`)[props.setId].fullPrice
        sendAmplitudeEvent('gifts', {
          counterparty_id: props.to,
          gift_type: 'set',
          gift_id: props.setId,
          age_rating: getUserAgeRating(props.to),
          sent_coins: cost
        })
      }
    ],
    false: [
      ({ store, get, props }) => {
        const coins = +get(state`auth.coins`)
        const giftsSets = get(state`intl.settings.giftsSets`)
        const message = get(state`intl.content.need_more_coins`)
        return {
          message: message.replace(/\{0\}/gi, giftsSets[props.setId].fullPrice - coins),
          reason: 'buy-gift-set'
        }
      },
      openShop,
    ]
  }
]
