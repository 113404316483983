import { checkMySubscription, LEVEL_0 } from '../../../utils/subscriptionUtil'
import { isMe } from '../../../utils'
import { callSequence, getSequence } from '../../../utils/StoreManager'
import { isNotificationEnable } from '../../../utils/my'
import { checkNotification } from './notificationUtil'

export function subscribeOnLike(uid) {
  //если это я или уже подписан то ничего не делаем
  if (!uid || isMe(uid) || checkMySubscription(uid)){
    return
  }

  checkNotification(uid)
  callSequence('app.addSub', { uid: uid, type: LEVEL_0, duration: 'MONTH', like:true })
}

