import { state } from 'cerebral'
import controller from '@/controller'
import uploadFile from './uploadFile'
import { getSessionId } from '@/utils'
import { prepareFile, getOrientationDegree } from '@/utils/selectPhoto'

import config from '@/config'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { sendDialogMessageReq } from '../../chat/actions/sendDialogMessage'
const { postUploadUrl } = config

import { MESSAGE_TYPE_MEDIA } from '@/utils/manyConst'
import { checkUploadFile, getUploadFileHash, saveUploadFile } from '../uploadCash'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { checkFreeSendMedia } from '../../chat/actions/dialogSendMedia'
import { sendForNeuralCheck } from '../../../utils/api/sendForNeuralCheck'
import { showSnackbarNew } from '../../app/factories'
import { getLocaleValue } from '../../../utils/localeUil'

export default context => {
  const { props, store, get } = context
  const fileData = props.fileData
  const uid = props.uid
  const cost = props.cost
  const selectedFileUrl = props.selectedFileUrl
  const dialog_id = props.dialog_id
  const file = fileData.selectedFile
  const orientation = fileData.selectedPhotoOrientation

  const hash = getUploadFileHash(file.size, selectedFileUrl)
  const fileId = checkUploadFile(hash)

  function sendMessage(fileId) {
    let message = {
      message:fileId,
      message_type:MESSAGE_TYPE_MEDIA
    }

    if (cost){
      message.cost = cost
    }

    sendDialogMessageReq(message, dialog_id,  uid)
  }

  if (fileId) {
    const progress = getState('upload.uploadProgressFile') || {}
    progress[getState('upload.selectedFilename')] = 100
    setStoreWrapper('upload.uploadProgressFile', progress)
    sendMessage(fileId)
    return
  }

  const settings = getState('intl.settings')

  uploadFile({ ... fileData, props: {
      url: postUploadUrl + '/api/v1/media-file/upload',
      selectedFilename: fileData.selectedFilename,
      body: {
        file,
        sid: getSessionId(),
        main_file: false,
        orientation: getOrientationDegree(orientation),
      },
      callback: result => {
        if (!result.result) { return }
        console.log('SENDED ' + result.result.original || result.result.screenshot)
        sendForNeuralCheck(result.result[1350] || result.result[600] || result.result.original, (state, data)=>{

          if (state === 'success' && (data.age_minor || data.adult)){
            showSnackbarNew(getLocaleValue('dialog_media_restricted'), 5000, 'error')
          }
          if (state !== 'success' || (!data.age_minor && !data.adult)){
            sendMessage(result.result.file_id)
            //аналитика успеха отправки
            sendAmplitudeEvent('dialog_media', {
              action:'send_success',
              paid_send:checkFreeSendMedia(uid) ? 0 : settings.dialogMediaSendCost,
              is_video:fileData.mediaTypeVideo,
              cost:cost,
              age_minor: state !== 'success' ? 'error' : false
            })

            saveUploadFile(hash, result.result.file_id)
          }
          sendAmplitudeEvent('neural_check',
            {
              status: state,
              type: 'dialog',
              all_good: true,
              gender: data.gender,
              age_minor: data.age_minor
            })
        })
      },
      onError: () => {
        //аналитика ошибки отправки
        sendAmplitudeEvent('dialog_media', {
          action:'send_error',
          paid_send:checkFreeSendMedia(uid) ? 0 : settings.dialogMediaSendCost,
          is_video:fileData.mediaTypeVideo,
          cost:cost
        })

        console.log('ERROR ')
        const showSnackbar = controller.getSequence('app.showSnackbar')
        showSnackbar({ text: get(state`intl.content.error_load_profile`), type: 'error' })
      }
    } })
}
