import { state } from 'cerebral'
import config from '@/config'

const { vbApiUrl } = config
import { publicTagsAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { imAdmin } from '../../../utils/adminUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'

export const loadTags = async (get, store, categoryIds) => {
  if (categoryIds.length === 0) return
  let params = `?ageRating=${publicTagsAgeRating}`
  params += '&categories=' + categoryIds.join()
  params += '&limit=9999'
  if (get(state`app.subject`)) {
    params += `&subject=${get(state`app.subject`)}`
  }
  try{
    const response = await fetch(
      `${vbApiUrl}/vb2/topTags/${get(state`intl.lang`)}` + params
    )
    if (!response.ok) { return }

    const result = await response.json()
    const catTags = get(state`upload.categoriesTags`)

    saveLikeTags(result)
    store.set('upload.categoriesTags', { ...catTags, ...result })
  } catch (e) {
    console.log('topTags error')
    console.log(e)
  }
}

function saveLikeTags(tagsInCats) {
  const tagsData = getState('intl.tagsData')
  Object.keys(tagsInCats).map(categoryID => {
    const tags = tagsInCats[categoryID].topTags
    tags.forEach(tagData => {
      if (!tagsData[tagData.tagId]) {
        tagsData[tagData.tagId] = { ...tagData, categories:[categoryID] }
      }
    })
  })
  setStoreWrapper('intl.tagsData', tagsData)
}

export default [
  async ({ get, store }) => {
    const postKind = get(state`upload.postKind`)

    const categoryIds = get(state`intl.postKinds.${postKind}.categoryIds`)
    if (!categoryIds || categoryIds.length === 0) {
      return
    }

    const postCategories = get(state`intl.postCategories`)
    const catTags = get(state`upload.categoriesTags`)

    const categoryIdsAllRatings = []
    const categoryIdsHighRatings = []
    categoryIds.forEach(categoryId => {
      const cat = postCategories[categoryId]
      if (!cat) return
      if (catTags[categoryId]) return
      if (cat.hideLowAgeTags)
        categoryIdsHighRatings.push(categoryId)
      else
        categoryIdsAllRatings.push(categoryId)
    })

    //const appRating = get(state`app.ageRating`)
    //const postRatings = get(state`intl.appSourceConfig.postRatings`) || []
    //const postRatingsNSFW = get(state`intl.appSourceConfig.postRatingsNSFW`) || []
    //const highRatings = [...postRatings, ...postRatingsNSFW].join()
    //const allRatings = [12, 14, 16, 18].filter(rate => rate <= appRating).join()
    //await loadTags(get, store, allRatings, categoryIdsAllRatings)
    //await loadTags(get, store, highRatings, categoryIdsHighRatings)

    await loadTags(get, store, categoryIdsAllRatings)
    await loadTags(get, store, categoryIdsHighRatings)

    if (imAdmin()) {
      if (!get(state`upload.categoriesTags.admins`)) {
        await loadTags(get, store, ['admins'])
      }
      if (!get(state`upload.categoriesTags.seo`)) {
        await loadTags(get, store, ['seo'])
      }
    }

    if (postKind === 'postProfile'){
      store.set('loadedCatsProfile', true)
    }
  }
]
