import { state } from 'cerebral'
import config from '@/config'
import controller from '@/controller'
import { getSessionId } from '@/utils'
import { panelsOnPop } from '../../app/actions'

export default [
  async ({ store, get }) => {
    const  {
      vbApiUrl
    } = config

    const showSnackbar = controller.getSequence('app.showSnackbar')
    const usdExchangeRate = get(state`intl.settings.coinsToMoney`)

    let res = ''
    try {
      const fd = new URLSearchParams()
      fd.append('sid', getSessionId())
      const response = await fetch(`${vbApiUrl}/vb2/balance/coins_to_dollars`, {
        method: 'POST',
        body: fd
      })
      res = await response.json()
    } catch (e) {
        console.log(e)
    }
    if (res.error) {
    showSnackbar({ 
      text: get(state`intl.content.charge_error`), 
      ms: 1500, 
      type: 'error' 
    })
    } else {
      const usdBalance = res.receivedCoins / usdExchangeRate

      store.set('auth.receivedCoins', res.receivedCoins)
      store.set('auth.purchasedCoins', res.purchasedCoins)
      store.set('auth.coins', res.purchasedCoins)
      store.set('auth.usdBalance', Math.trunc(usdBalance * 100) / 100)

      showSnackbar({ 
        text: get(state`intl.content.purchase_completed`), 
        ms: 1500, 
        type: 'success' 
      })
    }
  },
  panelsOnPop
]
