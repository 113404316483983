import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import GAEvent from '@/utils/GAEvent'
import controller from '@/controller'
import { getSessionId } from '@/utils'
import removeNulls from '@/utils/removeNulls'
import { postersRoute } from '@/utils/routerHelpers'

import config from '@/config'
import { deleteRemoteFile } from './cancelUpload'
import {
  postCreateErrorEvent,
  postCreateSuccessEvent
} from '../../../utils/amplitude/postEvents'
import { closeAllPanels } from '../../../components/Panels/createPanelUtil'
import { setStoreWrapper } from '../../../utils/StoreManager'
const { ftCoreUrl, postUploadUrl } = config

const onPostUploaded = ({ store, get, props }) => {
  if (!props.postTheme && !props.postProfile) {
    const showSnackbar = controller.getSequence('app.showSnackbar')
    if (props.resp && props.resp.post_id) {
      postCreateSuccessEvent(props)
      showSnackbar({ text: get(state`intl.content.post_uploaded`), type: 'success' })
    } else {
      postCreateErrorEvent(props)
      showSnackbar({ text: get(state`intl.content.error_try_again`), type: 'error' })
    }
  } else {
    if (props.resp){
      postCreateSuccessEvent(props)
    } else {
      postCreateErrorEvent(props)
    }
  }
  
  const myUid = get(state`auth.uid`)
  setTimeout(() => {
    store.unset(`posts.userPostsLoadedPages.${myUid}`)
    store.unset(`posts.userPosts.${myUid}`)
    store.unset(`posts.allUserPostsLoaded.${myUid}`)
    setStoreWrapper('updateDairy', myUid)
  }, 1500)

  store.set('upload.description', null)
  store.set('upload.title', null)
  store.set('upload.subscriptionType', null)
  store.set('upload.selectedTags', [])
  store.set('upload.selectedFile', null)
  store.set('upload.selectedFilename', null)
  store.set('upload.selectedFileUrl', null)
  store.set('upload.selectedPhotoOrientation', null)
  store.set('upload.mainFileId', null)
  store.set('upload.extraFiles', [])
  
  const selectedPostId = get(state`posts.selectedPostId`)
  const postOwnerUid = selectedPostId ? get(state`posts.postsData.${selectedPostId}.uid`) : null
  if (selectedPostId) {
    store.unset(`posts.postsData.${selectedPostId}`)
    const getPost = controller.getSequence('posts.getPost')
    getPost({ postId: selectedPostId, isSpecial:true })
  }
  
  if (postOwnerUid && postOwnerUid !== myUid) {
    const routeTo = controller.getSequence('app.prepareAndRouteTo')
    routeTo({ url: postersRoute })
  } else {
    if (!props.postProfile){
      const routeToRoom = controller.getSequence('app.routeToRoom')
      routeToRoom({ roomId: myUid })
    }
  }
  
  const resp = props.resp
  closeAllPanels()
  // setTimeout(()=>{
  //   setStoreWrapper('updateDairy', myUid)
  // }, 1000)
  return { post_id: resp.post_id }
}

export const checkExtraFiles = (extraFiles, description) => {
  const usedExtraFiles = []
  const unUsedExtraFiles = []

  extraFiles.forEach(({ file_id, original }) => {
    if (description.indexOf(original) !== -1) {
      usedExtraFiles.push(file_id)
    } else {
      unUsedExtraFiles.push(file_id)
    }
  })

  unUsedExtraFiles.forEach(file_id => deleteRemoteFile(file_id))
  return usedExtraFiles
}

export default [
  set(state`upload.uploadProgress`, 999),
  removeNulls,
  async ({ get, props }) => {
    const extraFiles = get(state`upload.extraFiles`)
    const creationData = {
      sid: getSessionId(),
      main_file_id: get(state`upload.mainFileId`),
      title: props.title,
      ...props
    }
    if (extraFiles.length > 0) {
      creationData.extra_files_ids = checkExtraFiles(extraFiles, get(state`upload.description`) || '')
    }
    if (props.postType === 1) {
      creationData.cover_time = get(state`upload.coverTime`)
    }

    if (props.postType === 1) {
      const mainFileId = get(state`upload.mainFileId`)
      if (mainFileId) {
        await fetch(`${postUploadUrl}/api/v1/media-file/create-screenshot`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            cover_time: get(state`upload.coverTime`),
            file_id: mainFileId,
            sid: getSessionId()
          })
        })
      }
    }

    const response = await fetch(`${ftCoreUrl}/api/v1/posts/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(creationData)
    })
    return { resp: await response.json(), creationData }
  },
  onPostUploaded,
  ({ props }) => GAEvent('users_content', 'send', props.postType === 0 ? 'photo_free' : 'video_free'),
  set(state`upload.uploadProgress`, -1),
]
