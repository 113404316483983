import { state, props } from 'cerebral'
import { when } from 'cerebral/factories'
import { send } from '../../socket/actions'
import openShop from '../../app/signals/openShop'
import panelsOnPop from '../../app/signals/panelsOnPop'

export default [
  when(state`auth.coins`, props`cost`, (coins, cost) => coins >= cost), {
    true: [
      ({ store, get, props }) => ({
        method: 'paidActivity',
        params: {
          modelUid: get(state`app.roomId`),
          item: props.item
        }
      }),
      send,
      panelsOnPop,
    ],
    false: [
      ({ store, get, props }) => {
        const coins = +get(state`auth.coins`)
        const message = get(state`intl.content.need_more_coins`)
        return {
          message: message.replace(/\{0\}/gi, props.cost - coins),
          reason: 'click-theme-activity'
        };
      },
      openShop,
    ]
  }
]
