import { getState } from '../StoreManager'
import { isUID } from '../isUID'
import { isMe } from '../checks'

export function getCurrentRoomId() {
  return getState('app.roomId')
}

export function checkCurrentRoomId(roomId) {
  if (!roomId){
    return false
  }
 return getCurrentRoomId() === roomId
}

export function getRoomOwnerUid(roomId) {
  if (!roomId){
    roomId = getCurrentRoomId()
  }
  if (!roomId){
    return null
  }
  if (isUID(roomId)){
    return roomId
  }

  if (roomId === 'main'){
    return null
  }
  return roomId.split('_')[1]
}

export function isMyRoom(){
  // return true
 return isMe(getRoomOwnerUid(getCurrentRoomId()))
}
