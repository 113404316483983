import oneSignalInited from './setOneSignalInited'
import saveOneSignalUserId from './saveOneSignalUserId'
import setNotificationsButtonMode from './setNotificationsButtonMode'
import { selectPhoto } from '../../../utils/selectPhoto'
import uploadExtraFile from '../../upload/actions/uploadExtraFile'

export const messageTypes = {
  oneSignalInited: 'oneSignalInited',
  isPushEnabled: 'isPushEnabled',
  saveOneSignalUserId: 'saveOneSignalUserId',
  setNotificationsButtonMode: 'setNotificationsButtonMode',
  requestQuery: 'requestQuery',
  editorMessage: 'editorMessage',
  editorAddImage: 'editorAddImage'
}

export default [
  ({ props, path }) => {
    const { data } = props
    switch (data.type) {
        case messageTypes.oneSignalInited:
          return path.oneSignalInited()
        case messageTypes.saveOneSignalUserId:
          return path.saveOneSignalUserId({ userId: data.payload })
        case messageTypes.requestQuery:
          return path.requestQuery({ query: data.payload })
        case messageTypes.setNotificationsButtonMode:
          return path.requestQuery({ query: data.payload })
        case messageTypes.editorMessage:
          return path.editorMessage({ text: data.markdown })
        case messageTypes.editorAddImage:
          return path.editorAddImage()
        default:
          return path.default({ data })
    }
  }, {
    doNothing: [],
    editorMessage: [
      ({ props, store }) => store.set('upload.description', props.text)
    ],
    editorAddImage: [
      context => selectPhoto(photo => uploadExtraFile({ ...photo, ...context }))
    ],
    oneSignalInited: oneSignalInited,
    saveOneSignalUserId: saveOneSignalUserId,
    setNotificationsButtonMode: setNotificationsButtonMode,
    requestQuery: ({ props, store, get }) => {
      const query = props.query
      const value = get(query)
      window.parent.postMessage(
        JSON.stringify(
          { type: 'returnQuery', payload: { query, value } }
        ),
        '*'
      )
    },
    default: ({ props }) => {
      console.log('unknown parent message: ', props.data)
    }
  }
]
