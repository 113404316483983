import { getState } from '../StoreManager'
import { getPostData } from '../postUtils'

export function isTagFromCategory(tagId, category) {
  if (!tagId || !category){
    return false
  }

  const td = getState('intl.tagsData')
  if (td[tagId] && td[tagId].categories){
    return td[tagId].categories.includes(category)
  }

  return false
}

export function getTagCategories(tagId) {
  const td = getState('intl.tagsData')
  if (td[tagId] && td[tagId].categories){
    return td[tagId].categories
  }
  return []
}

export function getCategories(postType) {
  const allTagsCategory = getState('intl.postKinds')
  if (allTagsCategory && allTagsCategory[postType]){
    return allTagsCategory[postType]
  }

  return []
}

