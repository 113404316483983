import editPost from './editPost'
import editPostPanel from './editPostPanel'
import setFile from './setFile'
import setTitle from './setTitle'
import setDescr from './setDescr'
import deletePost from './deletePost'
import selectCover from './selectCover'
import cancelUpload from './cancelUpload'
import selectPostKind from './selectPostKind'
import selectPostKindModer from './selectPostKindModer'
import selectPostTag from './selectPostTag'
import uploadFile from './uploadFile'
import createPost from './createPost'
import createPostProfile from './createPostProfile'
import createPostTheme from './createPostTheme'
import setSizes from './setSizes'
import setCoverTime from './setCoverTime'
import setModerPostStatus from './setModerPostStatus'
import setModerPostTag from './setModerPostTag'
import clearPhotoUrl from './clearPhotoUrl'

export default {
  setSizes,
  setCoverTime,
  cancelUpload,
  selectPostKind,
  selectPostKindModer,
  setModerPostStatus,
  setModerPostTag,
  selectPostTag,
  uploadFile,
  createPost,
  createPostProfile,
  createPostTheme,
  deletePost,
  editPost,
  editPostPanel,
  setTitle,
  setDescr,
  setFile,
  selectCover,
  clearPhotoUrl
}
