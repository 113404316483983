import config from '@/config'
import { getSessionId } from '@/utils'
import { addBuff, delBuff } from '../actions'

const { vbApiUrl, ftCoreUrl } = config

export default [
  ({ props, path }) => {
    const buff = {
      toUid: props.uid,
      cardType: props.buffId,
      startTime: Date.now()
    }
    if (props.method === 'DELETE') {
      return path.delete({ buff })
    } else {
      return path.add({ buff })
    }
  }, {
    delete: delBuff,
    add: addBuff
  },
  ({ props }) => {
    fetch(`${vbApiUrl}/vb2/buffs/admin`, {
      method: props.method,
      body: new URLSearchParams({
        sid: getSessionId(),
        uid: props.uid,
        buffId: props.buffId,
      })
    }).catch(e => console.warn('catch error', e))
    fetch(`${ftCoreUrl}/api/v1/broadcasts/preview`, {
      method: 'POST',
      body: new URLSearchParams({
        sid: getSessionId(),
        uid: props.uid,
      })
    }).catch(e => console.warn('catch error', e))
  }
]
