import { getState, setStoreWrapper } from '../utils/StoreManager'

const pages = {}
const status = {}
const cash = {}

const ONE_PAGE_ITEMS = 30

export async function loadGET(type, url, callback, keyResponse) {
  if (status[type]){
    if (callback){
      callback(type, status[type])
    }
    return
  }

  status[type] = true

  let page = pages[type] || 0
  try {
    url += '?page=' + page

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    delete status[type]
    if (!response.ok) {
      if (callback){
        callback(type, 'error')
      }
      return
    }

    page++
    pages[type] = page
    let result = await response.json()
    if (!result[keyResponse]){
      if (callback){
        callback(type, 'error')
      }
      return
    }

    result = result[keyResponse]

    if (result.length < ONE_PAGE_ITEMS){
      status[type] = 'allLoaded'
    }

    let allResp = cash[type] || []
    allResp = allResp.concat(result)

    cash[type] = allResp

    if (callback){
      callback(type, 'success')
    }
  } catch (e) {
    delete status[type]
    if (callback){
      callback(type, 'error')
    }
  }
}

export function clearGETCash(type) {
  delete pages[type]
  delete cash[type]
  delete status[type]
}

export function getGETCash(type) {
  return cash[type]
}
