import { state } from 'cerebral'
import controller from '@/controller'
//import { handleRoute } from './routed'
//import { dispatchUrlChangeToParent } from '../actions'
import { PROFILE_TAG, STREAM_THEME_TAG } from '@/utils/postUtils'

export default [
  ({ store, get, props }) => {
    const postNum = parseInt(props.postNum)
    const selectKind = controller.getSequence('upload.selectPostKindModer')
    if (!postNum) {
      if (props.isProfile){
        store.set('upload.postKind', 'postProfile')
        selectKind({ postKind: 'postProfile' })
      } else {
        store.set('upload.postKind', 'videochat_photo')
        selectKind({ postKind: 'videochat_photo' })
      }
      return
    }

    //const getPost = controller.getSequence('posts.getPost')
    //getPost({ postId: postNum })

    store.set('posts.selectedPostId', parseInt(postNum))

    const postData = get(state`posts.postsData.${postNum}`)
    if (!postData) {
      if (props.isProfile){
        store.set('upload.postKind', 'postProfile')
      } else {
        store.set('upload.postKind', 'videochat_photo')
      }
      return 
    }

    const postStatus = /*get(state`upload.moderPostStatus`) > 0 ? get(state`upload.moderPostStatus`) :*/ postData.statusFromDb

    store.set('upload.postKind', postData.postKind)
    store.set('upload.selectedFileUrl', postData.original)
    store.set('upload.title', postData.originalTitle)
    store.set('upload.description', postData.markdownDescOriginal || postData.originalDesc)
    store.set('upload.moderPostStatus', postStatus)
    store.set('upload.selectedTags', postData.tags || [])
    store.set('upload.subscriptionType', postData.subscriptionType)
    store.set('upload.photoUrl', postData.photoUrl)

    let kind = postData.postKind
    if (!kind) {
      if (postData.tags && postData.tags.indexOf(STREAM_THEME_TAG) !== -1) {
        kind = 'postTheme'
      } else if (postData.tags && postData.tags.indexOf(PROFILE_TAG) !== -1) {
        kind = 'postProfile'
      } else {
        kind = postData.postType === 1 ? 'video' : 'photo'
      }
    }

    selectKind({ postKind: kind })
  }
]
