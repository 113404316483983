import { when } from 'cerebral/factories'
import { state } from 'cerebral'
import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'

/*export default [
  when(state`auth.admin`, status => status === true || state`auth.superAdmin`, status => status === true), {
    true: [() => ({ route: 'contentModeration' })],
    false: [() => ({ route: 'home' })]
  },  
  dispatchUrlChangeToParent,
  handleRoute
]*/

export default [
  () => ({ route: 'contentModeration' }),  
  dispatchUrlChangeToParent,
  handleRoute
]
