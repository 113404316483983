import controller from '@/controller'
import {
  messagesRoute
} from '@/utils/routerHelpers'
import { setStoreWrapper } from '../../../utils/StoreManager'

export const SUPPORT_UID = '786cb107854c02c23bd4d7b971e05c74'

export default [
  ()=>{
    setStoreWrapper('forceDialogID', null)
    controller.getSequence('app.prepareAndRouteTo')({ url: messagesRoute, urlValue:SUPPORT_UID  })
  }
]

