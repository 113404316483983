import { state } from 'cerebral'
import { onVideoCallClick } from '../../../utils/amplitudeLiveEvents'

export const checkForVideoPrivateAvailability = ({ get, path, props }) => {
  if (!props.withVideo) {
    return path.available()
  } else {
    onVideoCallClick()
  }
  if (navigator.mediaDevices){
    return path.available()
  } else {
    return path.noVideoDevicesOrBisy()
  }
  // const videoDevices = get(state`room.videoDevices`) || []
  //
  // if (videoDevices.length === 0) return path.noVideoDevicesOrBisy()
  // return path.available()
}
