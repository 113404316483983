import { state, props } from 'cerebral'
import { showSnackbar, prepareSnackbarTitle } from '../../app/factories'
import { when, wait, set } from 'cerebral/factories'
import { panelsOnPop } from '../../app/actions'
import { getCookie, getSessionId } from '@/utils'
import config from '@/config'
import { getParentDomain } from '../../../utils/urlUtils'

const { httpApiUrl } = config

export default [
  async ({ get, path }) => {
    const { email, password } = get(state`auth.changeEmailForm`)
    
    const fd = new FormData()
    fd.append('sid', getSessionId())
    fd.append('email', email)

    const referrer = get(state`auth.partnerId`) || 
                     get(state`auth.fromParam`) || 
                     getCookie('mrtigra.refer') || 
                     get(state`auth.mrtigraRefer`)

    if (referrer) {
      fd.append('from', referrer)
    }
    
    if (get(state`auth.isPasswordSet`)) {
      fd.append('old_password', password)
    } else {
      fd.append('password', password)
    }
    fd.append('domain', getParentDomain())
    const response = await fetch(httpApiUrl + '/auth/change_email', {
      method: 'POST',
      body: fd
    })

    if (response.ok) {
      const { result, error } = await response.json()
      return path.success({ result, error })
    }
    return path.error()
  }, {
    success: [
      when(props`result`), {
        true: [
          set(state`auth.email`, state`auth.changeEmailForm.email`),
          panelsOnPop,
          wait(300),
          panelsOnPop,
          prepareSnackbarTitle('intl.content.ce_sended', 'auth.changeEmailForm.email'),
          showSnackbar(props`snackbarTitle`, 30000, 'mail'),
          set(state`auth.changeEmailForm`, { email: '', password: '' }),
          set(state`auth.changePasswordForm`, { oldPassword: '', password: '' }),
        ],
        false: showSnackbar(props`error`, 3000, 'error')
    }],
    error: showSnackbar(state`intl.content.connection_faild`, 3000, 'error')
  }
]
