import { set } from 'cerebral/factories'
import { state } from 'cerebral'
import { send } from '../../socket/actions'
import canPeep from '../../room/actions/canPeep'
import { getActivityData } from '@/utils/themeUtils'
import openShop from '../../app/signals/openShop'

export default [
  canPeep,{
    yes: [
      ({ store, get }) => ({
        method: 'paidActivity',
        params: { 
          modelUid: get(state`room.peepUid`),
          item: get(state`room.peepActivityId`),
          peep: true
        }
      }),
      send,
      set(state`room.peepTimeLeft`, 0)
    ],
    no: [
      ({ store, get }) => {
        const act = getActivityData(get(state`room.peepActivityId`))
        const coins = get(state`auth.coins`)
        const message = get(state`intl.content.need_more_coins`)
        
        return {
          message: message.replace('{0}', act.cost - coins),
          reason: 'click-peep-activity'
        }
      },
      openShop
    ]
  }
]
