import signals from './signals'
import { 
  BITCOIN, 
  YANDEX, 
  CARD_RUSSIA, 
  CARD,
  SEPA,
  BITSAFE, 
  INVOICE, 
  SWIFT, 
  ACH, 
  QIWI, 
  CT, 
  USDT, 
  PYONEER, 
  ADVCASHE,
  PAXUM,
  PAYTM,
  ALIPAY,
  IMPS
} from '@/config'
import { GCASH, GRABPAY, PAGBANK, PAYMAYA } from '../../config'

export default {
  state: {
    pymentTypeOrder: [
      // INVOICE,
      // BITCOIN,
      YANDEX, 
      CARD_RUSSIA, 
      // CARD,
      SEPA, 
      BITSAFE, 
      QIWI,  
      SWIFT, 
      CT,
      ACH, 
      USDT,
      // PYONEER,
      ADVCASHE,
      PAXUM,
      // ALIPAY
      PAYTM,
      IMPS,
      PAGBANK,
      GCASH,
      GRABPAY,
      PAYMAYA
    ],
    pymentDataUser:{
      pymentTypeUser:{}
    },
    purashedVipCoinsLoading: false,
  },
  sequences: signals
}
