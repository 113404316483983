import { state } from 'cerebral'
import controller from '@/controller'
import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { PROFILE_TAG, STREAM_THEME_TAG } from '@/utils/postUtils'

export default [
  () => ({ route: 'postModeration' }),
  ({ store, get, props }) => {
    const postNum = parseInt(props.routeArgs[0])
    if (!postNum) {
      return
    }

    store.set('posts.selectedPostId', parseInt(postNum))
    const selectKind = controller.getSequence('upload.selectPostKind')
    
    const postData = get(state`posts.postsData.${postNum}`)
    if (!postData) {
      return 
    }

    store.set('upload.postKind', postData.postKind)
    store.set('upload.selectedFileUrl', postData.original)
    store.set('upload.title', postData.originalTitle)
    store.set('upload.description', postData.markdownDescOriginal || postData.originalDesc)
    store.set('upload.moderPostStatus', postData.statusFromDb)
    store.set('upload.selectedTags', postData.tags || [])
    store.set('upload.subscriptionType', postData.subscriptionType)
    
    let kind = postData.postKind
    if (!kind) {
      if (postData.tags && postData.tags.indexOf(STREAM_THEME_TAG) !== -1) {
        kind = 'postTheme'
      } else if (postData.tags && postData.tags.indexOf(PROFILE_TAG) !== -1) {
        kind = 'postProfile'
      } else {
        kind = postData.postType === 1 ? 'video' : 'photo'
      }
    }

    selectKind({ postKind: kind })
  },
  dispatchUrlChangeToParent,
  handleRoute
]
