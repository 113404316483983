import { handleRoute } from './routed'
import { dispatchUrlChangeToParent } from '../actions'
import { setScreenInPosition } from '../../../utils/scrollRememberUtil'
import { state } from 'cerebral'
import { getState } from '../../../utils/StoreManager'

export default [
  () => ({ route: 'post' }),
  ({ store, get, props }) => {
    const postNum = props.routeArgs[0]
    store.set('posts.selectedPostId', parseInt(postNum))
  },
  dispatchUrlChangeToParent,
  handleRoute
]
