import {
  DESCRIPTION_REMOVED,
  EMAIL_TAG, FACE_DETECTED,
  FB_TAG,
  FEMALE, FIXED_POST,
  getPostData,
  HD,
  HETERO, INSTAGRAM_TAG,
  INTERESTING, IS_REAL,
  MALE, NO_FACE,
  PROFILE_TAG, SKYPE_TAG, SNAPCHAT_TAG,
  STREAM_THEME_TAG, TIK_TOK_TAG,
  VIDEOCHAT
} from './postUtils'
import { getUser } from './userLoader'
import { isMe } from './checks'
import { callSequence, getState, setStoreWrapper } from './StoreManager'
import { moderEditPanel } from '../components/Panels/types'
import { isProfileVisible } from './ageRating/ageRatingUtil'
import { postCreateClickEvent } from './amplitude/postEvents'
import { getTagCategories, isTagFromCategory } from './post/tagsUtil'
import { hideOrientation } from './user/ageUtil'
import { imAdmin } from './adminUtil'
import { getLocaleValue } from './localeUil'
import { checkOnline } from '../modules/socket/actions'
import { getDurationStr } from '@/utils'
import { getCashedUrl } from './cash/cashUtil'
import { getLanguageTag } from './user/getLanguageTag'
import { getUserMainCharacter } from './awards/getUserMainCharacter'
import { getAwardAvatarUrl } from './urlUtils'
import { isCDNDisable } from './appUtil'

// export function isFemale(uid) {
//   const user = getUser(uid)
//   if (user && user.profilePostTags && user.profilePostTags.indexOf(FEMALE) !== -1){
//     return true
//   }
//   return false
// }

export function getProfilePostData(uid) {
  const user = getUser(uid)
  if (user && user.profile_post){
    return user.profile_post
  }
  return null
}

export function getProfilePostId(uid) {
  const profileData = getProfilePostData(uid)
  if (profileData && profileData.postId){
    return profileData.postId
  }

  return null
}

export function getPostThemeId(uid) {
  const user = getUser(uid)

  if (user && user.storage && user.storage.metadata && user.storage.metadata.postThemeId){
    return user.storage.metadata.postThemeId
  }

  return null
}

export function profileClick(uid) {
  const profilePostId = getProfilePostId(uid)
  if (isMe(uid)){
    if (profilePostId){
      setStoreWrapper('goodAvatar', true)
    }
    setStoreWrapper('posts.selectedPostId', profilePostId)
    postCreateClickEvent(profilePostId)
    callSequence('moderation.postModerationOpen',{ postNum: profilePostId, isProfile:true })
    callSequence('app.createPanel',moderEditPanel(profilePostId, 740, true, false, false, true))
  } else {
    if (profilePostId){
      callSequence('posts.onPostClick',{ postId:profilePostId })
    }
  }
}

export function characterAvatar(uid) {
  const character = getUserMainCharacter(uid)
  if (!character){
    return null
  }

  return getAwardAvatarUrl(character)
}

export function getProfileAvatar(uid) {
  if (!isProfileVisible(uid)){
    return characterAvatar(uid)
  }
  const profileData = getProfilePostData(uid)
  if (profileData && profileData.avatar){
    return checkCDN(profileData.avatar)
  }
  return characterAvatar(uid)
}

function checkCDN(url){
  if(isCDNDisable() && url.includes('photo.faminta1')){
    return url.replace('photo.faminta1', 'photo-proxy.faminta1')
  }
  return url
}

export function getProfilePic(uid) {
  if (!isProfileVisible(uid)){
    return null
  }

  const profileData = getProfilePostData(uid)
  if (profileData){
    return profileData.original || profileData.middle || profileData.avatar
  }
  return null
}

export function getLoadedPreviewPost(postId) {
  return getCashedUrl(postId)
}

export function getLoadedPreviewProfile(uid, postId) {
  if (postId){
    const cashedPic = getCashedUrl(postId) || getCashedUrl(uid)
    if (cashedPic){
      return cashedPic
    }
  }

  if (!isProfileVisible(uid)){
    return null
  }

  const profileData = getProfilePostData(uid)
  if (profileData){
    return profileData.middle || profileData.avatar
  }
  return null
}

export function isVerified(uid) {
  const profileId = getProfilePostId(uid)
  if (profileId){
    const pd = getPostData(profileId)
    if (pd && pd.tags && pd.tags.includes(IS_REAL)){
      return true
    }
  }
  return false
}

export function getProfileSizeData(uid) {
  const profileData = getProfilePostData(uid)
  if (!profileData){
    return { originalWidth:640, originalHeight:480 }
  }
  return { originalWidth:profileData.originalWidth, originalHeight: profileData.originalHeight }
}

  // [
  // "people",
  //   "country",
  //   "videochat_questionnaire_purpose",
  //   "sexual_orientation",
  //   "profile_housing",
  //   "profile_kids",
  //   "profile_education",
  //   "profile_religion",
  //   "profile_smoking",
  //   "profile_height",
  //   "videochat_questionnaire_interests"
  // ]
const PROFILE_HEIGHT = 'profile_height'
export const SEXUAL_ORIENTATION = 'sexual_orientation'
const VIDEOCHAT_QUESTIONNAIRE_PURPOSE = 'videochat_questionnaire_purpose'
const PROFILE_HOUSING = 'profile_housing'
const PROFILE_KIDS = 'profile_kids'
const PROFILE_EDUCATION = 'profile_education'
const PROFILE_RELIGION = 'profile_religion'
const PROFILE_SMOKING = 'profile_smoking'
const AUTO_TAGS = 'autotags'
const VIDEOCHAT_QUESTIONNAIRE_INTERESTS = 'videochat_questionnaire_interests'
export const PROFILE_LANGUAGES = 'profile_languages'

export const GENDER_CATEGORY = 'people'
export const COUNTRY_CATEGORY = 'country'


const USA_TAG = 1106081

export function getUserGEOCountry(uid, force, code) {
  if (isCountryHidden(uid) && !force){
    return null
  }
  const countries = getState('intl.countriesList')

  const user = getUser(uid)
  if (!(user && user.geoIpInfo && user.geoIpInfo.country)){
    return null
  }

  if (code){
    return user.geoIpInfo.country.toLowerCase()
  }
  return countries && countries[user.geoIpInfo.country.toLowerCase()]
}

export function getUserCountry(uid) {
 return getUserGEOCountry(uid)//getUserTagCountry(uid)//|| getUserGEOCountry(uid)
}

export function getUserTagCountry(uid) {
  // //пока отключим
  // return null

  if (isCountryHidden(uid)){
    return null
  }
  const profileId = getProfilePostId(uid)
  if (!profileId){
    return null
  }

  const categoriesTags =  getState('upload.categoriesTags')
  if (!categoriesTags || !categoriesTags[COUNTRY_CATEGORY] || !categoriesTags[COUNTRY_CATEGORY].topTags){
    return null
  }

  const pd = getPostData(profileId)
  if (!pd || !pd.tags){
    return null
  }

  const catTags = categoriesTags[COUNTRY_CATEGORY].topTags
  let countryName
  //проверим, есть ли уже страна
  catTags.forEach((tag) =>{
    if (pd.tags.includes(tag.tagId)){
      countryName = tag.title
      return
    }
  })

  if (!countryName){
    return null
  }

  return countryName
}

export function isCountryHidden(uid) {
  const user = getUser(uid)
  return user && user.storage && user.storage.hidden_location
}

export function getUserCity(uid, force) {
  if (!force && isCountryHidden(uid)){
    return null
  }

  const user = getUser(uid)
  return user && user.geoIpInfo && user.geoIpInfo.city
}

export function getUserLang(uid) {
  if (!uid){
    return 'en'
  }
  const user = getUser(uid)
  if (user && user.lang){
    return  user.lang
  }
  return 'en'
}

export function getUserProfileTags(uid, forRoom = false) {
  const profileId = getProfilePostId(uid)
  const userLangTag = getLanguageTag(getUserLang(uid))
  if (!profileId){
    return [userLangTag]
  }
  const postData = getPostData(profileId)
  if (!postData){
    return [userLangTag]
  }
  const tags = postData.tags
  if (!tags){
    return [userLangTag]
  }
  let filtered
  if (forRoom){
    //уберем лишние теги
    filtered = tags.filter(id => {
      return (
        id !== PROFILE_TAG &&
        id !== IS_REAL &&
        id !== FIXED_POST &&
        // id !== FEMALE &&
        id !== STREAM_THEME_TAG &&
        id !== HD &&
        // id !== HETERO &&
        id !== SKYPE_TAG &&
        id !== FB_TAG &&
        id !== INSTAGRAM_TAG &&
        id !== SNAPCHAT_TAG &&
        id !== TIK_TOK_TAG &&
        id !== EMAIL_TAG &&
        id !== VIDEOCHAT &&
        id !== DESCRIPTION_REMOVED &&
        !((isTagFromCategory(id, SEXUAL_ORIENTATION) && hideOrientation())) &&
        !(isTagFromCategory(id, AUTO_TAGS) && !imAdmin()) &&
        !isTagFromCategory(id, COUNTRY_CATEGORY) &&
        id !== INTERESTING)
    })
  } else {
    //уберем лишние теги
    filtered = tags.filter(id => {
      return (id !== PROFILE_TAG &&
        id !== MALE &&
        id !== FEMALE &&
        id !== STREAM_THEME_TAG &&
        id !== HD &&
        id !== FIXED_POST &&
        id !== HETERO &&
        id !== IS_REAL &&
        id !== SKYPE_TAG &&
        id !== FB_TAG &&
        id !== INSTAGRAM_TAG &&
        id !== SNAPCHAT_TAG &&
        id !== TIK_TOK_TAG &&
        id !== EMAIL_TAG &&
        id !== VIDEOCHAT &&
        id !== DESCRIPTION_REMOVED &&
        !(!imAdmin() && id === FACE_DETECTED) &&
        !(!imAdmin() && id === NO_FACE) &&
        !((isTagFromCategory(id, SEXUAL_ORIENTATION) && hideOrientation())) &&
        !isTagFromCategory(id, PROFILE_SMOKING) &&
        !isTagFromCategory(id, COUNTRY_CATEGORY) &&
        id !== INTERESTING)
    })
  }

  // if (!filtered.includes(userLangTag)){
  //   filtered.push(userLangTag)
  // }

  //Страна, ориентация, образование, религия, цель знакомства, дети, жильё, рост, интересы
  //порядок сортировки категорий тегов
  const sortRate = [COUNTRY_CATEGORY,
    PROFILE_LANGUAGES,
    SEXUAL_ORIENTATION,
    PROFILE_RELIGION,
    VIDEOCHAT_QUESTIONNAIRE_PURPOSE,
    PROFILE_EDUCATION,
    PROFILE_KIDS,
    PROFILE_HOUSING,
    PROFILE_HEIGHT,
    VIDEOCHAT_QUESTIONNAIRE_INTERESTS
  ]

  function getSortPoints(tagId) {
    const categories = getTagCategories(tagId)
    if(!categories.length){
      return -1
    }
    let minCategory = sortRate.length

    categories.forEach((category) => {
      if (category){
        let currentPosition = sortRate.indexOf(category)
        if (currentPosition !== -1){
          minCategory = Math.min(sortRate.indexOf(category), minCategory)
        }
      }
    })

    return sortRate.length - minCategory
  }

  return filtered.sort((a, b) =>{
    return getSortPoints(b) - getSortPoints(a)
  })
}

export function getProfileDescription(uid) {
  const postId = getProfilePostId(uid)
  if (!postId){
    return null
  }

  const postData = getPostData(postId)
  if (!postData){
    return  null
  }

  return postData.description || postData.originalDesc
}

export const ANONYMOUS = 'anonymous'
export function isAnonymously(uid) {
  return uid === ANONYMOUS
}

export function getUserName(uid) {
  const user = getUser(uid)
  return (user && user.name) || 'Guest'
}

export function getLastSeen(uid) {
  if(checkOnline(uid)){
    return getLocaleValue('online_status')
  }
  const user = getUser(uid)
  const lastSeen = (user && user.storage && user.storage.logOutTime) || 0

  if (lastSeen === 0){
    return getLocaleValue('last_seen', { '{time}':  getLocaleValue('last_visit_max_date') })
  }
  return getLocaleValue('last_seen', { '{time}': getDurationStr(Date.now() - lastSeen * 1000, getState('intl.content')) })
}

export function getUserRating(uid) {
  const user = getUser(uid)
  if (!user || !user.rating){
    return 0
  }
  return user.rating.toFixed(1)
}

export function getUserPercentRating(uid) {
  return getUserRating(uid).split('.')[1].charAt(0)*10
}

export function isTopRated(uid) {
  return getUserRating(uid) >= 5
}

