import controller from '@/controller'
import { getState, setStoreWrapper } from './StoreManager'
import { checkPhotoSize } from './post/checkPhotoSize'

const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024//в байтах

export const selectPhoto = callback => {
  const onGetPhoto = ({ file, url }) => {
    getOrientation(file, orientation => callback({ file, url, orientation }))
  }

  selectFile('.png, .jpg, .gif, .webp, .jpeg', onGetPhoto)
}

export default selectPhoto

export const selectVideo = callback => {
  selectFile('.mp4, .mov, .avi, .mkv, video/x-m4v, video/*;capture=camera', callback)
}

export const prepareFile = (file, callback) => {
  const url = URL.createObjectURL(file)

  if (file.size > MAX_FILE_SIZE) {
    const showSnackbar = controller.getSequence('app.showSnackbar')
    showSnackbar({ text: controller.getState('intl.content.file_too_big'), type: 'error' })
    return
  }

  if(getState('postCreationPanel') && file.type.indexOf('image') === 0){
    //проверим размер картинки по URL
    checkPhotoSize(url, () => {
      callback({ file, url, filename: file.name })
    })
    return
  }

  callback({ file, url, filename: file.name })
}

function selectFile(accept, callback) {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = accept
  input.style = 'display:none'
  
  const onFileSelected = event => {
    document.body.removeChild(input)
    input.removeEventListener('change', onFileSelected)
    if (!event.target.files || !event.target.files[0]) {
      return
    }
    prepareFile(event.target.files[0], callback)
  }
  
  document.body.appendChild(input)
  input.addEventListener('change', onFileSelected, false)
  input.click()
}
  
export const getOrientation = (file, callback) => {
  const reader = new FileReader()

  reader.onload = function(event) {
    const view = new DataView(event.target.result)

    if (view.getUint16(0, false) !== 0xFFD8) return callback(-2)

    const length = view.byteLength
    let offset = 2

    while (offset < length) {
      const marker = view.getUint16(offset, false)
      offset += 2

      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) {
          return callback(-1)
        }
        const little = view.getUint16(offset += 6, false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        const tags = view.getUint16(offset, little)
        offset += 2

        for (let i = 0; i < tags; i++)
          if (view.getUint16(offset + (i * 12), little) === 0x0112)
            return callback(view.getUint16(offset + (i * 12) + 8, little))
      }
      else if ((marker & 0xFF00) !== 0xFF00) break
      else offset += view.getUint16(offset, false)
    }
    return callback(-1)
  }

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
}

export const getOrientationDegree = orientation => {
  switch (orientation) {
    case 3:
    case 4:
      return 180
    case 5:
    case 6:
      return 90
    case 7:
    case 8:
      return 270
  }
  return 0
}

export const onUploadFileClick = callback => {
  selectFile('.png, .jpg, .jpeg, .mp4, .mov, .avi, .mkv, .gif, .webm, .webp, video/x-m4v, video/*;capture=camera', callback)
}

export const onUploadPhotoClick = callback => {
  setStoreWrapper('isAvaSet', false)
  selectFile('.png, .jpg, .gif, .webp, .jpeg', callback)
}
