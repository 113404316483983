import { wait } from 'cerebral/factories'
import panelsOnPop from './panelsOnPop'
import { createPanel } from '../actions'
import { changeSubscriptionPanel } from '../../../components/Panels/types'

export default [
  /*panelsOnPop,
  wait(500),*/
  ({ props }) => changeSubscriptionPanel(props.id),
  createPanel
]
