import { state } from 'cerebral'
import controller from '@/controller'
import uploadFile from './uploadFile'
import { getSessionId } from '@/utils'
import { prepareFile, getOrientationDegree } from '@/utils/selectPhoto'

import config from '@/config'
const { postUploadUrl } = config

const setExtraFile = context => {
  const { file, orientation, store, get } = context
  
  uploadFile({ ... context, props: {
    url: postUploadUrl + '/api/v1/media-file/upload',
    body: {
      file,
      sid: getSessionId(),
      main_file: false,
      orientation: getOrientationDegree(orientation),
    },
    callback: result => {
      if (!result.result) { return }
      let descr = get(state`upload.description`) || ''
      descr += `![image](${result.result.original})`
      store.set('upload.description', descr)

      const extraFiles = get(state`upload.extraFiles`) || []
      extraFiles.push(result.result)
      store.set('upload.extraFiles', extraFiles)
    },
    onError: () => {
      const showSnackbar = controller.getSequence('app.showSnackbar')
      showSnackbar({ text: get(state`intl.content.error_load_profile`), type: 'error' })
    }
  } })
}

export default context => {
  const { file, orientation } = context
  if (!file) { return }
  
  prepareFile(
    file, 
    fileInfo => setExtraFile({ ...fileInfo, ...context, orientation })
  )
}
