import { state } from 'cerebral'

export default ({ props, store, get }) => {
  const res = []  

  const users = get(state`app.users`)

  if (props.uid && users[props.uid]) {
    const user = users[props.uid]
    if (user.model > 0) {
      res.push(user)
    }
  } 
  if (props.name) {
    for (const uid in users) {
      if (users[uid].model > 0) {
        const modelName = users[uid].name.toLowerCase()
        if (modelName.indexOf(props.name.toLowerCase()) !== -1) {
          res.push(users[uid])
        }
      }
    }
  }

  return res
}
