import is from 'is_js'
import authSuccess from '../../auth/actions/authSuccess'

export const onmessage = async ({ store, get, props, path }) => {
  let json
  try {
    json = await JSON.parse(props.data)
  } catch (e) {
    json = props.data
  }
  const { method, params } = json

  if (method === 'onConnect') {
    return path.onConnect({ params })
  } else if (method === 'authSuccess') {
    if (params.ban) {
      return path.onBan(params)
    }
    
    const fwdProps = await authSuccess({ store, get, props, params })
    
    return path.authSuccess(fwdProps)
  } else if (method === 'authUnsuccess') {
    return path.authUnsuccess({ params })
  } else if (method === 'updateOnline') {
    const { online, totalCount } = params
    return path.updateOnline({ totalCount, online })
  } else if (method === 'broadcastsSpecialData') {
    return path.broadcastsSpecialData({ params })
  } else if (method === 'loginnedFromAnotherPlace') {
    return path.loginnedFromAnotherPlace()
  } else if (method === 'chatMessage') {
    return path.chatMessage({ roomId:params.roomId, msgData: params.message })
  } else if (method === 'roomEnterDisable') {
    return path.roomEnterDisable({ reason: params.reason })
  } else if (method === 'transaction') {
    return path.transaction({ params })
  } else if (method === 'privateCall') {
    return path.privateCall({ params })
  } else if (method === 'removeMessage') {
    return path.removeMessage({ messageId: params })
  } else if (method === 'roomHistory') {
    return path.roomHistory({ roomId: params.roomId, history: params.history })
  } else if (method === 'addBuff') {
    return path.addBuff({ buff: params })
  } else if (method === 'delBuff') {
    return path.delBuff({ buff: params })
  } else if (method === 'uncacheUser') {
    return path.uncacheUser({ uid:params.uid })
  } else if (method === 'createBroadcast') {
    return path.createBroadcast({ broadcast: params })
  } else if (method === 'endBroadcast') {
    return path.endBroadcast({ roomId: params.uid })
  } else if (method === 'privateMessage') {
    return path.privateMessage({ msgData: params })
  } else if (method === 'balanceChanged') {
    return path.balanceChanged({ data: params })    
  } else if (method === 'balanceChangedReceivedCoins') {
    return path.balanceChangedReceivedCoins({ params })
  } else if (method === 'balanceChangedPurchasedCoins') {
    return path.balanceChangedPurchasedCoins({ params })
  } else if (method === 'payoutError') {
    return path.payoutError({ data: params })
  } else if (method === 'payoutSuccess') {
    return path.payoutSuccess({ data: params })
  } else if (method === 'privateStartError') {
    return path.privateStartError({ data: params })
  } else if (method === 'directCallStart') {
    const { model, viewer, cost, withCam, themeId } = params
    return path.directCallStart({ model, viewer, cost, withCam, themeId })
  } else if (method === 'clearRoomHistory') {
    return path.clearRoomHistory({ roomId: params })
  } else if (method === 'deleteAllMessageFromUser') {
    return path.deleteAllMessageFromUser({ uid: params })
  } else if (path[method]) {
    return path[method]({ params })
  } else {
    return path.unknownMethod(json)
  }
}
