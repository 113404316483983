import { getUser } from '../userLoader'
import { getState, setStoreWrapper } from '../StoreManager'
import { getMyLSProp, setMyLSProps } from '../LocalStorageUtil'
import { getUsersURL } from '../api/apiURLUtil'
import { getSessionId } from '../cookies'
import { showSuccessSnackbar } from '../snackbarUtil'
import { sendAmplitudeEvent } from '../GAEvent'
import { getLocationByGEO } from './getLocation'

//минимальная дистанция в километрах
const MIN_DISTANCE = 1

//максимальная дистанция в километрах
const MAX_DISTANCE = 20

export function getDistanceTo(uid, realDistance) {
  //отключим дистанцию
  return null
  // const myGeo = getState('auth.geoIpInfo')
  // if (!myGeo || !myGeo.latitude || !myGeo.longitude){
  //   return null
  // }
  //
  // const user = getUser(uid)
  // if (!user || !user.geoIpInfo){
  //   return null
  // }
  //
  // const lat =  user && user.geoIpInfo && user.geoIpInfo.latitude
  // const lon =  user && user.geoIpInfo && user.geoIpInfo.longitude
  //
  // if (!lat || !lon){
  //   return null
  // }
  //
  // let distanceKM = getDistanceFromLatLonInKm(myGeo.latitude, myGeo.longitude, lat, lon)
  // distanceKM = Math.max(Math.floor(distanceKM), MIN_DISTANCE)
  //
  // //если расстояние больше максимального, то вернем пустоту для показа города
  // if (distanceKM > MAX_DISTANCE && !realDistance){
  //   return null
  // }
  //
  //
  // return distanceKM
}

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  let R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2-lat1)  // deg2rad below
  let dLon = deg2rad(lon2-lon1)
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  let d = R * c // Distance in km
  return d
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

async function saveGEO(latitude, longitude) {
  const response = await fetch(getUsersURL('geo'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ft-sid': getSessionId()
    },
    body: JSON.stringify({
      latitude: latitude,
      longitude: longitude
    })
  }).catch(e => {
    console.warn('catch error', e)
  })

  if (response && response.ok) {
    sendAmplitudeEvent('geo', {
      action:'allowed'
    })

    if(!getState('noSearchImprovePopup')){
      showSuccessSnackbar('geo_search_improved')
    }

    setStoreWrapper('posts.loadingPostsStatus', 'not_loaded')
    setStoreWrapper('posts.topPosts', [])
  }
}

function success(position) {
  const latitude = position.coords.latitude
  const longitude = position.coords.longitude
  setStoreWrapper('myGeo', { latitude: latitude, longitude: longitude })
  console.log('geo success latitude ' + latitude + ' longitude ' + longitude)

  setMyLSProps('geoAllowed', true)
  saveGEO(latitude, longitude)

  getLocationByGEO(latitude, longitude)
}

function error() {
  //TODO добавить сообщение
  setStoreWrapper('locationLoading', false)
  console.log('geoError')
}

export function getBrowserGeo() {
  setStoreWrapper('locationLoading', true)
  sendAmplitudeEvent('geo', {
    action:'request'
  })
  navigator.geolocation.getCurrentPosition(success, error)
}

export function isBrowserGeoSet() {
  return getMyLSProp('geoAllowed')
}
